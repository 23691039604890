import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftOnRectangleIcon, UserIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { getKeycloakUserInitials } from '../utils/KeycloakUser.util';

function ProfileMenu() {
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState<any>()

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      if (userInfo) {
        setUser(userInfo)
      }
    });
  }, [keycloak])

  return (
    <Menu as='div' className='relative inline-block w-full h-full'>
      <Menu.Button className='rounded-full bg-gray-300 w-full h-full'>
        {user && getKeycloakUserInitials(user)}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute left-0 mb-2 bottom-full origin-bottom-left z-10 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            <Menu.Item>
              <Link
                to='/profile'
                className='flex w-full items-center px-4 py-2 bg-white hover:text-primary'
              >
                <UserIcon className='mr-3 h-6 w-6' aria-hidden='true' />
                Profile
              </Link>
            </Menu.Item>
          </div>
          <div className='py-1'>
            <Menu.Item>
              <button
                onClick={() => {
                  keycloak.logout();
                }}
                className='flex w-full items-center px-4 py-2 bg-white hover:text-primary'
              >
                <ArrowLeftOnRectangleIcon
                  className='mr-3 h-6 w-6'
                  aria-hidden='true'
                />
                Logout
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ProfileMenu;
