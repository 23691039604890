import React from 'react';
import UserVerificationServiceDto from '../../../../../../interfaces/user/verification-service.dto';
import VerificationStatusItem from '../../../../UserStatus/StatusItem';
import {
  verificationServiceResponse,
  verificationServiceTitle,
} from '../../../../../../utils/VerificationServices.util';

interface Props {
  services: UserVerificationServiceDto[];
}

const BeneficialOwnerServices = ({ services }: Props) => {
  if (services.length === 0) {
    return <></>;
  }

  return (
    <div className='mt-12 border-t'>
      {services.map((service, index) => {
        return (
          <div key={index} className='border-b py-4'>
            <div className='flex justify-between'>
              <div className='font-bold'>
                {verificationServiceTitle(service.type)}
              </div>
              <VerificationStatusItem status={service.status} />
            </div>
            {service.response && (
              <div className='mt-2'>{verificationServiceResponse(service)}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BeneficialOwnerServices;
