import FlowType from '../../../enums/FlowType';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import { flowTypeTitle } from '../../../utils/FlowType.util';
import { verificationStatusBgColor } from '../../../utils/VerificationStatus.util';

import FlowTypeIcon from './FlowTypeIcon';

interface Props {
  flowType?: FlowType;
  status?: VerificationStatus;
  noBorder?: boolean;
}

function FlowTypeItem({
  flowType,
  status,
  noBorder = false,
}: Props): JSX.Element {
  let border = '';
  let background = '';

  if (!noBorder) {
    if (
      status === VerificationStatus.COMPLETED ||
      status === VerificationStatus.REJECTED
    ) {
      border = 'border border-transparent';
      background = verificationStatusBgColor(status);
    } else {
      border = 'border border-text';
    }
  }
  if (flowType) {
    return (
      <div
        className={`flex items-center justify-center py-0.5 w-24 rounded-full ${border} ${background}`}
      >
        <FlowTypeIcon flowType={flowType} className='h-4 w-4 mr-1' />
        <div className='text-sm'>{flowTypeTitle(flowType)}</div>
      </div>
    );
  }
  return <></>;
}

export default FlowTypeItem;
