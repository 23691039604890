interface SideBySideLayoutProps {
  children: JSX.Element;
  sideChildren: JSX.Element;
}

function SideBySideLayout({
  children,
  sideChildren,
}: SideBySideLayoutProps): JSX.Element {
  return (
    <div className='flex flex-1'>
      <div className='h-full shadow'>{sideChildren}</div>
      <div className='h-full flex-1 overflow-auto'>
        <div className="h-full">{children}</div>
 
      </div>
    </div>
  );
}

export default SideBySideLayout;
