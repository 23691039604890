import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import beneficialOwnerStore from '../../../../../stores/beneficialOwner.store';
import { observer } from 'mobx-react';
import { NodeType } from '../../../../../enums/NodeType';
import { getUserFullName } from '../../../../../utils/User.util';
import BeneficialOwnerUser from './SlideContent/BeneficialOwnerUser';
import BeneficialOwnerCompany from './SlideContent/BeneficialOwnerCompany';
import LoadingAnimation from '../../../../LoadingAnimation/LoadingAnimation';
import { VerificationStatus } from '../../../../../enums/VerificationStatus';
import { verificationStatusButtonClassName } from '../../../../../utils/VerificationStatus.util';

interface Props {
  reload: () => void;
  readonly?: boolean;
}

const BeneficialOwnerDialog = ({ reload, readonly = false }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [node, setNode]: any = useState(undefined);

  useEffect(() => {
    if (beneficialOwnerStore.node) {
      setNode(beneficialOwnerStore.node);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficialOwnerStore.node]);

  const closeDialog = () => {
    setOpen(false);
  };

  const isLegal = node?.type === NodeType.LEGAL_PERSON;
  const isNatural = node?.type === NodeType.NATURAL_PERSON;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeDialog}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
                afterLeave={() => {
                  beneficialOwnerStore.reset();
                }}
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-xl'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-base leading-6 text-gray-900 flex flex-row items-center'>
                          <div className='bg-gray-300 p-1 rounded-md'>
                            {isLegal && (
                              <HomeIcon className='w-6 h-6 text-gray-600' />
                            )}
                            {isNatural && (
                              <UserCircleIcon className='w-6 h-6 text-gray-600' />
                            )}
                          </div>
                          <div className='ml-2 font-semibold'>
                            {getUserFullName(node?.user)}
                          </div>
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                            onClick={closeDialog}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    {!readonly &&
                      node?.user.status !== VerificationStatus.REJECTED &&
                      node?.user.status !== VerificationStatus.COMPLETED && (
                        <div className='mt-6 flex justify-center'>
                          <button
                            className={`${verificationStatusButtonClassName(
                              VerificationStatus.REJECTED
                            )} w-1/3`}
                            onClick={async () => {
                              setLoading(true);
                              await beneficialOwnerStore.rejectUser();
                              setLoading(false);
                              beneficialOwnerStore.reset();
                              reload();
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className={`${verificationStatusButtonClassName(
                              VerificationStatus.COMPLETED
                            )} w-1/3 ml-4`}
                            onClick={async () => {
                              setLoading(true);
                              await beneficialOwnerStore.approveUser();
                              setLoading(false);
                              beneficialOwnerStore.reset();
                              reload();
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      )}

                    <div className='relative mt-6 flex-1 px-4'>
                      {beneficialOwnerStore.user && !loading ? (
                        isNatural ? (
                          <BeneficialOwnerUser
                            user={beneficialOwnerStore.user}
                          />
                        ) : (
                          isLegal && (
                            <BeneficialOwnerCompany
                              user={beneficialOwnerStore.user}
                            />
                          )
                        )
                      ) : (
                        <div className='w-full flex justify-center'>
                          <LoadingAnimation className='mt-16' />
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default observer(BeneficialOwnerDialog);
