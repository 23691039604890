import React from 'react';
import { parse as parseflatted } from 'flatted';
import FilePreview from '../../filepreview/FilePreview';
import FlattedText from '../../flatted-text/FlattedText';

interface Props {
  data: any;
}

const ParashiftRequest = ({ data }: Props) => {
  const parsedData = parseflatted(data);
  const documentUrl = parsedData.data?.attributes?.files[0]?.file_url;

  return (
    <>
      {documentUrl ? (
        <FilePreview file={documentUrl} />
      ) : (
        <FlattedText flattedText={data} />
      )}
    </>
  );
};

export default ParashiftRequest;
