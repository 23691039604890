import {
  getAllVerificationServices,
} from '../../../utils/FlowConfig.util';
import { verificationServiceTitle } from '../../../utils/VerificationServices.util';

const Statistics = () => {
  return (
    <div className='h-full w-full p-6'>
      <div className='text-xl font-bold'>Statistics</div>
      <p className='mt-1 max-w-2xl text-sm text-gray-500'>
        Check your Service-Statistics
      </p>
      <div className='mt-6 max-w-3xl border p-4 rounded-md'>
        <h3 className='text-xl font-semibold'>Number of Requests</h3>
        <dl className='grid mt-2 grid-cols-1 gap-5 sm:grid-cols-3'>
          {getAllVerificationServices().map((service, serviceI) => (
            <div
              key={`step-${serviceI}`}
              className='overflow-hidden rounded-md bg-white px-4 py-5 border sm:p-6'
            >
              <dt className='mt-1 max-w-2xl text-sm mb-3 text-gray-500'>
                {verificationServiceTitle(service.type)}
              </dt>
              <dd className='mt-1 text-3xl font-semibold tracking-tight'>
                {service.count}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Statistics;
