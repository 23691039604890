import { useKeycloak } from '@react-keycloak/web';
import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import NavBar from '../components/Nav/NavBar';
import Routing from '../components/Routing/Routing';

import Landing from '../pages/Landing';

const Routes = () => {
    const { keycloak } = useKeycloak();

    const renderLogin = () => {
        return (
            <div className='w-screen h-screen 2xl:w-screen flex'>
                <Landing />
            </div>
        );
    }
    return (
        <>
            {!keycloak.authenticated && renderLogin()}
            {keycloak.authenticated && <BrowserRouter>
                <div className='w-screen h-screen 2xl:w-screen flex overflow-hidden'>
                    <NavBar />
                    <Routing />
                </div>
            </BrowserRouter>}
        </>
    )
}

export default Routes;