import React, { useState } from 'react';
import { VerificationStepType } from '../../../../../../enums/VerificationStepType';
import { UserDto } from '../../../../../../interfaces/User.dto';
import FilePreview from '../../../../../filepreview/FilePreview';
import PersonalInfoUserData from '../../../VerificationSteps/PersonalInfo/PersonalInfoData';
import BeneficialOwnerDialogTabs from '../BeneficialOwnerDialogTabs';
import BeneficialOwnerServices from './BeneficialOwnerServices';
import { getSortedDocuments } from '../../../../../../utils/IdAndLiveness.util';

interface Props {
  user: UserDto;
}

const BeneficialOwnerUser = ({ user }: Props) => {
  const tabs = [
    VerificationStepType.PERSONAL_INFO,
    VerificationStepType.ID_AND_LIVENESS,
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const renderIdAndLiveness = () => {
    if (user.idAndLiveness?.documentImages) {
      const sortedDocuments = getSortedDocuments(user.idAndLiveness?.documentImages);

      return (
        <>
          {sortedDocuments.map((documentImage, index) => {
            return (
              <div className='mb-4'>
                <FilePreview file={documentImage.value} />
              </div>
            );
          })}
          {user.idAndLiveness?.services && (
            <BeneficialOwnerServices services={user.idAndLiveness?.services} />
          )}
        </>
      );
    }

    return <div className='w-fit mt-10 mx-auto'>No Id and Liveness yet</div>;
  };

  const renderPersonalInfo = () => {
    if (user.personalInfo) {
      return (
        <>
          <PersonalInfoUserData
            personalInfo={user.personalInfo}
            twoColMode
            displayOwnerPercent
          />
          {user.personalInfo?.services && (
            <BeneficialOwnerServices services={user.personalInfo?.services} />
          )}
        </>
      );
    }

    return <div className='w-fit mt-10 mx-auto'>No Personal Info yet</div>;
  };

  return (
    <div>
      <BeneficialOwnerDialogTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={(tab) => setCurrentTab(tab)}
        user={user}
      />
      <div className='mt-5'>
        {currentTab === VerificationStepType.PERSONAL_INFO &&
          renderPersonalInfo()}
        {currentTab === VerificationStepType.ID_AND_LIVENESS &&
          renderIdAndLiveness()}
      </div>
    </div>
  );
};

export default BeneficialOwnerUser;
