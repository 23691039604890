import { HomeIcon } from '@heroicons/react/24/solid'
import React, { ReactElement } from 'react'

interface Props {
  name: string
}

const CompanyNode = ({ name }: Props): ReactElement => {
  return (
    <div className="flex justify-center">
      <div className="rounded-md p-3 flex flex-col justify-center items-center border">
        <div className='bg-gray-300 p-1 rounded-md'>
          <HomeIcon className="w-8 h-8 text-gray-600" />
        </div>
        <div className='font-bold mt-1 text-sm'>{name}</div>
      </div>
    </div>
  )
}

export default CompanyNode
