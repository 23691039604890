import { parse as parseflatted } from 'flatted';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import FlattedText from '../../flatted-text/FlattedText';

interface Props {
  data: any;
}

const translation: any = {
  city: 'City',
  country: 'Country',
  region: 'Region',
  continent: 'Continent',
  region_code: 'Region Code',
  country_code: 'Country Code',
  continent_code: 'Continent Code',
  latitude: 'Latitude',
  longitude: 'Longitude',
  time_zone: 'Time Zone',
  locale_code: 'Locale Code',
  metro_code: 'Metro Code',
  is_in_european_union: 'Is in European Union',
  netwerk: 'Netwerk',
  autonomous_system_number: 'Autonomous System Number',
  autonomous_system_organization: 'Autonomous System Organization',
  vpn: 'VPN',
  tor: 'Tor',
  proxy: 'Proxy',
  relay: 'Relay',
};

const IPScannerResponse = ({ data }: Props) => {
  const parsedData = parseflatted(data);

  const renderLocation = (section: any) => {
    if (!section.location) return <></>;

    return Object.keys(section.location).map((key) => {
      return (
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={translation[key]}
          subTitle={section.location[key]}
        />
      );
    });
  };

  const renderNetwork = (section: any) => {
    if (!section.network) return <></>;
    return Object.keys(section.network).map((key) => {
      return (
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={translation[key]}
          subTitle={section.network[key]}
        />
      );
    });
  };

  const renderSecurity = (section: any) => {
    if (!section.security) return <></>;
    return Object.keys(section.security).map((key) => {
      return (
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={translation[key]}
          subTitle={section.security[key] ? 'Yes' : 'No'}
        />
      );
    });
  };

  const renderSectionNonArray = () => {
    try {
      if (parsedData.message) {
        return (
          <div>
            <h3 className='mb-3 mt-5 font-bold'> {parsedData.ip}</h3>
            <IconTitleItem
              icon={<InformationCircleIcon />}
              title={'IP'}
              subTitle={parsedData.message}
            />
          </div>
        );
      }
      return (
        <div
          key={parsedData.ip ? parsedData.ip : 'localhost'}
          className='pb-3 border-b'
        >
          <h3 className='mb-3 mt-5 font-bold'> {parsedData.ip}</h3>
          <div className='grid gap-3 grid-cols-3 border-b mb-3 pb-3'>
            {renderLocation(parsedData)}
          </div>
          <div className='grid gap-3 grid-cols-3 border-b mb-3 pb-3'>
            {renderNetwork(parsedData)}
          </div>
          <div className='grid gap-3 grid-cols-3'>
            {renderSecurity(parsedData)}
          </div>
        </div>
      );
    } catch (err) {
      console.log(err);
    }
  };

  const renderSection = () => {
    if (Array.isArray(parsedData)) {
      try {
        return parsedData.map((section: any) => {
          if (section.message) {
            return (
              <div>
                <h3 className='mb-3 mt-5 font-bold'> {section.ip}</h3>
                <IconTitleItem
                  icon={<InformationCircleIcon />}
                  title={'IP'}
                  subTitle={section.message}
                />
              </div>
            );
          }

          return (
            <div
              key={section.ip ? section.ip : 'localhost'}
              className='pb-3 border-b'
            >
              <h3 className='mb-3 mt-5 font-bold'> {section.ip}</h3>
              <div className='grid gap-3 grid-cols-3 border-b mb-3 pb-3'>
                {renderLocation(section)}
              </div>
              <div className='grid gap-3 grid-cols-3 border-b mb-3 pb-3'>
                {renderNetwork(section)}
              </div>
              <div className='grid gap-3 grid-cols-3'>
                {renderSecurity(section)}
              </div>
            </div>
          );
        });
      } catch (err) {
        return <FlattedText flattedText={data} />;
      }
    }
    return renderSectionNonArray();
  };

  if (typeof parsedData == 'string') {
    return (
      <IconTitleItem
        icon={<InformationCircleIcon />}
        title={'IP Information'}
        subTitle={parsedData}
      />
    );
  }
  return <>{renderSection()}</>;
};

export default IPScannerResponse;
