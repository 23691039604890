enum POSSIBLE_KEYS {
  MATCH_STATUS = 'match_status',
  RESK_LEVEL = 'risk_level',
  SEARCH_TERM = 'search_term',
  SUBMITTED_TERM = 'submitted_term',
  TOTAL_HITS = 'total_hits',
  TOTAL_MATCHES = 'total_matches',
  TOTAL_BLACKLIST_HITS = 'total_blacklist_hits',
  REF = 'ref',
}

const parseComplyAdvantageResponse = (response: any) => {
  const data = response.content?.data;
  const result: any = {};

  if (data) {
    Object.values(POSSIBLE_KEYS).forEach((possibleKey) => {
      if (Object.keys(data).includes(possibleKey)) {
        result[possibleKey] = data[possibleKey];
      }
    });
  }

  return result;
};

export { parseComplyAdvantageResponse };
