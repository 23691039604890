import { XMarkIcon } from '@heroicons/react/24/outline';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import {
  verificationStatusBgColor,
  verificationStatusHoverBorderColor,
  verificationStatusText,
} from '../../../utils/VerificationStatus.util';

interface ActiveStatusFilterItemProps {
  userStatus: VerificationStatus;
}

function ActiveStatusFilterItem({
  userStatus,
}: ActiveStatusFilterItemProps): JSX.Element {
  return (
    <div
      className={`flex items-center py-0.5 px-2 rounded-full border border-transparent ${verificationStatusHoverBorderColor(
        userStatus
      )}
      ${verificationStatusBgColor(userStatus)}`}
    >
      <div className='text-sm'>{verificationStatusText(userStatus)}</div>
      <XMarkIcon className='h-4 w-4 ml-1' />
    </div>
  );
}

export default ActiveStatusFilterItem;
