import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import projectStore from '../../stores/project.store';

const ProjectDetail = () => {
  const { project } = useParams();

  useEffect(() => {
    if (project) {
      projectStore.resetProject();
      projectStore.getProject(project);
    }
  }, [project]);

  if (projectStore.project && projectStore.project !== undefined) {
    return <Outlet />;
  } else {
    return <Loading />
  }
};

export default observer(ProjectDetail);
