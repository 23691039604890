import React from 'react';
import { DirectorApprovalUserDto } from '../../../../interfaces/user/verification-steps/director-approval.dto';
import UserInfo from '../../UserInfo/UserInfo';
import PersonalInfoUserData from '../VerificationSteps/PersonalInfo/PersonalInfoData';

interface Props {
  directorApproval: DirectorApprovalUserDto;
}

const DirectorApprovalData = ({ directorApproval }: Props) => {
  if (directorApproval.director) {
    return (
      <>
        <UserInfo user={directorApproval.director} hideStatus />
        {directorApproval.director.personalInfo && (
          <div className='mt-5 border-t pt-5'>
            <PersonalInfoUserData
              personalInfo={directorApproval.director.personalInfo}
              limited
            />
          </div>
        )}
      </>
    );
    // return <UserDetailInfo user={directorApproval.director} />;
  }
  return <></>;
};

export default DirectorApprovalData;
