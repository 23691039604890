import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../components/button/Button';
import CustomColorPicker from '../../../components/colorpicker/CustomColorPicker';
import TextInput from '../../../components/inputs/TextInput';
import LoadingAnimation from '../../../components/LoadingAnimation/LoadingAnimation';
import RequireAuth from '../../../components/RequireAuth';
import { Role } from '../../../enums/Role';
import ProjectConfigDto from '../../../interfaces/ProjectConfig.dto';
import projectConfigStore from '../../../stores/project-config.store';
import { onPromise } from '../../../utils/formHelper';
import { isUserReadyonly } from '../../../utils/KeycloakUser.util';

const Development = () => {
  const [isLoading, setIsLoading] = useState(false);
  const readOnly = isUserReadyonly(useKeycloak().keycloak);

  const methods = useForm<any>({
    mode: 'onSubmit',
    defaultValues: useMemo(() => projectConfigStore.config, []),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit: SubmitHandler<ProjectConfigDto> = async (data) => {
    try {
      await setIsLoading(true);
      await projectConfigStore.updateConfig(data);
      await setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <div className='h-full w-full p-4 flex justify-center items-center'>
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className='h-full w-full p-6'>
      <FormProvider {...methods}>
        <form onSubmit={onPromise(methods.handleSubmit(onSubmit))}>
          <div className='text-xl font-bold'>Development</div>
          <p className='mt-1 max-w-2xl text-sm text-gray-500'>
            Configure your development settings
          </p>
          <div className='mt-4 max-w-2xl'>
            <div>
              <div className='text-xl mt-6 border p-4 rounded-md'>
                <div className='text-xl font-semibold pb-4'>Frontend Urls</div>
                <p className='mt-1 max-w-2xl text-sm mb-3 text-gray-500'>
                  KYC
                </p>
                <TextInput
                  className='w-full'
                  variable='kycUrl'
                  placeHolder='https://project-kyc.complie.li'
                  regExp={/^https?:\/\/.*/}
                  readOnly={readOnly}
                />
                <p className='mt-1 max-w-2xl text-sm mb-3 text-gray-500'>
                  KYB
                </p>
                <TextInput
                  className='w-full'
                  variable='kybUrl'
                  placeHolder='https://project-kyb.complie.li'
                  regExp={/^https?:\/\/.*/}
                  readOnly={readOnly}
                />
              </div>
              <div className='text-xl mt-6 border p-4 rounded-md'>
                <div className='text-xl font-semibold'>Server</div>
                <div className='w-full mt-4'>
                  <TextInput
                    className='w-full'
                    variable='backendUrl'
                    placeHolder='https://project.api.complie.li'
                    regExp={/[a-zA-Z0-9]/}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div className='text-xl mt-6 border p-4 rounded-md'>
                <div className='text-xl font-semibold'>AWS Bucket Name</div>
                <div className='w-full mt-4'>
                  <TextInput
                    className='w-full'
                    variable='awsBucketName'
                    placeHolder='complie-project'
                    regExp={/[a-zA-Z0-9]/}
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <RequireAuth roles={[Role.ADMIN]}>
                <div className='flex justify-end'>
                  <Button
                    className='mt-4 w-64'
                    type='submit'
                    title='Save'
                    style='primary'
                  />
                </div>
              </RequireAuth>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className='h-16'></div>
    </div>
  );
};

export default Development;
