import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import UserDetail from "../../../components/User/UserDetail/UserDetail";
import VerificationStatusCard from "../../../components/User/UserDetail/VerificationStatusCard";
import QuestionnaireData from "../../../components/User/UserDetail/VerificationSteps/Questionnaire/QuestionnaireData";
import VerificationServiceCards from "../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards";
import VerificationStepSettings from "../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings";
import { VerificationStepType } from "../../../enums/VerificationStepType";
import { getVerificationStepUserServices } from "../../../helpers/VerificationService.helper";
import UserVerificationServiceDto from "../../../interfaces/user/verification-service.dto";
import clientUserStore from "../../../stores/clientUser.store";
import { stepConfigStore } from "../../../utils/FlowConfig.util";
import { getUserVerificationStepStatus } from "../../../utils/User.util";
import { verificationStepTitle } from "../../../utils/VerificationSteps.util";

function UserDetailQuestionnaire() {
  const stepType = VerificationStepType.QUESTIONNAIRE;
  const questionaire = clientUserStore.user?.questionnaire;

  let services: UserVerificationServiceDto[] = [];

  const { user } = useParams();
  useEffect(() => {
    if (user) {
      // clientUserStore.resetUser();
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, []);

  if (questionaire) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: questionaire.services ? questionaire.services : [],
    });
  }

  const renderQuestionnaireData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          questionaire && <VerificationStepSettings step={stepType} />
        }
      >
        {questionaire && <QuestionnaireData questionnaire={questionaire} />}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderQuestionnaireData()}
        {questionaire &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className="mt-8"
              services={services}
              comments={questionaire.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailQuestionnaire);
