import React, { ReactElement } from 'react';

interface Props {
  children: any
  className?: string
}

const Card: React.FC<Props> = ({ children, className = '' }: Props): ReactElement => {
  return (
    <div className={`bg-white self-center rounded-lg drop-shadow-xl overflow-auto ${className}`} >
      {children}
    </div >
  );
}

export default Card;
