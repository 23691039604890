import { observer } from 'mobx-react';
import filterStore from '../../../stores/filter.store';
import TextSearchInput from '../../inputs/TextSearchInput';

interface Props {
  className?: string;
  isWarning?: boolean;
}

const TextSearchBarRow = ({ className }: Props) => {
  if (filterStore.textSearchActive) {
    return (
      <div className={`${className}`}>
        <TextSearchInput
          onChange={(e) => {
            filterStore.setTextSearch(e.target.value);
          }}
        />
      </div>
    );
  }

  return <></>;
};

export default observer(TextSearchBarRow);
