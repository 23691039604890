import { UserIcon } from '@heroicons/react/24/outline';
import { parse as parseflatted } from 'flatted';
import React from 'react';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';

interface Props {
  data: any;
}

const NameRequest = ({ data }: Props) => {
  const parsedData = parseflatted(data);
  if (parsedData.length > 0) {
    return (
      <div className='grid gap-3 grid-cols-3'>
        {parsedData.map((name: string, index: number) => {
          return (
            <IconTitleItem
              icon={<UserIcon />}
              title={`Name ${(index + 1).toString()}`}
              subTitle={name}
              key={index}
            />
          );
        })}
      </div>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default NameRequest;
