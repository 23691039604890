import React, { ReactElement } from 'react';
import { Control, useController, useFormContext } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';

interface Props {
    name: any
    control: Control<any, any>
    options: any
    selectedOption: undefined | any
    onChange?: () => Promise<void>
    className?: string
}

const DropdownSelected: React.FC<Props> = ({ name, control, options, onChange, className = '', selectedOption }: Props): ReactElement => {
    const { field } = useController({ control, name });
    const { formState: { errors } } = useFormContext();
    const handleOnChange = (val: SingleValue<{
        value: string
        label: JSX.Element | string | any[]
    }>): void => {
        field.onChange(val?.value)
        onChange && onChange()
    }
    const styles = {
        control: (base: any) => ({
            ...base,
            fontSize: "0.875rem"
        }),
        menu: (base: any) => ({
            ...base,
            fontSize: "0.875rem"
        }),
    };

    return (
        <>
            <div className={className}>
                <Select
                    onChange={(val) => handleOnChange(val)}
                    options={options}
                    defaultValue={selectedOption}
                    styles={styles}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 8,
                        colors: {
                            ...theme.colors,
                            primary25: 'rgb(var(--color-secondary))',
                            primary: 'rgb(var(--color-primary))'
                        }
                    })}
                />
            </div>
            <div className='h-7'>{errors[name] != null && <div className='text-red-600'>Input required.</div>}</div>
        </>
    );
}

export default DropdownSelected;
