import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Node as NodeDto } from '../../../../interfaces/Organigram.dto';
import { BeneficialOwnerUserDto } from '../../../../interfaces/user/verification-steps/beneficial-owner.dto';
import beneficialOwnerStore from '../../../../stores/beneficialOwner.store';
import clientUserStore from '../../../../stores/clientUser.store';
import CompanyNode from './nodes/CompanyNode';
import Node from './nodes/Node';
import BeneficialOwnerDialog from './SideSlideOver/BeneficialOwnerDialog';
import Information from '../../../information/Information';
import WarningInformation from '../../../information/WarningInformation';

interface Props {
  beneficialOwner: BeneficialOwnerUserDto;
  reloadUser: () => void;
}

const BeneficialOwnerData = ({ beneficialOwner, reloadUser }: Props) => {
  const organization = beneficialOwner.root;

  const readonly = !beneficialOwner.verifiedData;

  if (!organization) {
    return <></>;
  }

  const renderRecursive = (node: NodeDto) => {
    if (!node.children) {
      return <></>;
    }

    return (
      <TreeNode
        label={
          <Node
            node={node}
            key={node.id}
            hideStatus={readonly}
            onClick={(node: NodeDto) => {
              beneficialOwnerStore.getUser(node);
            }}
          />
        }
      >
        {node.children &&
          node.children.slice().map((child: any) => renderRecursive(child))}
      </TreeNode>
    );
  };

  const renderOrganization = () => {
    let name = '';
    if (clientUserStore.user?.companyInfo?.companyName) {
      name = clientUserStore.user.companyInfo.companyName;
    }

    return (
      <Tree label={<CompanyNode name={name} />} lineWidth='2px'>
        {organization.children &&
          organization.children.map((child: NodeDto) => renderRecursive(child))}
      </Tree>
    );
  };

  return (
    <div>
      {readonly && (
        <div className='mb-5'>
          <WarningInformation text='Director has not verified Beneficial Owner yet!' />
        </div>
      )}
      {renderOrganization()}
      <BeneficialOwnerDialog reload={reloadUser} readonly={readonly} />
    </div>
  );
};

export default BeneficialOwnerData;
