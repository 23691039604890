import { NavLink, useParams } from 'react-router-dom';
import { VerificationStatus } from '../../enums/VerificationStatus';
import clientUserStore from '../../stores/clientUser.store';
import { getUserVerificationStepStatus } from '../../utils/User.util';
import {
  verificationStepSlug,
  verificationStepTitle,
} from '../../utils/VerificationSteps.util';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { VerificationStepType } from '../../enums/VerificationStepType';
import { stepConfigStore } from '../../utils/FlowConfig.util';

function linkClassName(args: { isActive: boolean }): string {
  let defaultClass = 'text-white pr-10 underline-offset-4';
  if (args.isActive) {
    defaultClass += ' underline';
  }
  return defaultClass;
}

function UserDetailNavBar() {
  const { project, user } = useParams();

  if (!project || !user) {
    return <></>;
  }

  const baseUrl = `/projects/${project}/${clientUserStore.getUsersParentUrlParam()}/${user}`;

  return (
    <nav className='flex flex-row w-full'>
      <NavLink to={baseUrl} end className={linkClassName}>
        <div>{'Overview'}</div>
      </NavLink>
      {stepConfigStore(clientUserStore.user?.flowType)
        .getActiveVerificationSteps()
        .map((verificationStep, index) => {
          if (verificationStep !== VerificationStepType.FINISH) {
            let hasWarning: boolean = false;
            if (clientUserStore.user) {
              const status = getUserVerificationStepStatus({
                user: clientUserStore.user,
                step: verificationStep,
              });

              if (status === VerificationStatus.WARNING) {
                hasWarning = true;
              }
            }

            return (
              <NavLink
                to={`${baseUrl}/${verificationStepSlug(verificationStep)}`}
                end
                className={linkClassName}
                key={index}
              >
                <div className='flex items-center'>
                  {verificationStepTitle(verificationStep)}
                  {hasWarning && (
                    <ExclamationTriangleIcon className='ml-2 h-4 w-4' />
                  )}
                </div>
              </NavLink>
            );
          }
        })}
    </nav>
  );
}

export default UserDetailNavBar;
