import React, { ReactElement } from 'react';
import { parse as parseflatted } from 'flatted';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';
import { parseRegulaResponse } from './helper/regula.parser';
import FlattedText from '../../flatted-text/FlattedText';

interface Props {
  data: any;
}

const RegulaResponse = ({ data }: Props): ReactElement => {
  const response = parseflatted(data);
  if (!response) return <></>;

  const parsedResponse = parseRegulaResponse(response);

  const renderResponse = (key: string, response: any) => {
    if (Array.isArray(response) && response.length > 0) {
      if (Object.keys(response).length === 0) {
        return <></>;
      }
      return (
        <>
          <h3 className='mb-3 mt-5 font-bold'>{key}</h3>
          {response.map((item: any, responseIndex: number) => {
            return (
              <div key={responseIndex}>
                <div className='grid gap-5 grid-cols-2 pb-3 pt-3 border-b'>
                  {Object.keys(item).map((key, index: number) => {
                    if (!item || !item[key]) {
                      return <></>;
                    }
                    if (key.toLocaleLowerCase().includes('image')) {
                      return (
                        <IconTitleItem
                          key={index}
                          icon={undefined}
                          subTitleColor='break-all'
                          title={''}
                          subItem={<img src={item[key].image} alt={key} />}
                        />
                      );
                    }
                    if (typeof item[key] === 'object') {
                      return <></>;
                    }
                    return (
                      <IconTitleItem
                        key={index}
                        subTitleColor='break-all'
                        icon={<InformationCircleIcon />}
                        title={key}
                        subTitle={item[key].toString()}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      );
    }

    if (Object.keys(response).length === 0) {
      return <></>;
    }

    return (
      <div key={key}>
        <h3 className='mb-3 mt-5 font-bold'>{key}</h3>
        <div className='grid gap-5 grid-cols-2'>
          {Object.keys(response).map((key, index: number) => {
            if (key.toLocaleLowerCase().includes('image')) {
              if (!response[key]) {
                return <></>;
              }
              return (
                <IconTitleItem
                  key={index}
                  icon={undefined}
                  title={''}
                  subTitleColor='break-all'
                  subItem={<img src={response[key].image} alt={key} />}
                />
              );
            }
            if (typeof response[key] === 'object') {
              return <div key={index}></div>;
            }
            return (
              <IconTitleItem
                key={index}
                icon={<InformationCircleIcon />}
                title={key}
                subTitleColor='break-all'
                subTitle={response[key]}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {Object.keys(parsedResponse).length > 0 ? (
        Object.keys(parsedResponse).map((key, index) => {
          const entity = parsedResponse[key];
          return <div key={index}>{renderResponse(key, entity)}</div>;
        })
      ) : (
        <FlattedText flattedText={data} />
      )}
    </div>
  );
};

export default RegulaResponse;
