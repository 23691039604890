import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ProjectDto from '../../interfaces/Project.dto';

interface Props {
  project?: ProjectDto | undefined;
}

const WrapperCard = ({ children }: any) => {
  return (
    <div className='w-1/4 h-16  rounded-lg overflow-hidden border border-gray-300 shadow-sm m-2 ml-0 bg-gray-100 '>
      {children}
    </div>
  );
};

const ProjectCard = ({ project }: Props) => {
  const generateInitials = () => {
    if (!project) {
      return '';
    }
    return project.name.slice(0, 2).toUpperCase();
  };

  const renderSkeleton = () => {
    return (
      <WrapperCard>
        <Skeleton className='w-full h-full' />
      </WrapperCard>
    );
  };

  const renderProject = () => {
    if (!project) {
      return renderSkeleton();
    }

    return (
      <NavLink
        to={`/projects/${project.id}/users`}
        className='h-16 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-2 py-2 shadow-sm focus-within:ring-2 focus-within:ring-offset-2 hover:border-gray-400 w-1/4 m-2 ml-0 box-border'
      >
        <div className='flex-shrink-0 bg-gray-300 p-3 rounded-md font-bold'>
          {generateInitials()}
          {/* <img className="h-10 w-10 rounded-full" src={project.imageUrl} alt="" /> */}
        </div>
        <div className='min-w-0 flex-1'>
          <span className='absolute inset-0' aria-hidden='true' />
          <p className='text-sm text-gray-900 font-bold'>{project.name}</p>
        </div>
      </NavLink>
    );
  };

  return renderProject();
};

export default ProjectCard;
