import {
  AcademicCapIcon,
  BuildingOffice2Icon,
  UserIcon,
} from '@heroicons/react/24/solid';
import FlowType from '../../../enums/FlowType';

interface Props {
  flowType?: FlowType;
  className?: string;
}

function FlowTypeIcon({ className, flowType }: Props): JSX.Element {
  switch (flowType) {
    case FlowType.KYC:
      return <UserIcon className={className} />;
    case FlowType.KYB:
      return <BuildingOffice2Icon className={className} />;
    case FlowType.KYB_DIRECTOR:
      return <AcademicCapIcon className={className} />;
    default:
      return <></>;
  }
}

export default FlowTypeIcon;
