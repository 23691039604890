import { useKeycloak } from '@react-keycloak/web';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import FlowType from '../../../enums/FlowType';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import { VerificationStepsConfigStore } from '../../../stores/verification-steps-config.store';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import { isUserReadyonly } from '../../../utils/KeycloakUser.util';
import { verificationStepTitle } from '../../../utils/VerificationSteps.util';
import ToggleSwitch from '../../toggle-switch/ToggleSwitch';

interface Props {
  verificationStepType: VerificationStepType;
  children?: React.ReactNode;
  flowType: FlowType;
}

const ConfigVerificationStepLayout = ({
  verificationStepType,
  children,
  flowType,
}: Props) => {
  const readOnly = isUserReadyonly(useKeycloak().keycloak);

  const configStore = stepConfigStore(flowType);

  return (
    <div className='p-6'>
      <div className='flex items-center mb-6'>
        <ToggleSwitch
          initialyEnabled={configStore.getVerificationStepEnabled(
            verificationStepType
          )}
          key={'toggleSwitch' + verificationStepType + flowType}
          readOnly={readOnly}
          onChange={(isEnabled) => {
            configStore.setVerificationStepEnabled({
              verificationStepType,
              isEnabled,
            });
          }}
        />
        <div className='text-lg font-bold ml-4'>
          Enable {verificationStepTitle(verificationStepType)}
        </div>
      </div>
      {
        configStore.getVerificationStepEnabled(verificationStepType) && children
        // : (
        //   <FlowDisabled />
        // )
      }
    </div>
  );
};

export default observer(ConfigVerificationStepLayout);
