import { VerificationStepType } from '../../enums/VerificationStepType';
import { VerificationStepsConfigStore } from '../verification-steps-config.store';

const kybUserStepsConfigStore = new VerificationStepsConfigStore({
  allowedStepTypes: [
    VerificationStepType.PERSONAL_INFO,
    VerificationStepType.ID_AND_LIVENESS,
  ],
});
export default kybUserStepsConfigStore;
