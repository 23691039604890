import { action, makeObservable, observable } from 'mobx';
import { VerificationStatus } from '../enums/VerificationStatus';
import projectStore from './project.store';
import { debounce } from 'lodash';
import clientUserStore from './clientUser.store';
import FlowType from '../enums/FlowType';

export class FilterStore {
  @observable statusFilter: VerificationStatus | undefined = undefined;
  @observable flowTypeFilter: FlowType | undefined = undefined;
  @observable textSearch: string = '';
  @observable textSearchActive: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setTextSearchActive(textSearchActive: boolean) {
    this.textSearchActive = textSearchActive;
  }

  @action
  setTextSearch(textSearch: string) {
    if (this.textSearch !== textSearch) {
      this.textSearch = textSearch;
      this.debounceTriggerGetUsers();
    }
  }

  @action
  setFlowTypeFilter(flowType?: FlowType) {
    this.flowTypeFilter = flowType;
    this.debounceTriggerGetUsers();
  }

  @action
  removeFlowTypeFilter() {
    this.flowTypeFilter = undefined;
    this.debounceTriggerGetUsers();
  }

  @action
  setStatusFilter(userStatus?: VerificationStatus) {
    this.statusFilter = userStatus;
    this.debounceTriggerGetUsers();
  }

  @action
  removeStatusFilter() {
    this.statusFilter = undefined;
    this.debounceTriggerGetUsers();
  }

  private debounceTriggerGetUsers = debounce(() => {
    this.triggerGetUsers();
  }, 300);

  private triggerGetUsers() {
    console.log(this.getUsersQuery())
    if (projectStore.project) {
      clientUserStore.initUsers(this.getUsersQuery());
    }
  }

  getUsersQuery() {
    let query: any;
    if (this.textSearch !== '') {
      query = { searchTerm: this.textSearch };
    }

    if (this.statusFilter) {
      query = { ...query, status: this.statusFilter };
    }

    if (this.flowTypeFilter) {
      query = { ...query, flowType: this.flowTypeFilter };
    }
    return query;
  }

  hasActiveStatusFilter() {
    return !!this.statusFilter;
  }

  hasActiveFlowTypeFilter() {
    return !!this.flowTypeFilter;
  }

  hasActiveFilters() {
    return (
      this.textSearchActive ||
      this.hasActiveStatusFilter() ||
      this.hasActiveFlowTypeFilter()
    );
  }

  @action
  resetFilter() {
    this.setTextSearchActive(false);
    if (this.textSearch !== '') {
      this.setTextSearch('');
    }

    this.statusFilter = undefined;
    this.flowTypeFilter = undefined;
  }
}

const filterStore = new FilterStore();
export default filterStore;
