import React from "react";
import { parse as parseflatted } from "flatted";
import IconTitleItem from "../../IconTitleItem/IconTitleItem";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import FlattedText from "../../flatted-text/FlattedText";

interface Props {
  data: any;
}

const ParashiftResponse = ({ data }: Props) => {
  const parsedData = parseflatted(data);

  const sender: any[] = [];
  const receiver: any[] = [];

  Object.keys(parsedData).forEach(function (key) {
    if (key.indexOf("pp-sender-address") !== -1) {
      sender.push({
        value: parsedData[key],
        key: key.replace("pp-sender-address-", ""),
      });
    } else if (key.indexOf("pp-receiver-address") !== 1) {
      receiver.push({
        value: parsedData[key],
        key: key.replace("pp-receiver-address-", ""),
      });
    }
  });

  return (
    <>
      {sender.length > 0 && receiver.length > 0 ? (
        <>
          <div className="font-bold mb-1 underline">Receiver: </div>
          <div className="grid gap-3 grid-cols-2">
            {receiver.map((receiverObj, index) => {
              return (
                <IconTitleItem
                  key={index}
                  icon={<InformationCircleIcon />}
                  title={receiverObj.key}
                  subTitle={receiverObj.value}
                />
              );
            })}
          </div>
          <div className="font-bold mb-1 mt-4 underline">Sender: </div>
          <div className="grid gap-3 grid-cols-2">
            {sender.map((senderObj, index) => {
              return (
                <IconTitleItem
                  key={index}
                  icon={<InformationCircleIcon />}
                  title={senderObj.key}
                  subTitle={senderObj.value}
                />
              );
            })}
          </div>
        </>
      ) : (
        <FlattedText flattedText={data} />
      )}
    </>
  );
};

export default ParashiftResponse;
