import 'react-color-picker/index.css';
import { useFormContext } from 'react-hook-form';
import TextInput from '../inputs/TextInput';

interface Props {
  title: string;
  name: string;
  readOnly?: boolean;
}

const CustomColorPicker = ({ name, title, readOnly = false }: Props) => {
  const { watch } = useFormContext();

  return (
    <>
      <p className='mt-1 max-w-2xl text-sm mb-3 text-gray-500'>{title}</p>
      <div className='flex h-full'>
        <div
          className={`w-16 min-h-full mr-2 rounded-md mb-4 shadow border`}
          style={{ backgroundColor: watch(name) }}
        ></div>
        <TextInput
          className='w-full'
          variable={name}
          readOnly={readOnly}
          placeHolder={title}
          regExp={/^#(?:[0-9a-fA-F]{3}){1,2}$/}
        />
      </div>
    </>
  );
};

export default CustomColorPicker;
