import { observer } from 'mobx-react';
import clientUserStore from '../../../stores/clientUser.store';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import UserDetailNavBar from '../../Nav/UserDetailNavBar';

interface UserDetailProps {
  children: JSX.Element;
}

function UserDetail({ children }: UserDetailProps): JSX.Element {
  return (
    <div className='w-full h-full overflow-auto'>
      <div className='px-14 py-8'>
        <div className='h-full flex-1 flex flex-col justify-center items-center max-w-4xl mx-auto'>
          <UserDetailNavBar />
          <div className='flex-1 w-full'>
            {clientUserStore.userLoadingCount > 0 || !clientUserStore.user ? (
              <div className='absolute w-full h-full top-0 left-0 flex items-center justify-center'>
                <LoadingAnimation />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(UserDetail);
