import axios from '../utils/Axios';

const approveUser = async (id: string) => {
  try {
    return await axios.post(`/client/users/${id}/approve`);
  } catch (err) {
    console.log(err);
  }
};

const approveBeneficialUser = async (args: {
  userId: string;
  beneficialUserId: string;
}) => {
  try {
    return await axios.post(
      `/client/users/${args.userId}/beneficial-owner/${args.beneficialUserId}/approve`
    );
  } catch (err) {
    console.log(err);
  }
};

const rejectUser = async (id: string) => {
  try {
    return await axios.post(`/client/users/${id}/reject`);
  } catch (err) {
    console.log(err);
  }
};

const rejectBeneficialUser = async (args: {
  userId: string;
  beneficialUserId: string;
}) => {
  try {
    return await axios.post(
      `/client/users/${args.userId}/beneficial-owner/${args.beneficialUserId}/reject`
    );
  } catch (err) {
    console.log(err);
  }
};

const getUser = async (args: { user: string; query?: string }) => {
  if (!args.query) {
    args.query = '';
  }

  try {
    return await axios.get(`/client/users/${args.user}${args.query}`);
  } catch (err) {
    console.log(err);
  }
};

const generateCsvDataBlob = async (args: { query?: string }) => {
  if (!args.query) {
    args.query = '';
  }

  try {
    const response = await axios.get(
      `/client/users/generate-csv${args.query}`,
      {
        responseType: 'blob',
      }
    );

    return new Blob([response.data], { type: 'application/zip' });
  } catch (err) {
    console.log(err);
    return;
  }
};

export {
  approveUser,
  approveBeneficialUser,
  rejectUser,
  rejectBeneficialUser,
  getUser,
  generateCsvDataBlob,
};
