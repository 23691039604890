import { action, makeObservable, observable } from 'mobx';
import axios from '../utils/Axios';
import { CountryRestrictionDto } from '../interfaces/CountryRestriction.dto';
import { countries as allCountries } from '../data/countries';
import projectConfigStore from './project-config.store';

interface CountryRestrictionDbDto {
  id?: string;
  residences: string[];
  nationalities: string[];
}

export class CountryRestrictionStore {
  private countryRestrictionDbObj?: CountryRestrictionDbDto;
  @observable countries: CountryRestrictionDto[] = [];
  @observable restrictedCountries: CountryRestrictionDto[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setRestrictedCountries() {
    const restrictedCountries: CountryRestrictionDto[] = [];
    this.countries.forEach((country) => {
      if (country.nationalityRestricted || country.residenceRestricted) {
        restrictedCountries.push(country);
      }
    });

    this.restrictedCountries = restrictedCountries;
    this.updateCountryRestriction();
  }

  @action
  setCountries(countryRestrictionDbObj: CountryRestrictionDbDto) {
    this.countryRestrictionDbObj = countryRestrictionDbObj;
    allCountries.forEach((countryObj) => {
      let country: CountryRestrictionDto = {
        country: countryObj.value,
        residenceRestricted:
          countryRestrictionDbObj.residences.indexOf(countryObj.value) >= 0,
        nationalityRestricted:
          countryRestrictionDbObj.nationalities.indexOf(countryObj.value) >= 0,
      };

      this.countries.push(country);

      if (country.residenceRestricted || country.nationalityRestricted) {
        this.restrictedCountries.push(country);
      }
    });
  }

  @action
  setResidence(args: { country: string; value: boolean }) {
    const index = this.countries.findIndex((c) => c.country === args.country);

    if (index !== -1) {
      this.countries[index].residenceRestricted = args.value;
    }

    this.setRestrictedCountries();
  }

  @action
  setNationality(args: { country: string; value: boolean }) {
    const index = this.countries.findIndex((c) => c.country === args.country);

    if (index !== -1) {
      this.countries[index].nationalityRestricted = args.value;
    }

    this.setRestrictedCountries();
  }

  @action
  async updateCountryRestriction(): Promise<void> {
    try {
      if (!projectConfigStore.config) return;
      await axios.put('/project-config/' + projectConfigStore.config.id, {
        countryRestriction: this.convertToDbObj(this.restrictedCountries),
      });
    } catch (e) {
      console.log(e);
    }
  }

  isCountryRestricted(country: string) {
    const countryObj = this.countries.find((c) => c.country === country);

    if (
      countryObj &&
      countryObj.nationalityRestricted &&
      countryObj.residenceRestricted
    ) {
      return true;
    }

    return false;
  }

  convertToDbObj(
    restrictionDto: CountryRestrictionDto[]
  ): CountryRestrictionDbDto {
    const dbObj: CountryRestrictionDbDto = {
      residences: [],
      nationalities: [],
      id: this.countryRestrictionDbObj?.id,
    };

    restrictionDto.forEach((restriction) => {
      if (restriction.nationalityRestricted) {
        dbObj.nationalities.push(restriction.country);
      }

      if (restriction.residenceRestricted) {
        dbObj.residences.push(restriction.country);
      }
    });

    return dbObj;
  }

  @action
  resetRestrictions() {
    this.countries = [];
    this.restrictedCountries = [];
    this.countryRestrictionDbObj = undefined;
  }
}

const countryRestrictionStore = new CountryRestrictionStore();
export default countryRestrictionStore;
