import { TrashIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../components/button/Button';
import DropdownSelected from '../../../components/dropdown/DropdownSelected';
import TextInput from '../../../components/inputs/TextInput';
import LoadingAnimation from '../../../components/LoadingAnimation/LoadingAnimation';
import projectStore from '../../../stores/project.store';
import projectTeamStore from '../../../stores/projectTeam.store';
import { onPromise } from '../../../utils/formHelper';

const Team = () => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<any>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
  });

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await projectTeamStore.getInvitedUsers({
        project: projectStore.project!,
      });
      setIsLoading(false);
    };

    load();
  }, []);

  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const renderUser = () => {
    return projectTeamStore.users.map((user, index) => {
      return (
        <tr key={user.email}>
          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
            {user.email}
          </td>
          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
            {user.firstName} {user.lastName}
          </td>
          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
            <TrashIcon className='w-4 h-4' />
          </td>
        </tr>
      );
    });
  };

  return (
    <div className='h-full w-full p-6 max-w-2xl'>
      <div className='border p-4 rounded-md'>
        <div className='text-xl font-bold pb-4'>Current Team in project</div>

        <table className='min-w-full divide-y divide-gray-300 border-radius-t'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                E-Mail
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Name
              </th>
              <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                <span className='sr-only'>Delete</span>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {!isLoading && renderUser()}
            {isLoading && (
              <tr>
                <td></td>
                <td>
                  <LoadingAnimation size={40} className='p-3' />
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='border p-4 mt-4 rounded-md'>
        <div className='text-xl font-bold pb-4'>Invite User to project</div>
        <FormProvider {...methods}>
          <form onSubmit={onPromise(methods.handleSubmit(onSubmit))}>
            <div className='flex'>
              <TextInput
                className='w-2/3 mr-3'
                variable='email'
                placeHolder='Email'
                regExp={/[a-zA-Z0-9]/}
              />
              <DropdownSelected
                className='w-1/3'
                selectedOption='ADMIN'
                name='role'
                control={methods.control}
                options={[
                  { value: 'ADMIN', label: 'Admin' },
                  { value: 'SPECTATOR', label: 'Spectator' },
                ]}
              />
            </div>
            <div className='flex justify-end'>
              <Button
                className='mt-4 w-64'
                type='submit'
                title='Save'
                style='primary'
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default observer(Team);
