import RegulaRequest from '../components/UserServices/regula/RegulaRequest';
import ParashiftRequest from '../components/UserServices/parashift/ParashiftRequest';
import ParashiftResponse from '../components/UserServices/parashift/ParashiftResponse';
import { VerificationServiceType } from '../enums/VerificationServiceType';
import UserVerificationServiceDto from '../interfaces/user/verification-service.dto';
import RegulaResponse from '../components/UserServices/regula/RegulaResponse';
import ComplyAdvantageResponse from '../components/UserServices/complyadvantage/ComplyAdvantageResponse';
import ComplyAdvantageRequest from '../components/UserServices/complyadvantage/ComplyAdvantageRequest';
import ChainalysisRequest from '../components/UserServices/chainalysis/ChainalysisRequest';
import ChainalysisResponse from '../components/UserServices/chainalysis/ChainalysisResponse';
import IPScannerRequest from '../components/UserServices/ipscanner/IPScannerRequest';
import IPScannerResponse from '../components/UserServices/ipscanner/IPScannerResponse';
import NameRequest from '../components/UserServices/name/NameRequest';
import AddressRequest from '../components/UserServices/address/AddressRequest';
import CountryRestrictionResponse from '../components/UserServices/country-restriction/CountryRestrictionResponse';
import CountryRestrictionRequest from '../components/UserServices/country-restriction/CountryRestrictionRequest';
import KeyWordResponse from '../components/UserServices/keyword/KeyWordResponse';
import AcceptedRequest from '../components/UserServices/accepted/AcceptedRequest';

const verificationServiceResponse = (
  service: UserVerificationServiceDto
): JSX.Element | undefined | false => {
  switch (service.type) {
    case VerificationServiceType.REGULA:
      return <RegulaResponse data={service.response} />;
    case VerificationServiceType.PARASHIFT:
      return <ParashiftResponse data={service.response} />;
    case VerificationServiceType.COMPLYADVANTAGE:
      return <ComplyAdvantageResponse data={service.response} />;
    case VerificationServiceType.CHAINALYSIS:
      return <ChainalysisResponse data={service.response} />;
    case VerificationServiceType.IP_CHECKER:
      return <IPScannerResponse data={service.response} />;
    case VerificationServiceType.COUNTRY_RESTRICTION:
      return <CountryRestrictionResponse data={service.response} />;
    case VerificationServiceType.KEYWORDS:
      return <KeyWordResponse data={service.response} />;
    default:
      return;
  }
};

const verificationServiceRequest = (
  service: UserVerificationServiceDto
): JSX.Element | undefined | false => {
  switch (service.type) {
    case VerificationServiceType.REGULA:
      return <RegulaRequest data={service.request} />;
    case VerificationServiceType.PARASHIFT:
      return <ParashiftRequest data={service.request} />;
    case VerificationServiceType.COMPLYADVANTAGE:
      return <ComplyAdvantageRequest data={service.request} />;
    case VerificationServiceType.CHAINALYSIS:
      return <ChainalysisRequest data={service.request} />;
    case VerificationServiceType.IP_CHECKER:
      return <IPScannerRequest data={service.request} />;
    case VerificationServiceType.NAME:
      return <NameRequest data={service.request} />;
    case VerificationServiceType.ADDRESS:
      return <AddressRequest data={service.request} />;
    case VerificationServiceType.COUNTRY_RESTRICTION:
      return <CountryRestrictionRequest data={service.request} />;
    case VerificationServiceType.ACCEPTED:
      return <AcceptedRequest data={service.request} />;
    case VerificationServiceType.KEYWORDS:
      return false;
    default:
      return;
  }
};

const verificationServiceTitle = (type: VerificationServiceType): string => {
  switch (type) {
    case VerificationServiceType.COMPLYADVANTAGE:
      return 'Comply Advantage';
    case VerificationServiceType.CHAINALYSIS:
      return 'Chainalysis';
    case VerificationServiceType.PARASHIFT:
      return 'Parashift';
    case VerificationServiceType.REGULA:
      return 'Regula';
    case VerificationServiceType.IP_CHECKER:
      return 'IP Checker';
    case VerificationServiceType.NAME:
      return 'Name';
    case VerificationServiceType.ADDRESS:
      return 'Address';
    case VerificationServiceType.COUNTRY_RESTRICTION:
      return 'Country-Restriction';
    case VerificationServiceType.KEYWORDS:
      return 'Keywords';
    case VerificationServiceType.ACCEPTED:
      return 'Accepted';
    default:
      return '';
  }
};

export {
  verificationServiceTitle,
  verificationServiceResponse,
  verificationServiceRequest,
};
