import { parse as parseflatted } from 'flatted';
import { getCountryIcon, getCountryLabel } from '../../../utils/Address.util';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';

interface Props {
  data: any;
}

const CountryRestrictionRequest = ({ data }: Props) => {
  const dataObj = parseflatted(data);
  if (dataObj.nationality || dataObj.residency) {
    return (
      <div className='grid gap-3 grid-cols-3'>
        {dataObj.nationality && (
          <IconTitleItem
            className='mt-2'
            icon={getCountryIcon(dataObj.nationality)}
            title='Nationality'
            subTitle={getCountryLabel(dataObj.nationality)}
          />
        )}
        {dataObj.residency && (
          <IconTitleItem
            className='mt-2'
            icon={getCountryIcon(dataObj.residency)}
            title='Residency'
            subTitle={getCountryLabel(dataObj.residency)}
          />
        )}
      </div>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default CountryRestrictionRequest;
