import UserInfo from '../../UserInfo/UserInfo';
import { UserDto } from '../../../../interfaces/User.dto';
import VerificationStatusCard from '../VerificationStatusCard';
import PersonalInfoUserData from '../VerificationSteps/PersonalInfo/PersonalInfoData';
import IconTitleItem from '../../../IconTitleItem/IconTitleItem';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import CompanyInfoData from '../CompanyInfo/CompanyInfoData';
import FlowType from '../../../../enums/FlowType';

interface UserDetailInfoProps {
  user: UserDto;
  extendedContent?: JSX.Element;
}

export const UserDetailInfo = ({
  user,
  extendedContent,
}: UserDetailInfoProps) => {
  const renderChechboxes = () => {
    return (
      <div className='grid gap-2 grid-cols-2 border-b mb-3 pb-5 mt-5'>
        {user.flowType == FlowType.KYC && (
          <IconTitleItem
            icon={<InformationCircleIcon />}
            title={'Beneficial Owner Accepted'}
            subTitle={user.isBeneficialOwnerAccepted ? 'Yes' : 'No'}
          />
        )}

        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={'Terms And Conditions Accepted'}
          subTitle={user.isTermsAndConditionsAccepted ? 'Yes' : 'No'}
        />
      </div>
    );
  };

  return (
    <VerificationStatusCard status={user.status}>
      <>
        <UserInfo user={user} hideStatus />
        {renderChechboxes()}
        {user.personalInfo ? (
          <div className='mt-5'>
            <PersonalInfoUserData personalInfo={user.personalInfo} limited />
          </div>
        ) : (
          user.companyInfo && (
            <div className='mt-5'>
              <CompanyInfoData companyInfo={user.companyInfo} limited />
            </div>
          )
        )}
        {extendedContent}
      </>
    </VerificationStatusCard>
  );
};
