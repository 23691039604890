import React, { ReactElement, ReactNode } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
  variable: string;
  placeHolder: string;
  type?: string;
  className?: string;
  errorMessage?: string;
  regExp: RegExp;
  readOnly?: boolean;
  required?: boolean;
}

const TextInput: React.FC<Props> = ({
  variable,
  placeHolder,
  type = "text",
  className = "",
  errorMessage = "Invalid Input.",
  regExp,
  readOnly = false,
  required = true,
}: Props): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();


  const inputStyle = `border rounded-lg border rounded-lg outline-0 h-10 px-2.5 w-full text-sm ${
    readOnly ? "text-gray-400" : "focus:border-primary"
  } ${
    errors[variable] == null ? "border-gray-300" : "border-red-600 border-2"
  } `;

  return (
    <div className={className}>
      <input
        {...register(variable, {
          required: required ? 'Input required.' : undefined,
          pattern: {
            value: regExp,
            message: errorMessage,
          },
        })}
        readOnly={readOnly}
        placeholder={placeHolder}
        type={type}
        autoComplete="off"
        className={inputStyle}
      />
      <div className="h-5">
        {errors[variable] != null && (
          <div className="text-red-600 text-xs">
            {errors[variable]?.message as ReactNode}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
