import { VerificationStatus } from '../enums/VerificationStatus';
import VerificationServiceDto from '../interfaces/config/verification-steps/verification-service.dto';
import UserVerificationServiceDto from '../interfaces/user/verification-service.dto';

const getVerificationStepUserServices = (args: {
  configServices: VerificationServiceDto[];
  userServices: UserVerificationServiceDto[];
}): UserVerificationServiceDto[] => {
  const services: UserVerificationServiceDto[] = [];
  let notStarted = false;
  if (!args.userServices || args.userServices.length <= 0) {
    notStarted = true;
  }
  for (const configService of args.configServices) {
    if (!notStarted) {
      const typeServices = args.userServices.filter(
        (service) => service.type === configService.type
      );

      if (typeServices && typeServices.length > 0) {
        var service = typeServices.reduce(function (a, b) {
          return a.created_at! > b.created_at! ? a : b;
        });

        if (service) {
          services.push(service);

          if (service.status !== VerificationStatus.COMPLETED) {
            notStarted = true;
          }

          continue;
        }
      }
    }

    services.push({
      type: configService.type,
      status: VerificationStatus.NOT_STARTED,
    });
    if (!notStarted) {
      notStarted = true;
    }
  }

  return services;
};

export { getVerificationStepUserServices };
