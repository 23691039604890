import {
  CalendarIcon,
  FlagIcon,
  DocumentIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import {
  CalculatorIcon,
  IdentificationIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import * as Flags from 'country-flag-icons/react/3x2';
import IconTitleItem from '../../../../IconTitleItem/IconTitleItem';
import ReactPlayer from 'react-player';
import FilePreview from '../../../../filepreview/FilePreview';
import { getSortedDocuments } from '../../../../../utils/IdAndLiveness.util';

interface ProofOfResidenceDataProps {
  idAndLiveness: any;
}

const IdAndLivenessData = ({ idAndLiveness }: ProofOfResidenceDataProps) => {
  const renderCountryFlag = (country: string) => {
    const Flag = (Flags as any)[country];
    if (!Flag) return <FlagIcon />;
    return <Flag />;
  };

  let score = null;
  if (idAndLiveness.score) {
    if (idAndLiveness.score.similarity) {
      score = (Math.floor(idAndLiveness.score.similarity * 100) + '%') as any;
    } else {
      score = '0%' as any;
    }
  }

  const renderDocumentImages = (documentImages: any) => {
    if (documentImages && documentImages.length > 0) {
      const sortedDocuments = getSortedDocuments(documentImages);

      if (sortedDocuments.length > 0) {
        return (
          <div className='max-w-[640px]'>
            <div className='font-bold mt-5 mb-3'>Document Image</div>
            <FilePreview file={sortedDocuments[0].value} doubleMaxHeight />
          </div>
        );
      }
    }

    return <></>;
  };

  const generateDocumentType = (documentType: string) => {
    if (documentType === 'passports') {
      return 'Passport';
    } else if (documentType === 'drivingLicenses') {
      return 'Driving License';
    } else if (documentType === 'idCards') {
      return 'ID Card';
    }
  };

  return (
    <div>
      {idAndLiveness.documentMissmatch && (
        <div className='mb-8'>
          <IconTitleItem
            icon={<InformationCircleIcon />}
            title='Missmatch'
            subTitle={idAndLiveness.documentMissmatch}
          />
        </div>
      )}
      <div className='grid gap-5 grid-cols-2 w-2/3'>
        {idAndLiveness.name && (
          <IconTitleItem
            icon={<UserIcon />}
            title='Name'
            subTitle={idAndLiveness.name}
          />
        )}
        {idAndLiveness.isAlive !== null && (
          <IconTitleItem
            icon={<UserIcon />}
            title='Is Alive'
            subTitle={idAndLiveness.isAlive ? 'Yes' : 'Not confirmed'}
          />
        )}
        {idAndLiveness.dateOfExpiry && (
          <IconTitleItem
            icon={<UserIcon />}
            title='Date Of Expiry'
            subTitle={idAndLiveness.dateOfExpiry}
          />
        )}
        {idAndLiveness.dateOfBirth && (
          <IconTitleItem
            icon={<CalendarIcon />}
            title='Date of Birth'
            subTitle={idAndLiveness.dateOfBirth}
          />
        )}
        {idAndLiveness.country && (
          <IconTitleItem
            icon={
              idAndLiveness.country ? (
                renderCountryFlag(idAndLiveness.country)
              ) : (
                <FlagIcon />
              )
            }
            title='Country'
            subTitle={idAndLiveness.country}
          />
        )}
        {idAndLiveness.documentName && (
          <IconTitleItem
            icon={<DocumentIcon />}
            title='Document Name'
            subTitle={idAndLiveness.documentName}
          />
        )}
        {idAndLiveness.documentNumber && (
          <IconTitleItem
            icon={<DocumentIcon />}
            title='Document Number'
            subTitle={idAndLiveness.documentNumber}
          />
        )}
        {idAndLiveness.sex && (
          <IconTitleItem
            icon={<UserIcon />}
            title='Sex'
            subTitle={idAndLiveness.sex}
          />
        )}
        {score && (
          <IconTitleItem
            icon={<IdentificationIcon />}
            title='Similarity'
            subTitle={score}
          />
        )}
        {idAndLiveness.selectedDocumentType && (
          <IconTitleItem
            icon={<CalculatorIcon />}
            title='Selected Document Type'
            subTitle={generateDocumentType(idAndLiveness.selectedDocumentType)}
          />
        )}
      </div>
      {idAndLiveness.documentImages &&
        renderDocumentImages(idAndLiveness.documentImages)}
      {idAndLiveness.video && (
        <>
          <div className='font-bold mt-5 mb-3'>Live Video</div>
          <ReactPlayer url={idAndLiveness.video} controls />
        </>
      )}
    </div>
  );
};

export default IdAndLivenessData;
