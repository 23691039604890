import { TrashIcon } from "@heroicons/react/24/solid";
import React, { ReactElement, useState } from "react";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  file: string;
  deleteFile: () => void;
}

const FilePreviewExtended: React.FC<Props> = ({
  file,
  deleteFile,
}: Props): ReactElement => {
  const [imgError, setImgError] = useState(false);

  return (
    <>
      <div className="bg-gray-100 rounded-lg relative p-2">
        {imgError ? (
          <iframe
            src={file}
            title="file-preview"
            height="250px"
            className="rounded-lg border-0 w-full"
          />
        ) : (
          <div className="h-60 overflow-auto">
            <img
              alt="file-preview"
              src={file}
              className="rounded-lg border-0 w-full overflow-auto object-scale-down"
              onError={() => {
                setImgError(true);
              }}
            />
          </div>
        )}
        <div
          className="absolute top-3 right-3 cursor-pointer"
          onClick={() => {
            deleteFile && deleteFile();
          }}
        >
          <TrashIcon
            color="black"
            className="p-1 bg-white rounded-full w-7 h-7"
          />
        </div>
      </div>
    </>
  );
};

export default FilePreviewExtended;
