import { action, makeObservable, observable } from 'mobx';
import ProjectDto from '../interfaces/Project.dto';
import axios from '../utils/Axios';
import { toast } from 'react-toastify';
import clientUserStore from './clientUser.store';
import projectConfigStore from './project-config.store';

export class ProjectStore {
  @observable project: ProjectDto | undefined;
  @observable projects: ProjectDto[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async createProject(project: ProjectDto): Promise<Boolean> {
    try {
      const response = await axios.post('/projects', project);
      if (response.status === 201) {
        await this.getProjects();
        toast.success('Project created successfully');
        return true;
      }
    } catch (e) {
      toast.error('Error creating project');
      console.log(e);
      return false;
    }

    return false;
  }

  @action
  async getProjects(): Promise<void> {
    const response = await axios.get('/projects');
    if (response.status === 200) {
      this.projects = response.data;
    }
  }

  @action
  setProject(project: ProjectDto) {
    this.project = project;
  }

  async getProject(project: string): Promise<void> {
    const response = await axios.get('/projects/' + project);
    if (response.status === 200 && response.data) {
      const project = response.data;
      if (project.projectConfig) {
        projectConfigStore.setConfig(project.projectConfig);
      }

      this.setProject(project);
    }
  }

  @action
  async updateProject(updateProject: Partial<ProjectDto>): Promise<void> {
    try {
      if (!this.project) return;
      const response = await axios.put(
        '/projects/' + this.project.id,
        updateProject
      );
      if (response.status === 200) {
        await this.getProjects();
        toast.success('Project updated successfully');
      }
    } catch (e) {
      toast.error('Error updating project');
      console.log(e);
    }
  }

  @action
  resetProject() {
    this.project = undefined;
    projectConfigStore.resetConfig();
    clientUserStore.resetUsers();
  }
}

const projectStore = new ProjectStore();
export default projectStore;
