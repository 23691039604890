import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { parse as parseflatted } from "flatted";
import FilePreview from "../../filepreview/FilePreview";
import IconTitleItem from "../../IconTitleItem/IconTitleItem";

interface Props {
  data: any;
}

const KeyWordResponse = ({ data }: Props) => {
  const dataObj = parseflatted(data);

  if (data.length === 0) {
    return <div className="text-gray-500">No keywords found</div>;
  }

  if (!Array.isArray(dataObj)) {
    return (
      <div className="grid gap-3 grid-cols-3 mb-3 pb-3">
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={"Keyword Other"}
          subTitle={"Not Found"}
        />
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={"Source of Funds Evidence"}
          subTitle={"Not Found"}
        />
      </div>
    );
  }

  const itemURL = dataObj.find(
    (item: any) => item.key === "sourceOfFundsEvidence"
  );

  return (
    <div>
      <div className="grid gap-3 grid-cols-3 border-b mb-3 pb-3">
        {dataObj.map((item: any) => {
          if (item.key === "sourceOfFundsEvidence") {
            return <></>;
          }
          return (
            <IconTitleItem
              icon={<InformationCircleIcon />}
              title={item.key
                .split(/(?=[A-Z])/)
                .join(" ")
                .toLowerCase()}
              subTitle={item.other}
            />
          );
        })}
      </div>
      {itemURL && <FilePreview file={itemURL.value} />}
    </div>
  );
};

export default KeyWordResponse;
