import FlowType from '../enums/FlowType';

const flowTypeTitle = (flowType: FlowType): string => {
  switch (flowType) {
    case FlowType.KYC:
      return 'KYC';
    case FlowType.KYB:
      return 'KYB';
    case FlowType.KYB_DIRECTOR:
      return 'Director';
    default:
      return '';
  }
};

export { flowTypeTitle };
