import React, { MouseEventHandler, ReactElement } from 'react';
import './Button.css'

interface Props {
  style: 'primary' | 'secondary'
  title: string
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
}

const Button: React.FC<Props> = ({ style, title, onClick, className = '', disabled = false, type = 'button' }): ReactElement => {
  const buttonStyle = `rounded-lg text-white test-base h-8 px-2.5 ${(style === 'primary' && disabled) ? 'bg-primary/50' : (style === 'primary' && !disabled) ? 'bg-primary' : (style === 'secondary' && disabled) ? 'bg-secondary/50' : 'bg-secondary'} ${className}`;
  return (
    <div>
        <button
          type={type}
          className={buttonStyle}
          onClick={onClick}
          disabled={disabled}>
          {title}
        </button>
    </div>

  );
}

export default Button;
