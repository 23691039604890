import { VerificationStepType } from '../../enums/VerificationStepType';
import { VerificationStepsConfigStore } from '../verification-steps-config.store';

const kybDirectorStepsConfigStore = new VerificationStepsConfigStore({
  allowedStepTypes: [
    VerificationStepType.PERSONAL_INFO,
    VerificationStepType.ID_AND_LIVENESS,
    VerificationStepType.PROOF_OF_RESIDENCY,
    VerificationStepType.FINISH,
  ],
});
export default kybDirectorStepsConfigStore;
