import {
  BuildingLibraryIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
  MapPinIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { CompanyInfoUserDto } from '../../../../interfaces/user/verification-steps/company-info.dto';
import {
  getAddressString,
  getCountryIcon,
  getCountryLabel,
} from '../../../../utils/Address.util';
import IconTitleItem from '../../../IconTitleItem/IconTitleItem';

interface Props {
  companyInfo: CompanyInfoUserDto;
  limited?: boolean;
  twoColMode?: boolean;
  displayOwnerPercent?: boolean;
}

const CompanyInfoUserData = ({
  companyInfo,
  limited = false,
  twoColMode = false,
  displayOwnerPercent = false,
}: Props) => {
  return (
    <div className={`grid gap-5 ${twoColMode ? 'grid-cols-2' : 'grid-cols-3'}`}>
      {displayOwnerPercent && (
        <IconTitleItem
          icon={<CalculatorIcon />}
          title='Owner Percentage'
          subTitle={`${companyInfo.ownerPercentage}%`}
        />
      )}
      {!limited ? (
        <>
          <IconTitleItem
            icon={<BuildingOffice2Icon />}
            title='Company Name'
            subTitle={companyInfo.companyName}
          />
          <IconTitleItem
            icon={<BuildingLibraryIcon />}
            title='Legal Form'
            subTitle={companyInfo.legalForm}
          />
          {!twoColMode && <div></div>}
          <IconTitleItem
            icon={getCountryIcon(companyInfo.placeOfIncorperation)}
            title='Place of Incorporation'
            subTitle={getCountryLabel(companyInfo.placeOfIncorperation)}
          />
          <IconTitleItem
            icon={<PlayIcon />}
            title='Date of Incorporation'
            subTitle={
              companyInfo.dateOfIncorporation
                ? format(
                    new Date(companyInfo.dateOfIncorporation),
                    'dd.MM.yyyy'
                  )
                : ''
            }
          />
          <IconTitleItem
            icon={<PlayIcon />}
            title='Registration Number'
            subTitle={companyInfo.registartionNumber}
          />
        </>
      ) : (
        <></>
      )}
      <IconTitleItem
        icon={<MapPinIcon />}
        title='Postal address'
        subTitle={getAddressString(companyInfo.address)}
      />
      <IconTitleItem
        icon={getCountryIcon(companyInfo.address?.country)}
        title='Residency'
        subTitle={getCountryLabel(companyInfo.address?.country)}
      />
    </div>
  );
};

export default CompanyInfoUserData;
