import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import Card from "../card/Card";
import { useDropzone } from "react-dropzone";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

import Button from "../button/Button";
import projectConfigStore from "../../stores/project-config.store";

interface Props {
  infoText: any;
  onChange?: (file: string) => void;
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileUpload: React.FC<Props> = ({
  infoText = "",
  onChange,
}: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = async (acceptedFiles: File[]) => {
    setIsLoading(true);
    const url = await projectConfigStore.uploadFile(acceptedFiles[0]);
    setIsLoading(false);
    onChange && onChange(url);
  };

  // eslint-disable-next-line
  const { getRootProps, getInputProps } = useDropzone({
    // eslint-disable-next-line
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
  });

  const renderContent = (): ReactElement => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center">
          <div className="text-sm font-medium">Uploading</div>
        </div>
      );
    }

    return (
      <div className="flex flex-col justify-center items-center w-full">
        <input {...getInputProps()} />
        <CloudArrowUpIcon className="w-7" />
        <p className="text-base font-bold">DROP FILE HERE</p>
        <p className="lg:text-sm text-xs font-medium text-center">{infoText}</p>
        <div className="relative flex py-7 items-center">
          <div className="flex-grow border-2 rounded-lg border-secondary lg:w-8 w-5"></div>
          <span className="flex-shrink mx-4 text-secondary">OR</span>
          <div className="flex-grow border-2 rounded-lg border-secondary lg:w-8 w-5"></div>
        </div>
        <Button
          type="button"
          title="Browse File"
          style="primary"
          className="lg:text-sm text-xs"
        />
      </div>
    );
  };

  return (
    <>
      <div className="w-full">
        <Card className="rounded-lg p-5 border-2 border-dashed hover:border-primary max-h-64">
          <section className="w-full">
            <div
              {...getRootProps({ className: "dropzone" })}
              className="h-auto"
            >
              {renderContent()}
            </div>
          </section>
        </Card>
      </div>
    </>
  );
};

export default FileUpload;
