import UserDetail from '../../../components/User/UserDetail/UserDetail';
import VerificationStatusCard from '../../../components/User/UserDetail/VerificationStatusCard';
import VerificationServiceCards from '../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import clientUserStore from '../../../stores/clientUser.store';
import { getUserVerificationStepStatus } from '../../../utils/User.util';
import { getVerificationStepUserServices } from '../../../helpers/VerificationService.helper';
import { verificationStepTitle } from '../../../utils/VerificationSteps.util';
import { observer } from 'mobx-react';
import UserVerificationServiceDto from '../../../interfaces/user/verification-service.dto';
import VerificationStepSettings from '../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import BeneficialOwnerData from '../../../components/User/UserDetail/BeneficialOwner/BeneficialOwnerData';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function UserDetailBeneficialOwner() {
  const stepType = VerificationStepType.BENEFICIAL_OWNER;
  const beneficialOwner = clientUserStore.user?.beneficialOwner;

  let services: UserVerificationServiceDto[] = [];

  const { user } = useParams();

  const reloadUser = () => {
    if (user) {
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  };

  useEffect(() => {
    reloadUser();
  }, []);

  if (beneficialOwner) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: beneficialOwner.services ? beneficialOwner.services : [],
    });
  }

  const renderBeneficialOwnerData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          beneficialOwner && <VerificationStepSettings step={stepType} />
        }
      >
        {beneficialOwner && (
          <BeneficialOwnerData
            beneficialOwner={beneficialOwner}
            reloadUser={reloadUser}
          />
        )}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderBeneficialOwnerData()}
        {beneficialOwner &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className='mt-8'
              services={services}
              comments={beneficialOwner.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailBeneficialOwner);
