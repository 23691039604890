import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { Role } from '../../enums/Role';
import { VerificationServiceType } from '../../enums/VerificationServiceType';
import { VerificationStepType } from '../../enums/VerificationStepType';
import CommentDto from '../../interfaces/user/comment.dto';
import keycloak from '../../keycloak';
import clientUserStore from '../../stores/clientUser.store';
import { getKeycloakName, isUserReadyonly } from '../../utils/KeycloakUser.util';
import Button from '../button/Button';
import RequireAuth from '../RequireAuth';
import AutosizeTextarea from '../textarea/AutosizeTextarea';
import Comment from './Comment';

interface Props {
  stepType: VerificationStepType;
  serviceType: VerificationServiceType;
  comments?: CommentDto[];
  className?: string;
}

const CommentSection = ({
  stepType,
  comments = [],
  serviceType,
  className = '',
}: Props) => {
  const [comment, setComment] = useState('');
  const [user, setUser] = useState<any>();

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      if (userInfo) {
        setUser(userInfo);
      }
    });
  }, []);

  const handleChange = (event: any) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event: any) => {
    clientUserStore.setComment({
      stepType: stepType,
      comment: {
        userName: getKeycloakName(user),
        comment: comment,
        serviceType,
      },
    });
    setComment('');
    event.preventDefault();
  };

  return (
    <div className={className}>
      <div className='mb-2 font-bold'>Comments</div>
      {comments && comments.length > 0 && (
        <div className='mb-4'>
          {comments.map((comment, index) => {
            return <Comment comment={comment} key={index} />;
          })}
        </div>
      )}
      <RequireAuth roles={[Role.ADMIN]}>
        <form className='mb-4' onSubmit={handleSubmit}>
          <div className='mb-2'>
            <AutosizeTextarea
              onChange={handleChange}
              placeholder='Write a comment...'
              name='comment'
              defaultValue={comment}
              required
            />
          </div>
          <div className='flex justify-end'>
            <Button
              className='w-32'
              type='submit'
              title='Post'
              style='primary'
            />
          </div>
        </form>
      </RequireAuth>
    </div>
  );
};

export default CommentSection;
