import { NavLink, useLocation } from 'react-router-dom';
import {
  UserGroupIcon,
  Cog6ToothIcon,
  Squares2X2Icon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import ProfileMenu from '../ProfileMenu';
import { getIdByUrl } from '../../utils/UrlParser';
import RequireAuth from '../RequireAuth';
import { Role } from '../../enums/Role';
import ExportCsvButton from '../User/CSV/ExportCsvButton';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function NavBar() {
  const { keycloak } = useKeycloak();

  const [projectId, setProjectId] = useState<string | null>(null);
  let location = useLocation();

  useEffect(() => {
    setProjectId(getIdByUrl('projects'));
  }, [location]);

  if (!keycloak.authenticated) {
    return <></>;
  }

  return (
    <div className='bg-background flex h-full p-2'>
      <nav className='flex flex-col justify-between' aria-label='Sidebar'>
        <div className='flex justify-center flex-col w-full items-center'>
          {projectId && (
            <>
              <RequireAuth roles={[Role.ADMIN]}>
                <>
                  <NavLink
                    to={`/projects/${projectId}/approve`}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'text-primary' : '',
                        'flex py-4 px-1'
                      )
                    }
                  >
                    <CheckCircleIcon className='h-8 w-8' />
                  </NavLink>
                  <NavLink
                    to={`/projects/${projectId}/warning`}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'text-primary' : '',
                        'flex py-4 px-1'
                      )
                    }
                  >
                    <ExclamationTriangleIcon className='h-8 w-8' />
                  </NavLink>
                </>
              </RequireAuth>
              <NavLink
                to={`/projects/${projectId}/users`}
                className={({ isActive }) =>
                  classNames(isActive ? 'text-primary' : '', 'flex py-4 px-1')
                }
              >
                <UserGroupIcon className='h-8 w-8' />
              </NavLink>
              <NavLink
                to={`/projects/${projectId}/configuration`}
                className={({ isActive }) =>
                  classNames(isActive ? 'text-primary' : '', 'flex py-4 px-1')
                }
              >
                <Cog6ToothIcon className='h-8 w-8' />
              </NavLink>
              <div className='flex py-4 px-1'>
                <ExportCsvButton size={8} />
              </div>
            </>
          )}
        </div>
        <div className='flex flex-col w-full justify-center items-center'>
          <NavLink
            end
            to='/projects'
            className={({ isActive }) =>
              classNames(isActive ? 'text-primary' : '', 'flex py-4 px-1')
            }
          >
            <Squares2X2Icon className='h-8 w-8' />
          </NavLink>
          <div className='w-12 h-12 mb-4'>
            <div className='w-full aspect-square'>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
