import { DocType } from '../enums/DocType';

const getDocumentTypeLabel = (docType: DocType) => {
  switch (docType) {
    case DocType.BANK_STATEMENT:
      return 'Bank Statement';
    case DocType.LANDLINE_BILL:
      return 'Landline Bill';
    case DocType.ELECTRICITY_BILL:
      return 'Electricity Bill';
    case DocType.GAS_BILL:
      return 'Gas Bill';
    case DocType.WATER_BILL:
      return 'Water Bill';
    default:
      return '';
  }
};

export { getDocumentTypeLabel };
