import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import LoadingAnimation from '../../../components/LoadingAnimation/LoadingAnimation';
import SideBySideLayout from '../../../components/PageLayouts/SideBySideLayout';
import TextSearchBarRow from '../../../components/User/TextSearch/TextSearchBarRow';
import UserInfoCard from '../../../components/User/UserInfo/UserInfoCard';
import clientUserStore from '../../../stores/clientUser.store';
import filterStore from '../../../stores/filter.store';
import projectStore from '../../../stores/project.store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import { verificationStatusButtonClassName } from '../../../utils/VerificationStatus.util';
import UserFilter from '../../../components/User/Filter/UserFilter';
import ActiveUserStatusFilterRow from '../../../components/User/Filter/ActiveUserStatusFilterRow';
import FlowType from '../../../enums/FlowType';

interface Props {
  isWarning?: boolean;
  isToApprove?: boolean;
}

function Users({ isWarning = false, isToApprove = false }: Props): JSX.Element {
  const { user } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientUserStore.isToApprove !== isToApprove) {
      clientUserStore.setIsToApprove(isToApprove);
    }

    if (clientUserStore.isWarning !== isWarning) {
      clientUserStore.setIsWarning(isWarning);
    }

    filterStore.resetFilter();
    clientUserStore.initUsers();
    clientUserStore.removeAllMarkedUsers();
  }, [isToApprove, isWarning]);

  useEffect(() => {
    if (clientUserStore.usersLoadingCount <= 0) {
      const baseUrl = `/projects/${
        projectStore.project!.id
      }/${clientUserStore.getUsersParentUrlParam()}`;

      if (user && !hasUsers()) {
        navigate(baseUrl);
      } else if (
        hasUsers() &&
        (!user ||
          (clientUserStore.users.findIndex((u) => u.id === user) < 0 &&
            clientUserStore.users.findIndex(
              (u) =>
                u.flowType === FlowType.KYB &&
                u.directorApproval?.director?.id === user
            ) < 0))
      ) {
        navigate(`${baseUrl}/${clientUserStore.users[0].id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUserStore.users, clientUserStore.users?.length, user]);

  const renderToApproveActionRow = () => {
    return (
      <div className='mt-4'>
        <div className='flex justify-end items-center'>
          <button
            className={verificationStatusButtonClassName(
              VerificationStatus.REJECTED
            )}
            onClick={() => {
              clientUserStore.setRejectUsers();
            }}
          >
            Reject
          </button>
          <button
            className={verificationStatusButtonClassName(
              VerificationStatus.COMPLETED
            )}
            onClick={() => {
              clientUserStore.setApproveUsers();
            }}
          >
            Approve
          </button>
          <input
            type='checkbox'
            checked={clientUserStore.allUsersMarked()}
            className='accent-primary cursor-pointer w-4 h-4'
            onChange={(e) => {
              if (e.target.checked) {
                clientUserStore.markAllUsers();
              } else {
                clientUserStore.removeAllMarkedUsers();
              }
            }}
          />
        </div>
      </div>
    );
  };

  function renderUserSideHeader() {
    let statusOption = undefined;
    let flowTypeOptions = [FlowType.KYC, FlowType.KYB];

    if (isWarning || isToApprove) {
      flowTypeOptions.push(FlowType.KYB_DIRECTOR);
    } else {
      statusOption = [
        VerificationStatus.COMPLETED,
        VerificationStatus.REJECTED,
      ];
    }

    return (
      <div className='border-b p-4'>
        <div className='flex justify-between items-center '>
          <div className='text-xl font-bold'>Users</div>
          <div className='flex flex-row h-min'>
            <button
              onClick={() => {
                filterStore.setTextSearchActive(!filterStore.textSearchActive);
              }}
              className='mr-2'
            >
              <MagnifyingGlassIcon
                className={`h-6 w-6 ${
                  filterStore.textSearchActive ? 'text-primary' : ''
                }`}
              />
            </button>
            <UserFilter
              statusOptions={statusOption}
              flowTypeOptions={flowTypeOptions}
            />
          </div>
        </div>
        <TextSearchBarRow className='mt-4' />
        {clientUserStore.isToApprove &&
          clientUserStore.markedUsers.length > 0 &&
          renderToApproveActionRow()}
        <ActiveUserStatusFilterRow className='mt-4' />
      </div>
    );
  }

  function renderLoading() {
    return (
      <div className='flex justify-center py-5'>
        <LoadingAnimation size={40} />
      </div>
    );
  }

  function renderUserInfoCards() {
    const currentUser = user;
    if (clientUserStore.usersLoadingCount > 0) {
      return (
        <div className='w-full flex justify-center mt-20'>
          <LoadingAnimation />
        </div>
      );
    }

    if (hasUsers()) {
      return (
        <div className='flex-1 h-0 [&>*]:h-full'>
          <InfiniteScroll
            dataLength={clientUserStore.users.length}
            hasMore={clientUserStore.hasMoreUsers()}
            next={() => {
              clientUserStore.getNextUsers();
            }}
            loader={renderLoading()}
            height='100%'
          >
            {clientUserStore.users.map((user, index) => {
              return (
                <UserInfoCard
                  user={user}
                  active={user.id === currentUser}
                  directorActive={
                    user.flowType === FlowType.KYB &&
                    user.directorApproval?.director?.id === currentUser
                  }
                  project={projectStore.project!}
                  key={index}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      );
    } else {
      return (
        <div className='w-full flex justify-center mt-20'>No users found</div>
      );
    }
  }

  function renderSideChildren(): JSX.Element {
    return (
      <div className='min-w-96 w-96 h-full flex flex-col'>
        {renderUserSideHeader()}
        {renderUserInfoCards()}
      </div>
    );
  }

  function hasUsers(): boolean {
    if (clientUserStore.users) {
      return clientUserStore.users.length > 0;
    }

    return false;
  }

  return (
    <SideBySideLayout
      sideChildren={
        hasUsers() ||
        filterStore.hasActiveFilters() ||
        clientUserStore.usersLoadingCount > 0 ? (
          renderSideChildren()
        ) : (
          <></>
        )
      }
    >
      <div className='h-full flex-1 relative'>
        {/* Red background on top */}
        <div className='absolute top-0 left-0 w-full h-1/3 bg-gradient-to-r from-primary-400 to-primary-300 -z-20'></div>
        {/* Gray Background on bottom */}
        <div className='absolute top-0 left-0 w-full h-full bg-background -z-50'></div>
        <div className='w-full h-full'>
          {clientUserStore.usersLoadingCount > 0 && !user ? (
            <div className='w-full h-full flex justify-center items-center'>
              <LoadingAnimation />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </SideBySideLayout>
  );
}

export default observer(Users);
