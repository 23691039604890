import { XMarkIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import TextSearchInput from '../../../components/inputs/TextSearchInput';
import { CountryRestrictionDto } from '../../../interfaces/CountryRestriction.dto';
import countryRestrictionStore from '../../../stores/countryRestriction.store';
import { countries as allCountries } from '../../../data/countries';
import { getCountryLabelWithFlag } from '../../../utils/Address.util';
import RequireAuth from '../../../components/RequireAuth';
import { Role } from '../../../enums/Role';

const CountryRestrictions = () => {
  const [filter, setFilter] = useState('');
  const [filteredCountries, setFilteredCountries]: any[] = useState([]);

  useEffect(() => {
    setFilteredCountries(countryRestrictionStore.countries);
  }, []);

  useEffect(() => {
    if (filter) {
      const filtered = countryRestrictionStore.countries.filter((country) =>
        allCountries
          .find((c) => c.value === country.country)!
          .label.match(new RegExp(filter, 'i'))
      );

      setFilteredCountries(filtered);
    } else {
      setFilteredCountries(countryRestrictionStore.countries);
    }
  }, [filter]);

  const renderRestrictedCountries = () => {
    return (
      <div className='mt-4 max-w-2xl border rounded-md'>
        <table className='min-w-full divide-y divide-gray-300 border-radius-t'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='w-full px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Restricted Country
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Residence
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Nationality
              </th>
            </tr>
          </thead>
          <tbody>
            {countryRestrictionStore.restrictedCountries &&
            countryRestrictionStore.restrictedCountries.length > 0 ? (
              countryRestrictionStore.restrictedCountries.map((country) => {
                return (
                  <tr className='border-b' key={country.country}>
                    <td className='px-3 py-3.5'>
                      {getCountryLabelWithFlag(country.country)}
                    </td>
                    <td className='px-3 py-3.5'>
                      {country.residenceRestricted ? (
                        <XMarkIcon className='mx-auto w-6 h-6 text-primary' />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className='px-3 py-3.5'>
                      {country.nationalityRestricted ? (
                        <XMarkIcon className='mx-auto w-6 h-6 text-primary' />
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className='border-b'>
                <td className='px-3 py-3.5'>No Country Restriction</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderCountries = () => {
    return (
      <div className='mt-4 max-w-2xl border rounded-md'>
        <TextSearchInput
          className='m-2 w-64 ml-auto'
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <table className='min-w-full divide-y divide-gray-300 border-radius-t'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='w-full px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Country
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Residence
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Nationality
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCountries.map((country: CountryRestrictionDto) => {
              return (
                <tr className='border-b' key={country.country}>
                  <td className='px-3 py-3.5'>
                    {getCountryLabelWithFlag(country.country)}
                  </td>
                  <td className='px-3 py-3.5 text-center'>
                    <input
                      type='checkbox'
                      className='accent-primary'
                      onChange={(e) => {
                        countryRestrictionStore.setResidence({
                          country: country.country,
                          value: !e.target.checked,
                        });
                      }}
                      checked={!country.residenceRestricted}
                    />
                  </td>
                  <td className='px-3 py-3.5 text-center'>
                    <input
                      type='checkbox'
                      className='accent-primary'
                      onChange={(e) => {
                        countryRestrictionStore.setNationality({
                          country: country.country,
                          value: !e.target.checked,
                        });
                      }}
                      checked={!country.nationalityRestricted}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className='h-full w-full p-6'>
      <div className='text-xl font-bold'>Country Restrictions</div>
      <p className='mt-1 max-w-2xl text-sm text-gray-500'>
        Not selected countries are restricted
      </p>
      {renderRestrictedCountries()}
      <RequireAuth roles={[Role.ADMIN]}>{renderCountries()}</RequireAuth>
    </div>
  );
};

export default observer(CountryRestrictions);
