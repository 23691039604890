import {
  QuestionMarkCircleIcon,
  UserIcon,
  WalletIcon,
  CameraIcon,
  MapPinIcon,
  CheckBadgeIcon,
  AcademicCapIcon,
  DocumentTextIcon,
  BuildingOffice2Icon,
  ChartPieIcon,
} from '@heroicons/react/24/outline';
import { VerificationStepType } from '../enums/VerificationStepType';
import UserDetailIdAndLiveness from '../pages/ProjectPages/UserDetailPages/IdAndLiveness';
import UserDetailPersonalInfo from '../pages/ProjectPages/UserDetailPages/PersonalInfo';
import UserDetailProofOfResidency from '../pages/ProjectPages/UserDetailPages/ProofOfResidency';
import UserDetailQuestionnaire from '../pages/ProjectPages/UserDetailPages/Questionnaire';
import UserDetailWallet from '../pages/ProjectPages/UserDetailPages/Wallet';
import UserDetailCompanyInfo from '../pages/ProjectPages/UserDetailPages/CompanyInfo';
import UserDetailDirectorApproval from '../pages/ProjectPages/UserDetailPages/DirectorApproval';
import UserDetailBeneficialOwner from '../pages/ProjectPages/UserDetailPages/BeneficialOwner';
import UserDetailLegalDocuments from '../pages/ProjectPages/UserDetailPages/LegalDocuments';

const verificationStepUserDetailPage = (
  verificationStepType: VerificationStepType
): JSX.Element => {
  switch (verificationStepType) {
    case VerificationStepType.PERSONAL_INFO:
      return <UserDetailPersonalInfo />;
    case VerificationStepType.ID_AND_LIVENESS:
      return <UserDetailIdAndLiveness />;
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return <UserDetailProofOfResidency />;
    case VerificationStepType.QUESTIONNAIRE:
      return <UserDetailQuestionnaire />;
    case VerificationStepType.WALLET:
      return <UserDetailWallet />;
    case VerificationStepType.COMPANY_INFO:
      return <UserDetailCompanyInfo />;
    case VerificationStepType.DIRECTOR_APPROVAL:
      return <UserDetailDirectorApproval />;
    case VerificationStepType.BENEFICIAL_OWNER:
      return <UserDetailBeneficialOwner />;
    case VerificationStepType.LEGAL_DOCUMENTS:
      return <UserDetailLegalDocuments />;
    default:
      return <></>;
  }
};

const verificationStepSlug = (
  verificationStepType: VerificationStepType
): string => {
  return verificationStepType;
};

const verificationStepTitle = (
  verificationStepType: VerificationStepType
): string => {
  switch (verificationStepType) {
    case VerificationStepType.PERSONAL_INFO:
      return 'Personal Info';
    case VerificationStepType.ID_AND_LIVENESS:
      return 'ID and Liveness';
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return 'Proof of residency';
    case VerificationStepType.QUESTIONNAIRE:
      return 'Questionnaire';
    case VerificationStepType.WALLET:
      return 'Wallet';
    case VerificationStepType.FINISH:
      return 'Finish';
    case VerificationStepType.COMPANY_INFO:
      return 'Company Info';
    case VerificationStepType.DIRECTOR_APPROVAL:
      return 'Director Approval';
    case VerificationStepType.LEGAL_DOCUMENTS:
      return 'Legal Documents';
    case VerificationStepType.BENEFICIAL_OWNER:
      return 'Beneficial Owner';
    default:
      return '';
  }
};

const verificationStepEntityName = (step: VerificationStepType): string => {
  switch (step) {
    case VerificationStepType.PERSONAL_INFO:
      return 'personalInfo';
    case VerificationStepType.ID_AND_LIVENESS:
      return 'idAndLiveness';
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return 'proofOfResidency';
    case VerificationStepType.QUESTIONNAIRE:
      return 'questionnaire';
    case VerificationStepType.WALLET:
      return 'wallet';
    case VerificationStepType.FINISH:
      return 'finish';
    case VerificationStepType.COMPANY_INFO:
      return 'companyInfo';
    case VerificationStepType.DIRECTOR_APPROVAL:
      return 'directorApproval';
    case VerificationStepType.LEGAL_DOCUMENTS:
      return 'legalDocuments';
    case VerificationStepType.BENEFICIAL_OWNER:
      return 'beneficialOwner';
    default:
      return '';
  }
};

const verificationStepIcon = (
  verificationStepType: VerificationStepType
): JSX.Element => {
  switch (verificationStepType) {
    case VerificationStepType.PERSONAL_INFO:
      return <UserIcon />;
    case VerificationStepType.ID_AND_LIVENESS:
      return <CameraIcon />;
    case VerificationStepType.PROOF_OF_RESIDENCY:
      return <MapPinIcon />;
    case VerificationStepType.QUESTIONNAIRE:
      return <QuestionMarkCircleIcon />;
    case VerificationStepType.WALLET:
      return <WalletIcon />;
    case VerificationStepType.FINISH:
      return <CheckBadgeIcon />;
    case VerificationStepType.COMPANY_INFO:
      return <BuildingOffice2Icon />;
    case VerificationStepType.LEGAL_DOCUMENTS:
      return <DocumentTextIcon />;
    case VerificationStepType.DIRECTOR_APPROVAL:
      return <AcademicCapIcon />;
    case VerificationStepType.BENEFICIAL_OWNER:
      return <ChartPieIcon />;
    default:
      return <></>;
  }
};

export {
  verificationStepEntityName,
  verificationStepSlug,
  verificationStepTitle,
  verificationStepUserDetailPage,
  verificationStepIcon,
};
