import UserVerificationServiceDto from '../../interfaces/user/verification-service.dto';
import {
  verificationServiceRequest,
  verificationServiceResponse,
  verificationServiceTitle,
} from '../../utils/VerificationServices.util';
import VerificationStatusCard from '../User/UserDetail/VerificationStatusCard';
import FlattedText from '../flatted-text/FlattedText';
import { VerificationStepType } from '../../enums/VerificationStepType';
import CommentDto from '../../interfaces/user/comment.dto';
import CommentSection from '../comments/CommentSection';
import clientUserStore from '../../stores/clientUser.store';
import { isUserReadyonly } from '../../utils/KeycloakUser.util';
import { useKeycloak } from '@react-keycloak/web';

interface Props {
  service: UserVerificationServiceDto;
  stepType: VerificationStepType;
  comments?: CommentDto[];
}

const UserService = ({ service, stepType, comments = [] }: Props) => {
  const { keycloak } = useKeycloak();

  const renderRequest = () => {
    const request = verificationServiceRequest(service);

    if (request === false) {
      return null;
    }

    return (
      <>
        {request ? request : <FlattedText flattedText={service.request!} />}
        <hr className='my-8 h-0.5 bg-gray-200 border-0'></hr>
      </>
    );
  };

  const renderResponse = () => {
    const response = verificationServiceResponse(service);

    if (response === false) {
      return null;
    }

    return (
      <>
        {response ? response : <FlattedText flattedText={service.response!} />}
        <hr className='my-8 h-0.5 bg-gray-200 border-0'></hr>
      </>
    );
  };

  const renderContent = () => {
    const hasCommentSection = !(
      isUserReadyonly(keycloak) && comments.length <= 0
    );

    if (hasCommentSection || service.request || service.response) {
      return (
        <>
          {service.request && renderRequest()}
          {service.response && renderResponse()}
          {hasCommentSection && (
            <CommentSection
              serviceType={service.type}
              comments={comments}
              stepType={stepType}
              className='mt-5'
            />
          )}
        </>
      );
    }
  };

  return (
    <VerificationStatusCard
      title={verificationServiceTitle(service.type)}
      status={service.status}
      statusChangable={clientUserStore.isUserEditable()}
      onStatusChange={(status) => {
        clientUserStore.setServiceStatus({
          service,
          status,
          stepType: stepType,
        });
      }}
    >
      {renderContent()}
    </VerificationStatusCard>
  );
};

export default UserService;
