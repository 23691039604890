import { Menu } from '@headlessui/react';
import React from 'react';
import FlowTypeItem from '../UserFlowType/FlowTypeItem';
import FlowType from '../../../enums/FlowType';
import filterStore from '../../../stores/filter.store';

interface Props {
  flowType: FlowType;
}

const FilterFlowTypeItem = ({ flowType }: Props) => {
  return (
    <div className='p-1'>
      <Menu.Item>
        <button
          onClick={() => {
            filterStore.setFlowTypeFilter(flowType);
          }}
        >
          <FlowTypeItem flowType={flowType} noBorder />
        </button>
      </Menu.Item>
    </div>
  );
};

export default FilterFlowTypeItem;
