export enum VerificationStepType {
  PERSONAL_INFO = "personal-info",
  ID_AND_LIVENESS = "id-and-liveness",
  PROOF_OF_RESIDENCY = "proof-of-residency",
  QUESTIONNAIRE = "questionnaire",
  WALLET = "wallet",
  FINISH = "finish",
  COMPANY_INFO = "company-info",
  DIRECTOR_APPROVAL = "director-approval",
  LEGAL_DOCUMENTS = "legal-documents",
  BENEFICIAL_OWNER = 'beneficial-owner',
}
