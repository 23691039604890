import { Menu } from '@headlessui/react';
import React from 'react';
import VerificationStatusItem from '../UserStatus/StatusItem';
import filterStore from '../../../stores/filter.store';
import { VerificationStatus } from '../../../enums/VerificationStatus';

interface Props {
  status: VerificationStatus;
}

const FilterStatusItem = ({ status }: Props) => {
  return (
    <div className='p-1'>
      <Menu.Item>
        <button
          onClick={() => {
            filterStore.setStatusFilter(status);
          }}
        >
          <VerificationStatusItem status={status} noBackground />
        </button>
      </Menu.Item>
    </div>
  );
};

export default FilterStatusItem;
