import { UserDetailInfo } from '../../../components/User/UserDetail/Overview/UserDetailInfo';
import UserDetail from '../../../components/User/UserDetail/UserDetail';
import IconTitleItem from '../../../components/IconTitleItem/IconTitleItem';
import { observer } from 'mobx-react';
import clientUserStore from '../../../stores/clientUser.store';
import {
  verificationStepIcon,
  verificationStepTitle,
} from '../../../utils/VerificationSteps.util';
import {
  verificationStatusText,
  verificationStatusTextColor,
} from '../../../utils/VerificationStatus.util';
import { getUserVerificationStepStatus } from '../../../utils/User.util';
import VerificationStatusCard from '../../../components/User/UserDetail/VerificationStatusCard';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import { getVerificationStepUserServices } from '../../../helpers/VerificationService.helper';
import UserVerificationServiceDto from '../../../interfaces/user/verification-service.dto';
import VerificationServiceCards from '../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards';
import CommentDto from '../../../interfaces/user/comment.dto';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import Sessions from './Sessions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function UserDetailOverview() {
  let finishServices: UserVerificationServiceDto[] = [];
  let finishComments: CommentDto[] | undefined;

  const configStore = stepConfigStore(clientUserStore.user?.flowType);

  const { user } = useParams();
  useEffect(() => {
    if (user) {
      clientUserStore.resetUser();
      clientUserStore.getUser({ user });
    }
  }, [user]);

  if (
    configStore.getVerificationStepEnabled(VerificationStepType.FINISH) &&
    clientUserStore.user?.finish?.comments
  ) {
    finishComments = clientUserStore.user.finish.comments;
    finishServices = getVerificationStepUserServices({
      configServices: configStore.getVerificationServices(
        VerificationStepType.FINISH
      ),
      userServices: clientUserStore.user.finish.services
        ? clientUserStore.user.finish.services
        : [],
    });
  }

  return (
    <UserDetail>
      <div>
        <UserDetailInfo user={clientUserStore.user!} />
        <VerificationStatusCard title='Verifications'>
          <div className='grid gap-5 grid-cols-2 w-3/4'>
            {configStore.getActiveVerificationSteps().map((step, index) => {
              const status = getUserVerificationStepStatus({
                user: clientUserStore.user!,
                step,
              });
              return (
                <IconTitleItem
                  key={index}
                  icon={verificationStepIcon(step)}
                  title={verificationStepTitle(step)}
                  subTitle={verificationStatusText(status)}
                  subTitleColor={verificationStatusTextColor(status)}
                />
              );
            })}
          </div>
        </VerificationStatusCard>
        <VerificationServiceCards
          className='mt-8'
          services={finishServices}
          comments={finishComments}
          stepType={VerificationStepType.FINISH}
          hideOverview
        />
        <Sessions />
      </div>
    </UserDetail>
  );
}

export default observer(UserDetailOverview);
