import { XMarkIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { countries as allCountries } from '../../../../data/countries';
import { Role } from '../../../../enums/Role';
import IdAndLivenessConfigDto from '../../../../interfaces/config/verification-steps/id-and-liveness-config.dto';
import DocumentRestrictionDbDto from '../../../../interfaces/config/verification-steps/id-and-liveness/document-restriction-db.dto';
import { DocumentRestrictionDto } from '../../../../interfaces/config/verification-steps/id-and-liveness/document-restriction.dto';
import countryRestrictionStore from '../../../../stores/countryRestriction.store';
// import idAndLivenessStore from '../../../../stores/verification-steps/id-and-liveness.store';
import { getCountryLabelWithFlag } from '../../../../utils/Address.util';
import TextSearchInput from '../../../inputs/TextSearchInput';
import RequireAuth from '../../../RequireAuth';

interface Props {
  documentDBObj: DocumentRestrictionDbDto;
  onChange: (e: any) => void;
}

const DocumentRestriction = ({ documentDBObj, onChange }: Props) => {
  const [filter, setFilter] = useState('');
  const [filtered, setFiltered]: any[] = useState([]);
  const [documentCountries, setDocumentCountries]: any[] = useState([]);
  const [restrictedCountries, setRestrictedCountries]: any[] = useState([]);

  useEffect(() => {
    const docCountries: any[] = [];
    const restricted: any[] = [];
    allCountries.forEach((countryObj) => {
      let documentCountry: DocumentRestrictionDto = {
        country: countryObj.value,
        passport: documentDBObj.passports.indexOf(countryObj.value) >= 0,
        idCard: documentDBObj.idCards.indexOf(countryObj.value) >= 0,
        drivingLicense:
          documentDBObj.drivingLicenses.indexOf(countryObj.value) >= 0,
      };

      docCountries.push(documentCountry);

      if (
        documentCountry.passport ||
        documentCountry.drivingLicense ||
        documentCountry.idCard
      ) {
        restricted.push(documentCountry);
      }
    });
    setDocumentCountries(docCountries);
    setRestrictedCountries(restricted);
  }, [documentDBObj]);

  const updateDocumentRestriction = () => {
    const documentRestriction: DocumentRestrictionDto[] = [];
    documentCountries.forEach((country: DocumentRestrictionDto) => {
      if (country.idCard || country.drivingLicense || country.passport) {
        documentRestriction.push(country);
      }
    });

    setRestrictedCountries(documentRestriction);
    onChange(convertToDbObj(documentRestriction));
    // this.updateDocumentRestriction();
  };

  useEffect(() => {
    if (filter) {
      const filteredDocsCountry = documentCountries.filter(
        (country: DocumentRestrictionDto) =>
          allCountries
            .find((c) => c.value === country.country)!
            .label.match(new RegExp(filter, 'i'))
      );
      setFiltered(filteredDocsCountry);
    } else {
      setFiltered(documentCountries);
    }
  }, [filter, documentCountries]);

  const renderRestrictedDocuments = () => {
    return (
      <div className='mt-4 max-w-2xl border rounded-md'>
        <table className='min-w-full divide-y divide-gray-300 border-radius-t'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='w-full px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Country
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Passport
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900'
              >
                ID-Card
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Driving License
              </th>
            </tr>
          </thead>
          <tbody>
            {restrictedCountries && restrictedCountries.length > 0 ? (
              restrictedCountries.map((country: DocumentRestrictionDto) => {
                return (
                  <tr className='border-b' key={country.country}>
                    <td className='px-3 py-3.5'>
                      {getCountryLabelWithFlag(country.country)}
                    </td>
                    <td className='px-3 py-3.5'>
                      {country.passport ? (
                        <XMarkIcon className='mx-auto w-6 h-6 text-primary' />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className='px-3 py-3.5'>
                      {country.idCard ? (
                        <XMarkIcon className='mx-auto w-6 h-6 text-primary' />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className='px-3 py-3.5'>
                      {country.drivingLicense ? (
                        <XMarkIcon className='mx-auto w-6 h-6 text-primary' />
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className='border-b'>
                <td className='px-3 py-3.5'>No Document Restriction</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const convertToDbObj = (docs: DocumentRestrictionDto[]) => {
    const dbObj: any = {
      id: documentDBObj.id,
      passports: [],
      idCards: [],
      drivingLicenses: [],
    };

    docs.forEach((obj) => {
      if (obj.passport) {
        dbObj.passports.push(obj.country);
      }

      if (obj.idCard) {
        dbObj.idCards.push(obj.country);
      }

      if (obj.drivingLicense) {
        dbObj.drivingLicenses.push(obj.country);
      }
    });

    return dbObj;
  };

  const renderDocumentCountries = () => {
    return (
      <div className='mt-4 max-w-2xl border rounded-md'>
        <TextSearchInput
          className='m-2 w-64 ml-auto'
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <table className='min-w-full divide-y divide-gray-300 border-radius-t'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='w-full px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Country
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Passport
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm whitespace-nowrap font-semibold text-gray-900'
              >
                ID-Card
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
              >
                Driving License
              </th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((docsCountry: DocumentRestrictionDto) => {
              const isRestricted = countryRestrictionStore.isCountryRestricted(
                docsCountry.country
              );

              return (
                <tr
                  className={`border-b ${isRestricted ? 'opacity-50' : ''}`}
                  key={docsCountry.country}
                >
                  <td className='px-3 py-3.5'>
                    {getCountryLabelWithFlag(docsCountry.country)}
                  </td>
                  <td className='px-3 py-3.5 text-center'>
                    <input
                      type='checkbox'
                      className='accent-primary'
                      onChange={(e) => {
                        if (!isRestricted) {
                          const index = documentCountries.findIndex(
                            (c: DocumentRestrictionDto) =>
                              c.country === docsCountry.country
                          );

                          if (index !== -1) {
                            documentCountries[index].passport =
                              !e.target.checked;
                            setDocumentCountries(documentCountries);
                            updateDocumentRestriction();
                          }
                        }
                      }}
                      checked={!isRestricted && !docsCountry.passport}
                    />
                  </td>
                  <td className='px-3 py-3.5 text-center'>
                    <input
                      type='checkbox'
                      className='accent-primary'
                      readOnly={isRestricted}
                      onChange={(e) => {
                        const index = documentCountries.findIndex(
                          (c: DocumentRestrictionDto) =>
                            c.country === docsCountry.country
                        );

                        if (index !== -1) {
                          documentCountries[index].idCard = !e.target.checked;
                          setDocumentCountries(documentCountries);
                          updateDocumentRestriction();
                        }
                      }}
                      checked={!isRestricted && !docsCountry.idCard}
                    />
                  </td>
                  <td className='px-3 py-3.5 text-center'>
                    <input
                      type='checkbox'
                      className='accent-primary'
                      readOnly={isRestricted}
                      onChange={(e) => {
                        const index = documentCountries.findIndex(
                          (c: DocumentRestrictionDto) =>
                            c.country === docsCountry.country
                        );

                        if (index !== -1) {
                          documentCountries[index].drivingLicense =
                            !e.target.checked;
                          setDocumentCountries(documentCountries);
                          updateDocumentRestriction();
                        }
                      }}
                      checked={!isRestricted && !docsCountry.drivingLicense}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className='mt-5'>
      <div className='text-xl font-bold'>Documents</div>
      <p className='mt-1 max-w-2xl text-sm text-gray-500'>
        Not selected Documents are not allowed
      </p>
      {renderRestrictedDocuments()}
      <RequireAuth roles={[Role.ADMIN]}>
        {renderDocumentCountries()}
      </RequireAuth>
    </div>
  );
};

export default observer(DocumentRestriction);
