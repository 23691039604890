import { Outlet } from 'react-router-dom';
import ConfigurationNavBar from '../../components/Nav/ConfigurationNavBar';
import SideBySideLayout from '../../components/PageLayouts/SideBySideLayout';

function Configuration(): JSX.Element {
  return (
    <SideBySideLayout sideChildren={<ConfigurationNavBar />}>
      <>
        <Outlet />
      </>
    </SideBySideLayout>
  );
}

export default Configuration;
