import React, { ReactElement } from "react";
import { Switch } from "@headlessui/react";
import Button from "../button/Button";

interface Props {
  title: string;
  text: string;
  onChange: (enabled: boolean) => void;
  enabled: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Information = ({
  title,
  text,
  onChange,
  enabled,
}: Props): ReactElement => {
  return (
    <div className="relative mb-3 rounded-md overflow-hidden text-left h-64 max-w-2xl">
      <div className="absolute bg-yellow-300 opacity-20 top-0 left-0 right-0 bottom-0"></div>
      <div className="absolute top-0 right-0 left-0 bottom-0 p-3 flex justify-between flex-col">
        <div className="z-50">
          <h3 className="mb-3 mt-5 font-bold">{title}</h3>
          <p className="text-sm text-gray-600">{text}</p>
        </div>
        <div className="w-full flex justify-end">
          <Button
            className="cursor-pointer"
            onClick={() => onChange(!enabled)}
            title="Deactivate Testmode"
            style="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default Information;
