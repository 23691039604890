import {
  BellIcon,
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
  ArrowUturnRightIcon,
} from '@heroicons/react/24/solid';
import { VerificationStatus } from '../../../enums/VerificationStatus';

interface UserStatusIconProps {
  status?: VerificationStatus;
  className?: string;
}

function StatusIcon({ className, status }: UserStatusIconProps): JSX.Element {
  switch (status) {
    case VerificationStatus.COMPLETED:
      return <CheckCircleIcon className={className} />;
    case VerificationStatus.REJECTED:
      return <XCircleIcon className={className} />;
    case VerificationStatus.WARNING:
      return <BellIcon className={className} />;
    case VerificationStatus.REDO:
      return <ArrowUturnRightIcon className={className} />;
    default:
      return <ClockIcon className={className} />;
  }
}

export default StatusIcon;
