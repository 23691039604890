import React from 'react';
import { parse as parseflatted } from 'flatted';
import FilePreview from '../../filepreview/FilePreview';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { verificationStatusTextColor } from '../../../utils/VerificationStatus.util';
import { VerificationStatus } from '../../../enums/VerificationStatus';

interface Props {
  data: any;
}

const AcceptedRequest = ({ data }: Props) => {
  const parsedData = parseflatted(data);

  return (
    <>
      <div className='grid gap-5 grid-cols-1 mb-3 pb-5 mt-5'>
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={'Beneficial Owner Accepted'}
          subTitle={parsedData.isBeneficialOwnerAccepted ? 'Yes' : 'No'}
        />
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={'Terms And Conditions Accepted'}
          subTitle={parsedData.isTermsAndConditionsAccepted ? 'Yes' : 'No'}
        />
        <IconTitleItem
          icon={<InformationCircleIcon />}
          title={'Verified Data'}
          subTitle={parsedData.verifiedData ? 'Yes' : 'No'}
        />
      </div>
      {parsedData.acceptedDocuments &&
        parsedData.acceptedDocuments.map((document: any, i: number) => {
          if (document.url) {
            return (
              <div className='relative' key={i}>
                <FilePreview file={document.url} />
                {document.isAccepted ? (
                  <CheckCircleIcon
                    className={`absolute top-5 left-5 w-10 h-10 ${verificationStatusTextColor(
                      VerificationStatus.COMPLETED
                    )}`}
                  />
                ) : (
                  <XCircleIcon
                    className={`absolute top-5 left-5 w-10 h-10 ${verificationStatusTextColor(
                      VerificationStatus.REJECTED
                    )}`}
                  />
                )}
              </div>
            );
          }
          return <div key={i}></div>;
        })}
    </>
  );
};

export default AcceptedRequest;
