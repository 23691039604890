import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { countries, countryDropDownOptions } from '../../../data/countries';
import ReadonlyInput from '../ReadOnlyInput';

interface Props {
  name: string;
  options:
    | OptionsOrGroups<
        {
          value: string;
          label: JSX.Element;
        },
        GroupBase<{
          value: string;
          label: JSX.Element;
        }>
      >
    | undefined;
  control: any;
  placeholder: string;
  className?: string;
  readOnly?: boolean;
}

const DropdownWithSelect: React.FC<Props> = ({
  options,
  name,
  className = '',
  placeholder,
  control,
  readOnly = false,
}: Props): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) =>
        readOnly ? (
          <ReadonlyInput
            value={
              countryDropDownOptions.find((item) => item.value === field.value)
                ?.label
            }
          />
        ) : (
          <Select
            options={options}
            placeholder={placeholder}
            onChange={(select: any) => {
              field.onChange(select.value);
            }}
            value={countryDropDownOptions.find(
              (item) => item.value === field.value
            )}
            getOptionValue={(option) =>
              countries.find((country) => option.value === country.value)!.label
            }
            className='text-sm'
            theme={(theme: any) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: 'rgb(var(--color-secondary))',
                primary: 'rgb(var(--color-primary))',
              },
            })}
          />
        )
      }
    />
  );
};

export default DropdownWithSelect;
