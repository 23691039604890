import { WrenchIcon } from '@heroicons/react/24/outline';
import { VerificationStatus } from '../../../../enums/VerificationStatus';
import { VerificationStepType } from '../../../../enums/VerificationStepType';
import CommentDto from '../../../../interfaces/user/comment.dto';
import UserVerificationServiceDto from '../../../../interfaces/user/verification-service.dto';
import { verificationServiceTitle } from '../../../../utils/VerificationServices.util';
import {
  verificationStatusText,
  verificationStatusTextColor,
} from '../../../../utils/VerificationStatus.util';
import IconTitleItem from '../../../IconTitleItem/IconTitleItem';
import UserService from '../../../UserServices/UserService';
import VerificationStatusCard from '../VerificationStatusCard';

interface VerificationServiceCardsProps {
  services: UserVerificationServiceDto[];
  className?: string;
  stepType: VerificationStepType;
  comments?: CommentDto[];
  hideOverview?: boolean;
}

const VerificationServiceCards = ({
  services,
  className = '',
  stepType,
  comments = [],
  hideOverview = false,
}: VerificationServiceCardsProps) => {
  return (
    <div className={className}>
      {!hideOverview && (
        <VerificationStatusCard title='Services'>
          <div className='grid gap-5 grid-cols-3'>
            {services.map((service) => {
              return (
                <IconTitleItem
                  icon={<WrenchIcon />}
                  key={service.id}
                  title={verificationServiceTitle(service.type)}
                  subTitleColor={verificationStatusTextColor(service.status)}
                  subTitle={verificationStatusText(service.status)}
                />
              );
            })}
          </div>
        </VerificationStatusCard>
      )}
      {services.map((service) => {
        if (service.status !== VerificationStatus.NOT_STARTED) {
          return (
            <UserService
              service={service}
              key={service.id}
              comments={comments.filter((c) => c.serviceType === service.type)}
              stepType={stepType}
            />
          );
        }
      })}
    </div>
  );
};

export default VerificationServiceCards;
