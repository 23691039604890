import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import {
  verificationStatusBgColor,
  verificationStatusHoverBorderColor,
  verificationStatusText,
} from '../../../utils/VerificationStatus.util';
import StatusIcon from './StatusIcon';

interface VerificationStatusItemProps {
  status?: VerificationStatus;
  noBackground?: boolean;
  statusChangable?: boolean;
}

function VerificationStatusItem({
  status,
  noBackground = false,
  statusChangable,
}: VerificationStatusItemProps): JSX.Element {
  if (status) {
    const hoverClass = `border border-transparent ${verificationStatusHoverBorderColor(
      status
    )}`;
    return (
      <div
        className={`flex  items-center justify-center py-0.5 rounded-full 
        ${statusChangable ? `${hoverClass} w-32` : 'w-28'} 
        ${noBackground ? '' : verificationStatusBgColor(status)}`}
      >
        <StatusIcon status={status} className='h-4 w-4 mr-1' />
        <div className='text-sm'>{verificationStatusText(status)}</div>
        {statusChangable && <ChevronDownIcon className='h-4 w-4 ml-1' />}
      </div>
    );
  }
  return <></>;
}

export default VerificationStatusItem;
