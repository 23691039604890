import { XMarkIcon } from '@heroicons/react/24/outline';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import {
  verificationStatusBgColor,
  verificationStatusHoverBorderColor,
  verificationStatusText,
} from '../../../utils/VerificationStatus.util';
import FlowType from '../../../enums/FlowType';
import { flowTypeTitle } from '../../../utils/FlowType.util';

interface ActiveStatusFilterItemProps {
  flowType: FlowType;
}

function ActiveFlowTypeFilterItem({
  flowType,
}: ActiveStatusFilterItemProps): JSX.Element {
  return (
    <div className='flex items-center py-0.5 px-2 rounded-full border border-text hover:bg-background'>
      <div className='text-sm'>{flowTypeTitle(flowType)}</div>
      <XMarkIcon className='h-4 w-4 ml-1' />
    </div>
  );
}

export default ActiveFlowTypeFilterItem;
