import { VerificationStepType } from '../../enums/VerificationStepType';
import { VerificationStepsConfigStore } from '../verification-steps-config.store';

const kycStepsConfigStore = new VerificationStepsConfigStore({
  allowedStepTypes: [
    VerificationStepType.PERSONAL_INFO,
    VerificationStepType.ID_AND_LIVENESS,
    VerificationStepType.PROOF_OF_RESIDENCY,
    VerificationStepType.QUESTIONNAIRE,
    VerificationStepType.WALLET,
    VerificationStepType.FINISH,
  ],
});
export default kycStepsConfigStore;
