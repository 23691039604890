import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { parse as parseflatted } from 'flatted';
import React from 'react';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';

interface Props {
  data: any;
}

const ComplyAdvantageRequest = ({ data }: Props) => {
  const parsedData = parseflatted(data);
  if (parsedData.search_term) {
    return (
      <>
        <div className='font-bold underline mb-2'>Searchterm: </div>
        <div className='grid gap-3 grid-cols-3'>
          {typeof parsedData.search_term === 'string' ? (
            <IconTitleItem
              icon={<QuestionMarkCircleIcon />}
              title='Term'
              subTitle={parsedData.search_term}
            />
          ) : (
            Object.keys(parsedData.search_term).map(function (key) {
              return (
                <IconTitleItem
                  icon={<QuestionMarkCircleIcon />}
                  title={key}
                  key={key}
                  subTitle={parsedData.search_term[key]}
                />
              );
            })
          )}
        </div>
      </>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default ComplyAdvantageRequest;
