import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import FlowType from '../../../enums/FlowType';
import ProjectDto from '../../../interfaces/Project.dto';
import { UserDto } from '../../../interfaces/User.dto';
import clientUserStore from '../../../stores/clientUser.store';
import { getApproveRecommendation } from '../../../utils/User.util';
import UserInfo from './UserInfo';

interface UserInfoCardProps {
  user: UserDto;
  project: ProjectDto;
  active?: boolean;
  directorActive?: boolean;
}

function UserInfoCard({
  user,
  project,
  active = false,
  directorActive = false,
}: UserInfoCardProps): JSX.Element {
  const [checked, setChecked] = useState(false);

  active = active || directorActive;

  useEffect(() => {
    if (checked !== clientUserStore.isMarkedUser(user)) {
      setChecked(clientUserStore.isMarkedUser(user));
    }
  }, [clientUserStore.markedUsers, clientUserStore.markedUsers.length]);

  const userUrl = (userId: string) => {
    return `/projects/${
      project.id
    }/${clientUserStore.getUsersParentUrlParam()}/${userId}`;
  };

  const markedUserRow = () => {
    return (
      clientUserStore.isToApprove && (
        <div className='flex justify-between mt-2 mr-2'>
          <div className='text-sm'>
            Recomendation: {getApproveRecommendation(user)}
          </div>
          <input
            type='checkbox'
            className='accent-primary cursor-pointer w-4 h-4'
            onChange={(e) => {
              if (e.target.checked) {
                clientUserStore.addMarkedUser(user);
              } else {
                clientUserStore.removeMarkedUser(user);
              }
            }}
            checked={checked}
          />
        </div>
      )
    );
  };

  const renderDirector = () => {
    if (user.directorApproval?.director) {
      const director = user.directorApproval.director;
      return (
        <div className={`border-b pl-5 box-border ${active ? '' : 'hidden'}`}>
          <NavLink to={userUrl(director.id)}>
            <div
              className={`p-2 w-full border-l ${directorActive ? 'bg-background' : ''}`}
            >
              <UserInfo user={director} hideInitials />
            </div>
          </NavLink>
        </div>
      );
    }
  };

  return (
    <>
      <NavLink to={userUrl(user.id)}>
        <div
          className={`p-2 w-full border-b  box-border ${
            active ? 'bg-background' : ''
          }`}
        >
          <UserInfo user={user} hideInitials />
          {markedUserRow()}
        </div>
      </NavLink>
      {user.flowType === FlowType.KYB && renderDirector()}
    </>
  );
}

export default observer(UserInfoCard);
