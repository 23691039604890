import {
  CalculatorIcon,
  CalendarIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { PersonalInfoUserDto } from '../../../../../interfaces/user/verification-steps/personal-info-user.dto';
import IconTitleItem from '../../../../IconTitleItem/IconTitleItem';
import { UserIcon } from '@heroicons/react/24/outline';
import {
  getAddressString,
  getCountryIcon,
  getCountryLabel,
} from '../../../../../utils/Address.util';

interface PersonalInfoUserDataProps {
  personalInfo: PersonalInfoUserDto;
  limited?: boolean;
  twoColMode?: boolean;
  displayOwnerPercent?: boolean;
}

const PersonalInfoUserData = ({
  personalInfo,
  limited = false,
  twoColMode = false,
  displayOwnerPercent = false,
}: PersonalInfoUserDataProps) => {
  return (
    <div className={`grid gap-5 ${twoColMode ? 'grid-cols-2' : 'grid-cols-3'}`}>
      {displayOwnerPercent && (
        <IconTitleItem
          icon={<CalculatorIcon />}
          title='Owner Percentage'
          subTitle={`${personalInfo.ownerPercentage}%`}
        />
      )}
      {!limited ? (
        <>
          <IconTitleItem
            icon={<UserIcon />}
            title='Firstname'
            subTitle={personalInfo.firstName ?? '-'}
          />
          <IconTitleItem
            icon={<UserIcon />}
            title='Middlename'
            subTitle={personalInfo.middleName ? personalInfo.middleName : '-'}
          />
          <IconTitleItem
            icon={<UserIcon />}
            title='Lastname'
            subTitle={personalInfo.lastName ?? '-'}
          />
        </>
      ) : (
        <></>
      )}
      <IconTitleItem
        icon={<CalendarIcon />}
        title='Birth date'
        subTitle={
          personalInfo.dateOfBirth
            ? format(new Date(personalInfo.dateOfBirth), 'dd.MM.yyyy')
            : ''
        }
      />
      <IconTitleItem
        icon={<MapPinIcon />}
        title='Postal address'
        subTitle={getAddressString(personalInfo.address)}
      />
      {!twoColMode && <div></div>}
      <IconTitleItem
        icon={getCountryIcon(personalInfo.address?.country)}
        title='Residency'
        subTitle={getCountryLabel(personalInfo.address?.country)}
      />
      <IconTitleItem
        icon={getCountryIcon(personalInfo.nationality)}
        title='Nationality'
        subTitle={getCountryLabel(personalInfo.nationality)}
      />
    </div>
  );
};

export default PersonalInfoUserData;
