import { VerificationStatus } from '../enums/VerificationStatus';

const verificationStatusButtonTextColor = (
  verificationStatus?: VerificationStatus
): string => {
  switch (verificationStatus) {
    case VerificationStatus.COMPLETED:
      return 'text-green-700';
    default:
      return verificationStatusTextColor(verificationStatus);
  }
};

const verificationStatusTextColor = (
  verificationStatus?: VerificationStatus
): string => {
  switch (verificationStatus) {
    case VerificationStatus.COMPLETED:
      return 'text-green-500';
    case VerificationStatus.REJECTED:
      return 'text-red-500';
    default:
      return 'text-yellow-500';
  }
};

const verificationStatusBgColor = (
  verificationStatus?: VerificationStatus
): string => {
  switch (verificationStatus) {
    case VerificationStatus.COMPLETED:
      return 'bg-green-200';
    case VerificationStatus.REJECTED:
      return 'bg-red-200';
    default:
      return 'bg-yellow-200';
  }
};

const verificationStatusHoverBorderColor = (
  verificationStatus?: VerificationStatus
): string => {
  switch (verificationStatus) {
    case VerificationStatus.COMPLETED:
      return 'hover:border-green-600';
    case VerificationStatus.REJECTED:
      return 'hover:border-red-600';
    default:
      return 'hover:border-yellow-600';
  }
};

const verificationStatusText = (
  verificationStatus?: VerificationStatus
): string => {
  switch (verificationStatus) {
    case VerificationStatus.COMPLETED:
      return 'Completed';
    case VerificationStatus.REJECTED:
      return 'Rejected';
    case VerificationStatus.IN_REVIEW:
      return 'In Review';
    case VerificationStatus.STARTED:
      return 'Started';
    case VerificationStatus.WARNING:
      return 'Warning';
    case VerificationStatus.REDO:
      return 'Redo';
    default:
      return 'Not Started';
  }
};

const verificationStatusButtonClassName = (status: VerificationStatus) => {
  return `mr-2 px-4 rounded-full border border-transparent ${verificationStatusBgColor(
    status
  )} ${verificationStatusButtonTextColor(
    status
  )} ${verificationStatusHoverBorderColor(status)}`;
};

export {
  verificationStatusButtonTextColor,
  verificationStatusTextColor,
  verificationStatusBgColor,
  verificationStatusHoverBorderColor,
  verificationStatusText,
  verificationStatusButtonClassName
};
