import React, { useState } from 'react';
import { VerificationStepType } from '../../../../../../enums/VerificationStepType';
import { UserDto } from '../../../../../../interfaces/User.dto';
import { verificationStepTitle } from '../../../../../../utils/VerificationSteps.util';
import CompanyInfoUserData from '../../../CompanyInfo/CompanyInfoData';
import LegalDocumentsData from '../../../LegalDocuments/LegalDocumentsData';
import BeneficialOwnerDialogTabs from '../BeneficialOwnerDialogTabs';
import BeneficialOwnerServices from './BeneficialOwnerServices';

interface Props {
  user: UserDto;
}

const BeneficialOwnerCompany = ({ user }: Props) => {
  const tabs = [
    VerificationStepType.COMPANY_INFO,
    VerificationStepType.LEGAL_DOCUMENTS,
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const renderLegalDocuments = () => {
    if (user.legalDocuments) {
      return (
        <>
          <LegalDocumentsData legalDocuments={user.legalDocuments} />
          {user.legalDocuments.services && (
            <BeneficialOwnerServices services={user.legalDocuments.services} />
          )}
        </>
      );
    }
    return <div className='w-fit mt-10 mx-auto'>No Legal Documents yet</div>;
  };

  const renderCompanyInfo = () => {
    if (user.companyInfo) {
      return (
        <>
          <CompanyInfoUserData
            companyInfo={user.companyInfo}
            twoColMode
            displayOwnerPercent
          />
          {user.companyInfo.services && (
            <BeneficialOwnerServices services={user.companyInfo.services} />
          )}
        </>
      );
    }
    return <div className='w-fit mt-10 mx-auto'>No Company Info yet</div>;
  };

  return (
    <div>
      <BeneficialOwnerDialogTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={(tab) => setCurrentTab(tab)}
        user={user}
      />
      <div className='mt-5'>
        {currentTab === VerificationStepType.COMPANY_INFO &&
          renderCompanyInfo()}
        {currentTab === VerificationStepType.LEGAL_DOCUMENTS &&
          renderLegalDocuments()}
      </div>
    </div>
  );
};

export default BeneficialOwnerCompany;
