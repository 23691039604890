import React, { ReactElement, useState } from 'react';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  file: string;
  doubleMaxHeight?: boolean;
}

const FilePreview: React.FC<Props> = ({
  file,
  doubleMaxHeight = false,
}: Props): ReactElement => {
  const [imgError, setImgError] = useState(false);

  return (
    <>
      <div className='bg-gray-100 rounded-lg relative p-2'>
        {imgError ? (
          <iframe
            src={file}
            title='file-preview'
            height={doubleMaxHeight ? '500px' : '250px'}
            className='rounded-lg border-0 w-full'
          />
        ) : (
          <div
            className={`overflow-auto ${
              doubleMaxHeight ? 'max-h-[500px]' : 'max-h-[250px]'
            }`}
          >
            <img
              alt='file-preview'
              src={file}
              className='rounded-lg border-0 w-full overflow-auto object-scale-down'
              onError={() => {
                setImgError(true);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FilePreview;
