const getIdByUrl = (parent: string) => {
  const splittedPath = window.location.pathname.split('/');
  const projectsIndex = splittedPath.findIndex((p) => p === parent);
  if (
    projectsIndex &&
    splittedPath.length > projectsIndex + 1 &&
    splittedPath[projectsIndex + 1].includes('-')
  ) {
    return splittedPath[projectsIndex + 1];
  }
  return null;
};

export { getIdByUrl };
