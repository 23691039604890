import FlowType from '../enums/FlowType';
import { VerificationServiceType } from '../enums/VerificationServiceType';
import VerificationServiceDto from '../interfaces/config/verification-steps/verification-service.dto';
import kybStepsConfigStore from '../stores/flow-steps-config/kyb';
import kybCompanyStepsConfigStore from '../stores/flow-steps-config/kybCompany';
import kybDirectorStepsConfigStore from '../stores/flow-steps-config/kybDirector';
import kybUserStepsConfigStore from '../stores/flow-steps-config/kybUser';
import kycStepsConfigStore from '../stores/flow-steps-config/kyc';
import { VerificationStepsConfigStore } from '../stores/verification-steps-config.store';

const stepConfigStore = (flowType?: FlowType): VerificationStepsConfigStore => {
  switch (flowType) {
    case FlowType.KYB:
      return kybStepsConfigStore;
    case FlowType.KYB_COMPANY:
      return kybCompanyStepsConfigStore;
    case FlowType.KYB_DIRECTOR:
      return kybDirectorStepsConfigStore;
    case FlowType.KYB_USER:
      return kybUserStepsConfigStore;
    case FlowType.KYC:
    default:
      return kycStepsConfigStore;
  }
};

const setAllStepConfigs = (config: any) => {
  if (config.verificationKYCStepsConfig) {
    stepConfigStore(FlowType.KYC).setConfig(config.verificationKYCStepsConfig);
  }
  if (config.verificationKYBStepsConfig) {
    stepConfigStore(FlowType.KYB).setConfig(config.verificationKYBStepsConfig);
  }
  if (config.verificationKYBDirectorStepsConfig) {
    stepConfigStore(FlowType.KYB_DIRECTOR).setConfig(
      config.verificationKYBDirectorStepsConfig
    );
  }
  if (config.verificationKYBCompanyStepsConfig) {
    stepConfigStore(FlowType.KYB_COMPANY).setConfig(
      config.verificationKYBCompanyStepsConfig
    );
  }
  if (config.verificationKYBUserStepsConfig) {
    stepConfigStore(FlowType.KYB_USER).setConfig(
      config.verificationKYBUserStepsConfig
    );
  }
};

const flowTypeSlug = (flowType?: FlowType): string => {
  switch (flowType) {
    case FlowType.KYB:
      return 'kyb';
    case FlowType.KYB_COMPANY:
      return 'kyb-company';
    case FlowType.KYB_DIRECTOR:
      return 'kyb-director';
    case FlowType.KYB_USER:
      return 'kyb-user';
    case FlowType.KYC:
    default:
      return 'kyc';
  }
};

const getAllActiveVerificationSteps = () => {
  const allSteps = [
    ...kycStepsConfigStore.getActiveVerificationSteps(),
    ...kybStepsConfigStore.getActiveVerificationSteps(),
    ...kybCompanyStepsConfigStore.getActiveVerificationSteps(),
    ...kybDirectorStepsConfigStore.getActiveVerificationSteps(),
    ...kybUserStepsConfigStore.getActiveVerificationSteps(),
  ];

  return allSteps.filter((item, pos) => allSteps.indexOf(item) === pos);
};

const getAllVerificationServices = (): VerificationServiceDto[] => {
  const allServices = [
    ...kycStepsConfigStore.getAllServices(),
    ...kybStepsConfigStore.getAllServices(),
    ...kybCompanyStepsConfigStore.getAllServices(),
    ...kybDirectorStepsConfigStore.getAllServices(),
    ...kybUserStepsConfigStore.getAllServices(),
  ];

  const services: VerificationServiceDto[] = Object.values(
    VerificationServiceType
  ).map((type) => {
    return {
      type,
      count: 0,
    };
  });

  allServices.forEach((service) => {
    const index = services.findIndex((s) => s.type === service.type);
    if (index !== -1) {
      services[index].count = services[index].count! + service.count!;
    }
  });

  return services;
};

const resetAllStepConfigs = () => {
  kycStepsConfigStore.resetConfig();
  kybStepsConfigStore.resetConfig();
  kybCompanyStepsConfigStore.resetConfig();
  kybDirectorStepsConfigStore.resetConfig();
  kybUserStepsConfigStore.resetConfig();
};

export {
  stepConfigStore,
  resetAllStepConfigs,
  setAllStepConfigs,
  flowTypeSlug,
  getAllActiveVerificationSteps,
  getAllVerificationServices,
};
