import React from "react";
import { parse as parseflatted } from "flatted";
import IconTitleItem from "../../IconTitleItem/IconTitleItem";
import { WrenchIcon } from "@heroicons/react/24/outline";

interface Props {
  data: any;
}

const IPScannerRequest = ({ data }: Props) => {
  const parsedData = parseflatted(data);
  return (
    <>
      <IconTitleItem
        icon={<WrenchIcon />}
        title={"IP Address"}
        subTitle={parsedData[0]}
      />
    </>
  );
};

export default IPScannerRequest;
