import React, { useState } from 'react';
import { Switch } from '@headlessui/react';

interface Props {
  onChange: (value: boolean) => void;
  initialyEnabled?: boolean;
  readOnly?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const ToggleSwitch = ({
  onChange,
  initialyEnabled,
  readOnly = false,
}: Props) => {
  const [enabled, setEnabled] = useState(initialyEnabled);
  return (
    <Switch
      disabled={readOnly}
      checked={enabled}
      onChange={(changedValue: any) => {
        setEnabled(changedValue);
        onChange(changedValue);
      }}
      className={classNames(
        enabled ? 'bg-primary' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
      )}
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
};

export default ToggleSwitch;
