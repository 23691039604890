interface IconTitleItemProps {
  icon: JSX.Element | undefined;
  title: string;
  subTitle?: string;
  subItem?: JSX.Element;
  subTitleColor?: string;
  className?: string;
}

const IconTitleItem = ({
  icon,
  title,
  subTitle,
  subItem,
  subTitleColor,
  className,
}: IconTitleItemProps) => {
  return (
    <div className={`flex flex-row items-center overflow-hidden ${className}`}>
      {icon && (
        <div className="w-11 h-11 bg-background-700 rounded mr-2 flex">
          <icon.type className="p-1.5" />
        </div>
      )}
      <div>
        <div className="font-medium">{title}</div>
        {subTitle ? (
          <div className={`text-sm ${subTitleColor}`}>
            {subTitle.toString()}
          </div>
        ) : subItem ? (
          subItem
        ) : (
          <div className="h-5"></div>
        )}
      </div>
    </div>
  );
};

export default IconTitleItem;
