import UserDetail from '../../../components/User/UserDetail/UserDetail';
import VerificationStatusCard from '../../../components/User/UserDetail/VerificationStatusCard';
import VerificationServiceCards from '../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import clientUserStore from '../../../stores/clientUser.store';
import { getUserVerificationStepStatus } from '../../../utils/User.util';
import { getVerificationStepUserServices } from '../../../helpers/VerificationService.helper';
import { verificationStepTitle } from '../../../utils/VerificationSteps.util';
import { observer } from 'mobx-react';
import UserVerificationServiceDto from '../../../interfaces/user/verification-service.dto';
import VerificationStepSettings from '../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import LegalDocumentsData from '../../../components/User/UserDetail/LegalDocuments/LegalDocumentsData';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function UserDetailLegalDocuments() {
  const stepType = VerificationStepType.LEGAL_DOCUMENTS;
  const legalDocuments = clientUserStore.user?.legalDocuments;

  let services: UserVerificationServiceDto[] = [];
  const { user } = useParams();

  useEffect(() => {
    if (user) {
    //   clientUserStore.resetUser();
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, [user]);

  if (legalDocuments) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: legalDocuments.services ? legalDocuments.services : [],
    });
  }

  const renderLegalDocumentsData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          legalDocuments && <VerificationStepSettings step={stepType} />
        }
      >
        {legalDocuments && (
          <LegalDocumentsData legalDocuments={legalDocuments} />
        )}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderLegalDocumentsData()}
        {legalDocuments &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className='mt-8'
              services={services}
              comments={legalDocuments.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailLegalDocuments);
