import { Menu, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import FlowType from '../../../enums/FlowType';
import UserFilterItem from './UserFilterItem';
import FilterType from '../../../enums/FilterType';

interface Props {
  statusOptions?: VerificationStatus[];
  flowTypeOptions?: FlowType[];
}

const UserFilter = ({ statusOptions, flowTypeOptions }: Props) => {
  if (!statusOptions && !flowTypeOptions) {
    return <></>;
  }

  return (
    <Menu as='div' className='relative flex'>
      <Menu.Button className='data-[headlessui-state=open]:text-primary'>
        <FunnelIcon className='h-6 w-6' />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 top-full origin-top-right z-10 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {statusOptions && (
            <Menu.Item>
              <UserFilterItem
                type={FilterType.STATUS}
                options={statusOptions}
              />
            </Menu.Item>
          )}
          {flowTypeOptions && (
            <Menu.Item>
              <UserFilterItem
                type={FilterType.FLOW_TYPE}
                options={flowTypeOptions}
              />
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserFilter;
