export enum VerificationServiceType {
  COMPLYADVANTAGE = "COMPLYADVANTAGE",
  REGULA = "REGULA",
  PARASHIFT = "PARASHIFT",
  CHAINALYSIS = "CHAINALYSIS",
  IP_CHECKER = "IP_CHECKER",
  NAME = "NAME",
  ADDRESS = "ADDRESS",
  COUNTRY_RESTRICTION = "COUNTRY_RESTRICTION",
  KEYWORDS = "KEYWORDS",
  ACCEPTED = 'ACCEPTED',
}
