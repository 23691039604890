import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import UserDetail from "../../../components/User/UserDetail/UserDetail";
import VerificationStatusCard from "../../../components/User/UserDetail/VerificationStatusCard";
import VerificationServiceCards from "../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards";
import VerificationStepSettings from "../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings";
import WalletData from "../../../components/User/UserDetail/VerificationSteps/Wallet/WalletData";
import { VerificationStepType } from "../../../enums/VerificationStepType";
import { getVerificationStepUserServices } from "../../../helpers/VerificationService.helper";
import UserVerificationServiceDto from "../../../interfaces/user/verification-service.dto";
import clientUserStore from "../../../stores/clientUser.store";
import { stepConfigStore } from "../../../utils/FlowConfig.util";
import { getUserVerificationStepStatus } from "../../../utils/User.util";
import { verificationStepTitle } from "../../../utils/VerificationSteps.util";

function UserDetailWallet() {
  const { user } = useParams();

  const stepType = VerificationStepType.WALLET;
  const wallet = clientUserStore.user?.wallet;

  let services: UserVerificationServiceDto[] = [];

  useEffect(() => {
    if (user) {
      // clientUserStore.resetUser();
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, []);

  if (wallet) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: wallet.services ? wallet.services : [],
    });
  }

  const renderWalletData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={wallet && <VerificationStepSettings step={stepType} />}
      >
        {wallet && <WalletData wallet={wallet} />}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderWalletData()}
        {wallet &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className="mt-8"
              services={services}
              comments={wallet.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailWallet);
