function NoAuth() {
  return (
    <div className='flex flex-1 justify-center items-center'>
      <p className='text-2xl font-bold'>
        You do not have permission for this access
      </p>
    </div>
  );
}

export default NoAuth;
