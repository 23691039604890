import UserDetail from "../../../components/User/UserDetail/UserDetail";
import VerificationStatusCard from "../../../components/User/UserDetail/VerificationStatusCard";
import PersonalInfoUserData from "../../../components/User/UserDetail/VerificationSteps/PersonalInfo/PersonalInfoData";
import VerificationServiceCards from "../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards";
import { VerificationStepType } from "../../../enums/VerificationStepType";
import clientUserStore from "../../../stores/clientUser.store";
import { getUserVerificationStepStatus } from "../../../utils/User.util";
import { getVerificationStepUserServices } from "../../../helpers/VerificationService.helper";
import { verificationStepTitle } from "../../../utils/VerificationSteps.util";
import { observer } from "mobx-react";
import UserVerificationServiceDto from "../../../interfaces/user/verification-service.dto";
import VerificationStepSettings from "../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings";
import { stepConfigStore } from "../../../utils/FlowConfig.util";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

function UserDetailPersonalInfo() {
  const stepType = VerificationStepType.PERSONAL_INFO;
  const personalInfo = clientUserStore.user?.personalInfo;

  let services: UserVerificationServiceDto[] = [];
  
  const { user } = useParams();
  useEffect(() => {
    if (user) {
      // clientUserStore.resetUser();
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, []);

  if (personalInfo) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: personalInfo.services ? personalInfo.services : [],
    });
  }

  const renderPersonalInfoData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          personalInfo && <VerificationStepSettings step={stepType} />
        }
      >
        {personalInfo && <PersonalInfoUserData personalInfo={personalInfo} />}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderPersonalInfoData()}
        {personalInfo &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className="mt-8"
              services={services}
              comments={personalInfo.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailPersonalInfo);
