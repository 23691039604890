import FlowType from '../enums/FlowType';
import { VerificationStatus } from '../enums/VerificationStatus';
import { VerificationStepType } from '../enums/VerificationStepType';
import { UserDto } from '../interfaces/User.dto';
import { stepConfigStore } from './FlowConfig.util';
import { verificationStatusTextColor } from './VerificationStatus.util';
import { verificationStepEntityName } from './VerificationSteps.util';

const getUserFullName = (user?: UserDto): string => {
  if (user) {
    if (
      (user.flowType === FlowType.KYC ||
        user.flowType === FlowType.KYB_DIRECTOR ||
        user.flowType === FlowType.KYB_USER) &&
      user.personalInfo
    ) {
      const firstName = user.personalInfo.firstName;
      const middleName = user.personalInfo.middleName;
      const lastName = user.personalInfo.lastName;
      if (firstName && middleName && lastName) {
        return `${firstName} ${middleName} ${lastName}`;
      } else if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      } else if (firstName) {
        return firstName;
      } else if (lastName) {
        return lastName;
      }
    } else if (
      (user.flowType === FlowType.KYB ||
        user.flowType === FlowType.KYB_COMPANY) &&
      user.companyInfo
    ) {
      if (user.companyInfo.companyName) {
        return user.companyInfo.companyName;
      }
    }
  }

  return '';
};

const getUserInitials = (user?: UserDto): string | null => {
  if (user) {
    if (
      (user.flowType === FlowType.KYC ||
        user.flowType === FlowType.KYB_DIRECTOR) &&
      user.personalInfo
    ) {
      const first = user.personalInfo?.firstName?.substring(0, 1);
      const last = user.personalInfo?.lastName?.substring(0, 1);

      if (first && last) {
        return first.toUpperCase() + last.toUpperCase();
      } else if (first) {
        return first.toUpperCase();
      } else if (last) {
        return last.toUpperCase();
      }
    }

    if (user.flowType === FlowType.KYB && user.companyInfo) {
      const name = user.companyInfo?.companyName?.substring(0, 1);

      if (name) {
        return name.toUpperCase();
      }
    }
  }

  return null;
};

const isWarningUser = (user: UserDto) => {
  const activeSteps = stepConfigStore(
    user.flowType
  ).getActiveVerificationSteps();
  if (activeSteps.length > 0) {
    for (const activeStep of activeSteps) {
      const status = getUserVerificationStepStatus({
        user,
        step: activeStep,
      });

      if (status === VerificationStatus.WARNING) {
        return true;
      }
    }
  }

  return false;
};

const isToApproveUser = (user: UserDto) => {
  const activeSteps = stepConfigStore(
    user.flowType
  ).getActiveVerificationSteps();
  if (activeSteps.length > 0) {
    let toApprove = true;
    for (const activeStep of activeSteps) {
      const status = getUserVerificationStepStatus({
        user,
        step: activeStep,
      });

      if (status === VerificationStatus.REJECTED) {
        return true;
      }

      if (status !== VerificationStatus.COMPLETED) {
        toApprove = false;
      }
    }

    return toApprove;
  }

  return false;
};

const getApproveRecommendation = (user: UserDto): JSX.Element => {
  let approve = true;
  let reject = false;
  stepConfigStore(user.flowType)
    .getActiveVerificationSteps()
    .forEach((step) => {
      const status = getUserVerificationStepStatus({ step, user });
      if (status !== VerificationStatus.COMPLETED) {
        approve = false;
      }

      if (status === VerificationStatus.REJECTED) {
        reject = true;
      }
    });

  if (approve) {
    return (
      <span
        className={verificationStatusTextColor(VerificationStatus.COMPLETED)}
      >
        Approve
      </span>
    );
  } else if (reject) {
    return (
      <span
        className={verificationStatusTextColor(VerificationStatus.REJECTED)}
      >
        Reject
      </span>
    );
  }

  return <></>;
};

const getUserVerificationStepStatus = (args: {
  user: UserDto;
  step: VerificationStepType;
}): VerificationStatus => {
  if (args.user) {
    const step = (args.user as any)[verificationStepEntityName(args.step)];

    if (step?.status) {
      return step.status;
    }
  }

  return VerificationStatus.NOT_STARTED;
};

export {
  getApproveRecommendation,
  isToApproveUser,
  isWarningUser,
  getUserVerificationStepStatus,
  getUserFullName,
  getUserInitials,
};
