import React, { ReactElement } from 'react';

interface Props {
  placeHolder?: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const TextSearchInput: React.FC<Props> = ({
  placeHolder = 'Search ...',
  className = '',
  onChange,
}: Props): ReactElement => {
  return (
    <div className={`${className} relative `}>
      <input
        placeholder={placeHolder}
        autoComplete='off'
        autoFocus
        onChange={onChange}
        className={`border rounded-lg border-gray-300 focus:border-primary outline-0 h-10 px-2.5 w-full`}
      />
    </div>
  );
};

export default TextSearchInput;
