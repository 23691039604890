import {
  CheckCircleIcon,
  ClockIcon,
  HomeIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid';
import React, { ReactElement } from 'react';
import { NodeType } from '../../../../../enums/NodeType';
import { VerificationStatus } from '../../../../../enums/VerificationStatus';
import { Node as NodeDto } from '../../../../../interfaces/Organigram.dto';
import { getUserFullName } from '../../../../../utils/User.util';
import {
  verificationStatusText,
  verificationStatusTextColor,
} from '../../../../../utils/VerificationStatus.util';
import StatusIcon from '../../../UserStatus/StatusIcon';

interface Props {
  node: NodeDto;
  onClick?: (node: NodeDto) => void;
  hideStatus?: boolean;
}

const Node = ({ node, onClick, hideStatus = false }: Props): ReactElement => {
  const renderStatus = (): ReactElement => {
    const status = node.user?.status
      ? node.user.status
      : VerificationStatus.WARNING;

    return (
      <div className='flex justify-center items-center'>
        <StatusIcon
          status={status}
          className={`w-3 h-3 mr-1 ${verificationStatusTextColor(status)}`}
        />
        <div className={`text-xs ${verificationStatusTextColor(status)}`}>
          {verificationStatusText(status)}
        </div>
      </div>
    );
  };

  const canOpen = onClick && node.user?.id ? true : false;

  return (
    <div
      className={`flex w-min m-auto justify-center ${
        canOpen ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        if (canOpen) {
          onClick!(node);
        }
      }}
    >
      <div className='rounded-md p-2 pl-3 pr-3 flex flex-col justify-center items-center border'>
        <div className='bg-gray-300 p-1 rounded-md'>
          {node.type === NodeType.LEGAL_PERSON && (
            <HomeIcon className='w-8 h-8 text-gray-600' />
          )}
          {node.type === NodeType.NATURAL_PERSON && (
            <UserCircleIcon className='w-8 h-8 text-gray-600' />
          )}
        </div>
        <div className='font-bold mt-1 text-xs '>
          {getUserFullName(node.user)}
        </div>
        {!hideStatus && renderStatus()}
      </div>
    </div>
  );
};

export default Node;
