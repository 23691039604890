import { WalletIcon } from '@heroicons/react/24/outline';
import { parse as parseflatted } from 'flatted';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';

interface Props {
  data: any;
}

const ChainalysisRequest = ({ data }: Props) => {
  const dataObj = parseflatted(data);
  if (dataObj.address) {
    return (
      <IconTitleItem
        className='mt-2'
        icon={<WalletIcon />}
        title='Wallet Address'
        subTitle={dataObj.address}
      />
    );
  }

  return <FlattedText flattedText={data} />;
};

export default ChainalysisRequest;
