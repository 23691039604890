import React from 'react';
import { LegalDocumentsUserDto } from '../../../../interfaces/user/verification-steps/legal-documents.dto';
import FilePreview from '../../../filepreview/FilePreview';

interface Props {
  legalDocuments: LegalDocumentsUserDto;
}

const LegalDocumentsData = ({ legalDocuments }: Props) => {
  return (
    <>
      {legalDocuments.certificateOfIncorporation && (
        <div className='mt-5'>
          <div className='font-medium mb-2'>Certificate Of Incorporation</div>
          <FilePreview file={legalDocuments.certificateOfIncorporation} />
        </div>
      )}
      {legalDocuments.registerOfDirectors && (
        <div className='mt-5'>
          <div className='font-medium mb-2'>Register of Directors</div>
          <FilePreview file={legalDocuments.registerOfDirectors} />
        </div>
      )}
      {legalDocuments.registerOfMembers && (
        <div className='mt-5'>
          <div className='font-medium mb-2'>Register of Members</div>
          <FilePreview file={legalDocuments.registerOfMembers} />
        </div>
      )}
    </>
  );
};

export default LegalDocumentsData;
