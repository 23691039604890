import { BellAlertIcon } from "@heroicons/react/24/solid";
import { parse as parseflatted } from "flatted";
import { getCountryIcon, getCountryLabel } from "../../../utils/Address.util";
import FlattedText from "../../flatted-text/FlattedText";
import IconTitleItem from "../../IconTitleItem/IconTitleItem";

interface Props {
  data: any;
}

const CountryRestrictionResponse = ({ data }: Props) => {
  const dataObj = parseflatted(data);
  if (dataObj.reject?.nationality || dataObj.reject?.residency) {
    return (
      <div className="grid gap-3 grid-cols-3">
        {dataObj.reject?.nationality && (
          <IconTitleItem
            className="mt-2"
            icon={getCountryIcon(dataObj.reject?.nationality)}
            title="Rejected Nationality"
            subTitle={getCountryLabel(dataObj.reject?.nationality)}
          />
        )}
        {dataObj.reject?.residency && (
          <IconTitleItem
            className="mt-2"
            icon={getCountryIcon(dataObj.reject?.residency)}
            title="Rejected Residency"
            subTitle={getCountryLabel(dataObj.reject?.residency)}
          />
        )}
      </div>
    );
  } else if (dataObj.status && dataObj.error) {
    return (
      <div>
        {dataObj.status} - {dataObj.error}
      </div>
    );
  }

  const result = JSON.parse(data);

  if (Array.isArray(result)) {
    return (
      <>
        {
          <IconTitleItem
            className="mt-2"
            icon={<BellAlertIcon />}
            title="Warning"
            subTitle={result[1]}
          />
        }
      </>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default CountryRestrictionResponse;
