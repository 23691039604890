import dayjs from "dayjs";
import React from "react";
import Card from "../../../components/card/Card";
import clientUserStore from "../../../stores/clientUser.store";

const Sessions = () => {
  const translateAction = (action: string) => {
    switch (action) {
      case "PUT":
        return "Update";
      case "CREATE_LOGIN":
        return "Login";
      case "ACCESS_TOKEN_CREATED":
        return "Generate access token";
      case "DELETE":
        return "Delete";
      case "REDO":
        return "Redo";
      case "UPDATE":
        return "Update";
      default:
        return action;
    }
  };

  const translateService = (service: string) => {
    return service.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  if (
    !clientUserStore.user ||
    !clientUserStore.user.sessions ||
    clientUserStore.user.sessions.length === 0
  ) {
    return <></>;
  }
  return (
    <Card className="mt-8">
      <div className="p-8">
        <div className="font-bold">Sessions</div>
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                What
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
              >
                Time
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
              >
                Service
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
              >
                IP
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {clientUserStore.user.sessions
              .slice()
              .sort((a: any, b: any) =>
                dayjs(b.createdAt).diff(dayjs(a.createdAt))
              )
              .map((session: any, key: number) => (
                <tr key={key}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {translateAction(session.action)}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                    {dayjs(session.createdAt).format("HH:mm DD.MM.YYYY")}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                    {translateService(session.controller)}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                    {session.ip}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Sessions;
