import { Role } from '../enums/Role';
import { KeycloakUserDto } from '../interfaces/KeycloakUser.dto';


const getKeycloakUserInitials = (user: KeycloakUserDto): string => {
  let initials = '';

  if (user?.given_name) {
    initials = user.given_name.substring(0, 1);
  }

  if (user?.family_name) {
    initials += user.family_name.substring(0, 1);
  }

  return initials;
};

const getKeycloakName = (user: KeycloakUserDto): string => {
  const first = user ? user.given_name : null;
  const last = user ? user.family_name : null;

  if (first && last) {
    return first + ' ' + last;
  }

  return '-';
};

const isUserReadyonly = (keycloak: any): boolean => {
  return getKeycloakRoles(keycloak).some((r) => r === Role.READONLY);
};

const getKeycloakRoles = (keycloak: any): Role[] => {
  if (keycloak.tokenParsed?.realm_access?.roles) {
    return keycloak.tokenParsed.realm_access.roles;
  }

  return [];
};

export {
  getKeycloakUserInitials,
  getKeycloakName,
  getKeycloakRoles,
  isUserReadyonly,
};
