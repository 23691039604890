import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserDetail from '../../../components/User/UserDetail/UserDetail';
import VerificationStatusCard from '../../../components/User/UserDetail/VerificationStatusCard';
import ProofOfResidencyData from '../../../components/User/UserDetail/VerificationSteps/ProofOfResidency/ProofOfResidencyData';
import VerificationServiceCards from '../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards';
import VerificationStepSettings from '../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import { getVerificationStepUserServices } from '../../../helpers/VerificationService.helper';
import UserVerificationServiceDto from '../../../interfaces/user/verification-service.dto';
import clientUserStore from '../../../stores/clientUser.store';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import { getUserVerificationStepStatus } from '../../../utils/User.util';
import { verificationStepTitle } from '../../../utils/VerificationSteps.util';

function UserDetailProofOfResidency() {
  const stepType = VerificationStepType.PROOF_OF_RESIDENCY;
  const proofOfResidency = clientUserStore.user?.proofOfResidency;

  let services: UserVerificationServiceDto[] = [];

  const { user } = useParams();
  useEffect(() => {
    if (user) {
      // clientUserStore.resetUser();
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, []);

  if (proofOfResidency) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: proofOfResidency.services ? proofOfResidency.services : [],
    });
  }

  const renderProofOfResidencyData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          proofOfResidency && <VerificationStepSettings step={stepType} />
        }
      >
        {proofOfResidency && (
          <ProofOfResidencyData proofOfResidency={proofOfResidency} />
        )}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderProofOfResidencyData()}
        {proofOfResidency &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className='mt-8'
              services={services}
              comments={proofOfResidency.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailProofOfResidency);
