import { action, makeObservable, observable } from 'mobx';
import {
  approveBeneficialUser,
  getUser,
  rejectBeneficialUser,
} from '../api/user.api';
import FlowType from '../enums/FlowType';
import { VerificationStepType } from '../enums/VerificationStepType';
import { Node } from '../interfaces/Organigram.dto';
import { UserDto } from '../interfaces/User.dto';
import clientUserStore from './clientUser.store';

export class BeneficialOwnerStore {
  @observable user: UserDto | undefined = undefined;
  @observable node: Node | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setUser(user: UserDto) {
    this.user = user;
  }

  @action
  setNode(node: Node) {
    this.node = node;
  }

  async getUser(node: Node) {
    if (node.user) {
      this.setNode(node);

      let query = '';
      if (node.user.flowType === FlowType.KYB_USER) {
        query = `?properties=${VerificationStepType.PERSONAL_INFO},${VerificationStepType.ID_AND_LIVENESS}`;
      } else if (node.user.flowType === FlowType.KYB_COMPANY) {
        query = `?properties=${VerificationStepType.COMPANY_INFO},${VerificationStepType.LEGAL_DOCUMENTS}`;
      }

      const response = await getUser({ user: node.user.id, query });

      if (response && response.status === 200) {
        this.setUser(response.data);
      }
    }
  }

  async approveUser() {
    if (this.node && this.user && clientUserStore.user) {
      await approveBeneficialUser({
        userId: clientUserStore.user.id,
        beneficialUserId: this.user.id,
      });
    }
  }

  async rejectUser() {
    if (this.node && this.user && clientUserStore.user) {
      await rejectBeneficialUser({
        userId: clientUserStore.user.id,
        beneficialUserId: this.user.id,
      });
    }
  }

  @action
  reset() {
    this.node = undefined;
    this.user = undefined;
  }
}

const beneficialOwnerStore = new BeneficialOwnerStore();
export default beneficialOwnerStore;
