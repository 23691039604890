import { useKeycloak } from '@react-keycloak/web';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import CreateProjectCard from '../components/CreateProjectCard/CreateProjectCard';
import ProjectCard from '../components/ProjectCard/ProjectCard';
import RequireAuth from '../components/RequireAuth';
import { Role } from '../enums/Role';
import projectStore from '../stores/project.store';
import { isUserReadyonly } from '../utils/KeycloakUser.util';

function Projects() {
  const [loading, setLoading] = useState(false);
  const { keycloak } = useKeycloak();
  const readOnly = isUserReadyonly(useKeycloak().keycloak);

  useEffect(() => {
    const load = async () => {
      if (projectStore.projects.length === 0) {
        setLoading(true);
      }
      await projectStore.getProjects();
      setLoading(false);
    };

    load();
  }, []);

  const [user, setUser] = useState<any>();

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      if (userInfo) {
        setUser(userInfo);
      }
    });
  }, [keycloak]);

  const renderGhostProjects = () => {
    return [true, true, true].map((el, index) => <ProjectCard key={index} />);
  };

  const renderProjects = () => {
    if(projectStore.projects.length <= 0 && readOnly) {
      return <div>No assigned projects!</div>
    }
    return projectStore.projects.map((project, index) => (
      <ProjectCard key={index} project={project} />
    ));
  };

  return (
    <div className='w-full bg-white px-10 py-16'>
      <header>
        <div className='max-w-7xl'>
          <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
            Hi {user ? user.given_name : ''} !
          </h1>
        </div>
      </header>
      <main className='w-full flex mt-10 flex-wrap'>
        <RequireAuth roles={[Role.ADMIN]}>
          <CreateProjectCard />
        </RequireAuth>
        {loading && renderGhostProjects()}
        {!loading && renderProjects()}
      </main>
    </div>
  );
}

export default observer(Projects);
