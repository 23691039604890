import React, { useEffect, useState } from 'react';
import FlowType from '../../../../enums/FlowType';
import projectConfigStore from '../../../../stores/project-config.store';
import { stepConfigStore } from '../../../../utils/FlowConfig.util';
import Button from '../../../button/Button';
import FileUpload from '../../../file-upload/FileUpload';
import FilePreviewExtended from '../../../filepreview/FilePreviewExtended';
import Loading from '../../../loading/Loading';

interface Props {
  flowType: FlowType;
}

const DocumentUpload = ({ flowType }: Props) => {
  const [documents, setDocuments] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const uploadInfoText = 'Drag and drop your PDF, JPG images here.';
  const configStore = stepConfigStore(flowType);

  useEffect(() => {
    if (configStore.config) {
      setDocuments(configStore.config.finish?.documents);
    }
  }, [configStore.config]);

  const onSubmit = async () => {
    //TODO: Update
    const documentUrls = [];
    setLoading(true);
    for (let document of documents) {
      if (typeof document === 'string') {
        documentUrls.push(document);
        continue;
      }
      const url = await projectConfigStore.uploadFile(document);
      documentUrls.push(url);
    }

    const finish = configStore.config?.finish;
    if (!finish) return;
    if (!finish.documents) finish.documents = [];

    finish.documents = documentUrls;

    await configStore.updateConfig({ finish });
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className='w-fit'>
      {documents.map((item: any, index: number) => {
        return (
          <div key={index} className='mb-5'>
            <FilePreviewExtended
              file={item}
              deleteFile={() => {
                documents.splice(index, 1);
                setDocuments([...documents]);
              }}
            />
          </div>
        );
      })}
      <FileUpload
        onChange={(file) => {
          documents.push(file);

          setDocuments([...documents]);
        }}
        infoText={uploadInfoText}
      />
      <div>
        <Button
          className='mt-4 w-64'
          onClick={onSubmit}
          title='Save'
          style='primary'
        />
      </div>
      <div className='h-8'></div>
    </div>
  );
};

export default DocumentUpload;
