import { PlusIcon } from '@heroicons/react/24/outline';
import { useKeycloak } from '@react-keycloak/web';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../../components/button/Button';
import Information from '../../../components/information/Information';
import DropdownWithSelect from '../../../components/inputs/Dropdowns/DropdownWithSelect';
import ReadonlyInput from '../../../components/inputs/ReadOnlyInput';
import TextInput from '../../../components/inputs/TextInput';
import LoadingAnimation from '../../../components/LoadingAnimation/LoadingAnimation';
import RequireAuth from '../../../components/RequireAuth';
import TestModeModal from '../../../components/TestModeModal/TestModeModal';
import { countryDropDownOptions } from '../../../data/countries';
import { Role } from '../../../enums/Role';
import ProjectConfigDto from '../../../interfaces/ProjectConfig.dto';
import projectConfigStore from '../../../stores/project-config.store';
import projectStore from '../../../stores/project.store';
import { onPromise } from '../../../utils/formHelper';
import { isUserReadyonly } from '../../../utils/KeycloakUser.util';
import { generateProjectNameShort } from '../../../utils/ProjectNameShort';
import Card from '../../../components/card/Card';

const ConfigurationGeneral = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const readOnly = isUserReadyonly(useKeycloak().keycloak);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDrop = useCallback((acceptedFiles: any) => {
    const uploadAndUpdate = async () => {
      setIsImageLoading(true);
      await projectConfigStore.uploadImage(acceptedFiles[0]);
      setIsImageLoading(false);
    };
    uploadAndUpdate();
  }, []);

  let { getRootProps, getInputProps } = useDropzone({ onDrop });

  const methods = useForm<any>({
    mode: 'onSubmit',
    defaultValues: useMemo(
      () => projectConfigStore.config,
      [projectConfigStore.config]
    ),
  });

  useEffect(() => {
    methods.reset(projectConfigStore.config);
  }, [projectConfigStore.config]);

  const onSubmit: SubmitHandler<ProjectConfigDto> = async (data) => {
    if (data.backendUrl && data.backendUrl.slice(-1) === '/') {
      data.backendUrl = data.backendUrl.slice(0, -1);
    }

    try {
      setIsLoading(true);
      await projectConfigStore.updateConfig(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const logo = methods.watch('logo');

  const renderLogo = () => {
    if (isImageLoading) {
      return <LoadingAnimation />;
    }
    if (logo) {
      return (
        <img src={logo} alt='Logo' className='object-cover h-full w-full' />
      );
    }
    return generateProjectNameShort(projectStore.project?.name ?? '');
  };

  if (isLoading) {
    return (
      <div className='h-full w-full p-4 flex justify-center items-center'>
        <LoadingAnimation />
      </div>
    );
  }

  const renderRootProps = () => {
    if (readOnly) {
      return [];
    } else {
      return getRootProps();
    }
  };

  console.log(projectConfigStore.config);

  return (
    <div className='h-full w-full p-6'>
      {projectConfigStore.config?.testMode && (
        <Information
          title='Test Mode'
          text='This project is currently in test mode. It may contain bugs or unexpected behavior, and its functionality may be incomplete or subject to change without notice. Please use this project with caution and do not rely on it for critical or production-level operations. We recommend testing in a controlled environment before deploying in a live setting. By proceeding with this project, you acknowledge that you understand and accept the risks involved in using software that is still in the testing phase.'
          onChange={() => {
            setModalOpen(true);
          }}
          enabled={projectConfigStore.config?.testMode ?? false}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={onPromise(methods.handleSubmit(onSubmit))}>
          <div className='max-w-2xl w-full '>
            <div className='text-xl border p-4 rounded-md'>
              <div className='text-xl font-bold pb-4'>General</div>
              <div className='relative w-32 h-32'>
                <div
                  {...renderRootProps()}
                  className={`${
                    readOnly ? '' : 'cursor-pointer'
                  } rounded-full shadow-md overflow-hidden w-32 h-32`}
                >
                  <div className='w-full h-full font-bold text-xl '>
                    <RequireAuth roles={[Role.ADMIN]}>
                      <input {...getInputProps()} className='w-full h-full' />
                    </RequireAuth>

                    <div className='flex justify-center items-center w-full h-full'>
                      {renderLogo()}
                    </div>
                  </div>
                  <RequireAuth roles={[Role.ADMIN]}>
                    <div className='absolute bottom-0 right-0'>
                      <p className='m-1 w-8 h-8 bg-primary rounded-full flex justify-center items-center'>
                        <PlusIcon className='w-6 h-6' color='white' />
                      </p>
                    </div>
                  </RequireAuth>
                </div>
              </div>
              <ReadonlyInput
                className='mt-4 w-full'
                value={projectStore.project?.name}
              />
              <TextInput
                className='w-full'
                variable='email'
                placeHolder='Email'
                regExp={/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}
                readOnly={readOnly}
              />
            </div>
            <div className='text-xl mt-6 border p-4 rounded-md'>
              <div className='text-xl font-semibold'>Address</div>
              <div className='w-full mt-4'>
                <TextInput
                  className='w-full'
                  variable='address.street'
                  placeHolder='Street'
                  regExp={/[a-zA-Z0-9]/}
                  readOnly={readOnly}
                />
                <div className='flex w-full'>
                  <TextInput
                    className='w-1/4 mr-2'
                    variable='address.zip'
                    placeHolder='Zip'
                    regExp={/[a-zA-Z0-9]/}
                    readOnly={readOnly}
                  />
                  <TextInput
                    className='w-full'
                    variable='address.city'
                    placeHolder='City'
                    regExp={/[a-zA-Z0-9]/}
                    readOnly={readOnly}
                  />
                </div>
                <DropdownWithSelect
                  control={methods.control}
                  name='address.country'
                  options={countryDropDownOptions}
                  placeholder='Select a country'
                  readOnly={readOnly}
                />
              </div>
            </div>
            <RequireAuth roles={[Role.ADMIN]}>
              <div className='flex justify-end'>
                <Button
                  className='mt-4 w-64 text-sm'
                  type='submit'
                  title='Save'
                  style='primary'
                />
              </div>
            </RequireAuth>
          </div>
        </form>
      </FormProvider>
      <TestModeModal
        open={modalOpen}
        setOpen={(open) => {
          console.log(open);
          setModalOpen(open);
        }}
      />
      <div className='h-16'></div>
    </div>
  );
};

export default observer(ConfigurationGeneral);
