import { parse as parseflatted } from 'flatted';

interface Props {
  flattedText: string;
}

const FlattedText = ({ flattedText }: Props) => {
  return (
    <div className='text-xs'>
      {JSON.stringify(parseflatted(flattedText), null, 1)}
    </div>
  );
};

export default FlattedText;
