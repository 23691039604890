const getSortedDocuments = (documents: any[]): any[] => {
  return documents
    .filter((item: any) => item.fieldName.includes('Document'))
    .sort(
      (a: any, b: any) =>
        a.pageIndex - b.pageIndex || a.containerType - b.containerType
    )
    .filter((item: any, index: number, self: any) => {
      return (
        index === self.findIndex((i: any) => i.pageIndex === item.pageIndex)
      );
    });
};

export { getSortedDocuments };
