import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import UserDetail from "../../../components/User/UserDetail/UserDetail";
import VerificationStatusCard from "../../../components/User/UserDetail/VerificationStatusCard";
import IdAndLivenessData from "../../../components/User/UserDetail/VerificationSteps/IdAndLiveness/IdAndLivenessData";
import VerificationServiceCards from "../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards";
import VerificationStepSettings from "../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings";
import { VerificationStepType } from "../../../enums/VerificationStepType";
import { getVerificationStepUserServices } from "../../../helpers/VerificationService.helper";
import UserVerificationServiceDto from "../../../interfaces/user/verification-service.dto";
import clientUserStore from "../../../stores/clientUser.store";
import { stepConfigStore } from "../../../utils/FlowConfig.util";
import { getUserVerificationStepStatus } from "../../../utils/User.util";
import { verificationStepTitle } from "../../../utils/VerificationSteps.util";

function UserDetailIdAndLiveness() {
  const stepType = VerificationStepType.ID_AND_LIVENESS;
  const idAndLiveness = clientUserStore.user?.idAndLiveness;
  let services: UserVerificationServiceDto[] = [];

  const { user } = useParams();
  useEffect(() => {
    if (user) {
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, []);

  if (idAndLiveness) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: idAndLiveness.services ? idAndLiveness.services : [],
    });
  }

  const renderIdAndLivenessData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          idAndLiveness && <VerificationStepSettings step={stepType} />
        }
      >
        {idAndLiveness && <IdAndLivenessData idAndLiveness={idAndLiveness} />}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderIdAndLivenessData()}
        {idAndLiveness &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className="mt-8"
              services={services}
              comments={idAndLiveness.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailIdAndLiveness);
