import { WalletIcon } from '@heroicons/react/24/outline';
import { parse as parseflatted } from 'flatted';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';

interface Props {
  data: any;
}

const ChainalysisResponse = ({ data }: Props) => {
  const dataObj = parseflatted(data);
  return <FlattedText flattedText={data}></FlattedText>;

  // Not needed for now
  const renderContent = (key: string, value: any) => {
    if (key === 'address') {
      return <></>;
    }

    if (key === 'triggers' && false) {
      return (
        <div>
          <h3 className='mb-3 mt-5 font-bold'>Triggers</h3>
          <div className=''>
            {value.map((item: any) => {
              return (
                <div className='border-b grid gap-5 grid-cols-2 pb-3 pt-3'>
                  {Object.keys(item).map((key) => {
                    if (key === 'ruleTriggered') {
                      return <></>;
                    }
                    return (
                      <IconTitleItem
                        icon={<WalletIcon />}
                        title={key}
                        subTitle={
                          typeof item[key] === 'number'
                            ? item[key] + ' %'
                            : item[key]
                        }
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (key === 'exposures' && false) {
      return (
        <div>
          <h3 className='mb-3 mt-5 font-bold'>Exposures</h3>
          <div className='border-b grid gap-5 grid-cols-2 pb-3 pt-3'>
            {value.map((item: any) => {
              return (
                <div>
                  <IconTitleItem
                    icon={<WalletIcon />}
                    title={item['category']}
                    subTitle={item['value']}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (key === 'addressIdentifications' && false) {
      return (
        <div>
          <h3 className='mb-3 mt-5 font-bold'>Address Identifications</h3>
          <div className='border-b grid gap-5 grid-cols-2 pb-3 pt-3'>
            {value.map((item: any) => {
              return (
                <div>
                  <IconTitleItem
                    icon={<WalletIcon />}
                    title={item['category']}
                    subTitle={item['description']}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return <></>;
  };

  if (dataObj.risk && dataObj.riskReason) {
    return (
      <div className=''>
        {false &&
          Object.keys(dataObj).map((itemKey) => {
            return renderContent(itemKey, dataObj[itemKey]);
          })}
      </div>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default ChainalysisResponse;
