import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { QuestionnaireUserDto } from "../../../../../interfaces/user/verification-steps/questionnaire-user.dto";
import FilePreview from "../../../../filepreview/FilePreview";
import IconTitleItem from "../../../../IconTitleItem/IconTitleItem";

interface QuestionnaireDataProps {
  questionnaire: QuestionnaireUserDto;
}

const QuestionnaireData = ({ questionnaire }: QuestionnaireDataProps) => {
  return (
    <div>
      <div className="grid gap-5 grid-cols-2">
        {Object.keys(questionnaire).map((key) => {
          if (
            key === "sourceOfFundsEvidence" ||
            key === "id" ||
            key === "services" ||
            key === "comments" ||
            key === "isEnabled" ||
            key === "status" ||
            key === 'isDeleted' ||
            (questionnaire as any)[key] === ""
          ) {
            return <></>;
          }

          return (
            <IconTitleItem
              icon={<QuestionMarkCircleIcon />}
              title={key}
              subTitle={(questionnaire as any)[key]}
            />
          );
        })}
      </div>
      <div className="mt-5">
        {questionnaire.sourceOfFundsEvidence && (
          <FilePreview file={questionnaire.sourceOfFundsEvidence} />
        )}
      </div>
    </div>
  );
};

export default QuestionnaireData;
