import axios from "../utils/Axios";
import { action, makeObservable, observable } from "mobx";
import { toast } from "react-toastify";
import VerificationStepsConfigDto from "../interfaces/VerificationStepsConfig.dto";
import { VerificationStepType } from "../enums/VerificationStepType";
import VerificationServiceDto from "../interfaces/config/verification-steps/verification-service.dto";
import { verificationStepEntityName } from "../utils/VerificationSteps.util";

export class VerificationStepsConfigStore {
  @observable config: VerificationStepsConfigDto | undefined;
  @observable allowedStepTypes: VerificationStepType[] = [];

  constructor(args?: { allowedStepTypes?: VerificationStepType[] }) {
    this.allowedStepTypes =
      args?.allowedStepTypes || Object.values(VerificationStepType);
    makeObservable(this);
  }

  @action
  setConfig(config: any) {
    this.config = config;
  }

  @action
  async updateConfig(
    updateConfig: Partial<VerificationStepsConfigDto>
  ): Promise<void> {
    try {
      if (!this.config) return;
      const response = await axios.put(
        "/verification-steps-config/" + this.config.id,
        updateConfig
      );
      if (response.status === 200) {
        this.setConfig(updateConfig);
      }
    } catch (e) {
      toast.error("Error updating project");
      console.log(e);
    }
  }

  @action
  setVerificationStepEnabled(args: {
    verificationStepType: VerificationStepType;
    isEnabled: boolean;
  }) {
    const config: any = { ...this.config };

    if (config) {
      config[verificationStepEntityName(args.verificationStepType)].isEnabled =
        args.isEnabled;

      this.updateConfig(config);
    }
  }

  getVerificationServices(
    verificationStepType: VerificationStepType
  ): VerificationServiceDto[] {
    let serviceConfigs: VerificationServiceDto[] = [];

    if (this.config) {
      serviceConfigs =
        (this.config as any)[verificationStepEntityName(verificationStepType)]
          ?.services ?? [];
    }

    return serviceConfigs
      .map((serviceConfig: any) => {
        return serviceConfig;
      })
      .sort((a, b) => (a.prio < b.prio ? -1 : 1));
  }

  getAllServices(): VerificationServiceDto[] {
    let services: VerificationServiceDto[] = [];

    this.getActiveVerificationSteps().forEach((stepType) => {
      services = services.concat(this.getVerificationServices(stepType));
    });

    return services;
  }

  getVerificationStepEnabled(
    verificationStepType: VerificationStepType
  ): boolean {
    if (this.config) {
      if (
        !(this.config as any)[verificationStepEntityName(verificationStepType)]
      ) {
        return false;
      }
      return (this.config as any)[
        verificationStepEntityName(verificationStepType)
      ].isEnabled;
    }

    return false;
  }

  getActiveVerificationSteps(): VerificationStepType[] {
    let activeSteps: VerificationStepType[] = [];

    if (this.config) {
      this.allowedStepTypes.forEach((stepType) => {
        if (
          (this.config as any)[verificationStepEntityName(stepType)].isEnabled
        ) {
          activeSteps.push(stepType);
        }
      });
    }

    return activeSteps;
  }

  @action
  resetConfig() {
    this.config = undefined;
  }
}
