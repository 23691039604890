import axios from "axios";
import { getIdByUrl } from "./UrlParser";

const baseUrl = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000';

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
});

// Add a request interceptor
instance.interceptors.request.use(function (config: any) {
  const token = localStorage.getItem(':crypto_token')
  // Do something before request is sent

  const id = getIdByUrl('projects')
  if(id) {
    config.headers.project = id;
  }

  if (token && config.headers) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
},
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    return refreshToken(error);
  }
);

const refreshToken = async (error: any) => {
  const originalRequest = error.config;

  if (
    error.response.status === 401 &&
    originalRequest.url === "/auth/refresh"
  ) {
    return Promise.reject(error);
  }

  error.response.status = 401;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    // KeycloakService.updateToken(async () => {
    //     const token = KeycloakService.getToken();
    //     if (token && instance.defaults && instance.defaults.headers) {
    //         //@ts-ignore
    //         instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    //     }
    //     return instance(originalRequest);
    // })
  }
  return Promise.reject(error);
}


export default instance;
