import React, { useEffect, useRef, useState } from 'react';
import useAutosizeTextArea from './useAutosizeTextArea';

interface Props {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  name?: string;
  required?: boolean;
  defaultValue?: string;
}

const AutosizeTextarea = ({
  onChange,
  placeholder = '',
  name = '',
  defaultValue = '',
  required = false,
}: Props) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
    onChange(evt);
  };

  return (
    <div className='bg-white p-2 max-h-[80px] overflow-auto rounded-lg rounded-t-lg border border-gray-200'>
      <textarea
        id={name}
        name={name}
        className='resize-none flex w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none'
        placeholder={placeholder}
        required={required}
        onChange={handleChange}
        value={value}
        ref={textAreaRef}
        rows={1}
      ></textarea>
    </div>
  );
};

export default AutosizeTextarea;
