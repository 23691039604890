import { UserIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react';
import clientUserStore from '../../../stores/clientUser.store';
import filterStore from '../../../stores/filter.store';

function UserDetailLanding() {
  let label = 'No users in this project';

  if (clientUserStore.isWarning) {
    if (filterStore.hasActiveFilters()) {
      label = 'No warnings for these filters';
    } else {
      label = 'No warnings in this project';
    }
  } else if (clientUserStore.isToApprove) {
    if (filterStore.hasActiveFilters()) {
      label = 'No users to approve/reject for these filters';
    } else {
      label = 'No users to approve/reject in this project';
    }
  } else {
    if (filterStore.hasActiveFilters()) {
      label = 'No users for these filters';
    } else {
      label = 'No users in this project';
    }
  }

  return (
    <div className='h-full flex-1 flex flex-col justify-center items-center'>
      <UserIcon className='h-12 w-12 mb-2' />
      <div className='text-xl	font-bold w-50 text-center'>{label}</div>
    </div>
  );
}

export default observer(UserDetailLanding);
