import { ProjectStore } from './project.store';
import { ProjectUserStore } from './projectTeam.store';
import { ClientUserStore } from './clientUser.store';
import { ProjectConfigStore } from './project-config.store';
import { VerificationStepsConfigStore } from './verification-steps-config.store';

export const stores = {
  projectStore: ProjectStore,
  projectConfigStore: ProjectConfigStore,
  verificationStepsConfigStore: VerificationStepsConfigStore,
  projectUserStore: ProjectUserStore,
  clientUserStore: ClientUserStore
};
