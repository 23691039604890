import { WalletIcon } from "@heroicons/react/24/outline";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import React from "react";
import { WalletUserDto } from "../../../../../interfaces/user/verification-steps/wallet-user.dto";
import IconTitleItem from "../../../../IconTitleItem/IconTitleItem";

interface WalletDataProps {
  wallet: WalletUserDto;
}

const WalletData = ({ wallet }: WalletDataProps) => {
  return (
    <div className="grid gap-5 grid-cols-2">
      <IconTitleItem
        icon={<WalletIcon />}
        title="Wallet Address"
        subTitle={wallet.walletAddress}
      />
      {wallet.risk && (
        <IconTitleItem
          icon={<BellAlertIcon />}
          title="Risk"
          subTitle={wallet.risk}
        />
      )}
      {wallet.riskReason && (
        <IconTitleItem
          icon={<BellAlertIcon />}
          title="Risk Reason"
          subTitle={wallet.riskReason}
        />
      )}

      {/* {wallet.walletAddress && (
        <IconTitleItem
          icon={<BellAlertIcon />}
          title="Link to details"
          subItem={
            <a
              target="_blank"
              className="text-sm underline text-primary"
              href={`https://kyt.chainalysis.com/entities/${wallet.walletAddress}`}
            >
              Open
            </a>
          }
        />
      )} */}
    </div>
  );
};

export default WalletData;
