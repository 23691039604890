import {
  DocumentIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { ProofOfResidencyUserDto } from '../../../../../interfaces/user/verification-steps/proof-of-residency-user.dto';
import { getDocumentTypeLabel } from '../../../../../utils/Document.util';
import FilePreview from '../../../../filepreview/FilePreview';
import IconTitleItem from '../../../../IconTitleItem/IconTitleItem';

interface ProofOfResidenceDataProps {
  proofOfResidency: ProofOfResidencyUserDto;
}

const ProofOfResidencyData = ({
  proofOfResidency,
}: ProofOfResidenceDataProps) => {
  return (
    <>
      <div className='grid gap-5 grid-cols-2 w-2/3'>
        <IconTitleItem
          icon={<DocumentIcon />}
          title='Type'
          subTitle={
            proofOfResidency &&
            getDocumentTypeLabel(proofOfResidency.documentType!)
          }
        />

        {proofOfResidency.name && (
          <IconTitleItem
            icon={<UserIcon />}
            title='Name'
            subTitle={proofOfResidency.name}
          />
        )}

        {proofOfResidency.address && (
          <>
            <IconTitleItem
              icon={<MapPinIcon />}
              title='Street'
              subTitle={proofOfResidency.address.street}
            />

            <IconTitleItem
              icon={<MapPinIcon />}
              title='City'
              subTitle={proofOfResidency.address.city}
            />

            <IconTitleItem
              icon={<MapPinIcon />}
              title='Postal Code'
              subTitle={proofOfResidency.address.zip}
            />

            <IconTitleItem
              icon={<MapPinIcon />}
              title='Country'
              subTitle={proofOfResidency.address.country}
            />
          </>
        )}
      </div>
      {proofOfResidency.documentURL && (
        <div className='mt-5'>
          <div className='font-medium mb-2'>Uploaded File</div>
          <FilePreview file={proofOfResidency.documentURL} />
        </div>
      )}
    </>
  );
};

export default ProofOfResidencyData;
