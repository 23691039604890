import { action, makeObservable, observable } from 'mobx';
import axios from '../utils/Axios';
import ProjectDto from '../interfaces/Project.dto';
import { ProjectUserDto } from '../interfaces/ProjectUserDto.dto';

export class ProjectUserStore {
  @observable users: ProjectUserDto[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async getInvitedUsers(args: { project: ProjectDto; }) {
    const response = await axios.get(
      `/users`
    );
    if (response.status === 200) {
      this.users = response.data;
    }
  }
}

const projectTeamStore = new ProjectUserStore();
export default projectTeamStore;
