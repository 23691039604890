/* eslint-disable jsx-a11y/anchor-has-content */
import { useState, useRef } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import { generateCsvDataBlob } from '../../../api/user.api';
import { toast } from 'react-toastify';

interface Props {
  className?: string;
  size?: number;
}

const ExportCsvButton = ({ className, size }: Props) => {
  const [loading, setLoading] = useState(false);
  const csvLink = useRef<any>(); // Create a ref for the CSVLink

  const fetchData = async () => {
    setLoading(true);
    try {
      // Replace with actual data fetching logic
      const blob = await generateCsvDataBlob({});
      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const downloadAnchor = csvLink.current;
        downloadAnchor.href = url;
        downloadAnchor.click();
        window.URL.revokeObjectURL(url);
        downloadAnchor.href = '/';
      } else {
        toast.error('Error fetching data. Please try again later.');
      }
      setLoading(false);
    } catch (error) {
      toast.error('Error fetching data. Please try again later.');
      setLoading(false);
    }
  };

  return (
    <button
      onClick={fetchData}
      disabled={loading}
      className={`${className ? className : ''} ${
        size ? `h-${size} w-${size}` : ''
      }`}
    >
      {loading ? (
        <LoadingAnimation size={size ? size * 4 : undefined} />
      ) : (
        <ArrowDownTrayIcon className='h-full w-full' />
      )}
      <a download='export.zip' className='hidden' ref={csvLink} href='/' />
    </button>
  );
};

export default ExportCsvButton;
