import { VerificationStepType } from '../../enums/VerificationStepType';
import { VerificationStepsConfigStore } from '../verification-steps-config.store';

const kybCompanyStepsConfigStore = new VerificationStepsConfigStore({
  allowedStepTypes: [
    VerificationStepType.COMPANY_INFO,
    VerificationStepType.LEGAL_DOCUMENTS,
  ],
});
export default kybCompanyStepsConfigStore;
