import React from 'react';
import { Grid } from 'react-loader-spinner';

interface Props {
  size?: number;
  className?: string;
}

const LoadingAnimation = ({ size = 80, className }: Props) => {
  return (
    <Grid
      height={size}
      width={size}
      color={'rgb(235 87 87)'}
      ariaLabel='grid-loading'
      radius='12.5'
      wrapperStyle={{}}
      wrapperClass={className}
      visible={true}
    />
  );
};

export default LoadingAnimation;
