import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';

function Landing() {
  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    keycloak.login({
      redirectUri: window.location.origin + '/projects'
    })
  }, [keycloak])


  return (
    <div className='flex flex-1 flex-col justify-center items-center bg-background'>
      <div className='bg-backgroundSecondary rounded'>
        <div className='mx-auto max-w-2xl py-12 px-6 text-center sm:py-20 sm:px-10 lg:px-16'>
          <img
            src='/assets/logo_with_title.png'
            alt='Logo'
            className='max-w-xs mb-6 m-auto'
          ></img>
          <h2 className='text-3xl font-bold tracking-tight sm:text-4xl text-white'>
            <span className='block'>Compliance meets Crypto</span>
          </h2>
          <p className='mt-4 text-lg leading-6 text-white'>
            Ihr Ansprechpartner für Komplettlösungen in den Bereichen <br />
            #Compliance, #Blockchain und #Crypto.
          </p>
          {/* TODO: button component */}
          <button
            onClick={login}
            className='mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-5 py-3 text-base font-medium text-white hover:shadow-lg transition w-full'
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Landing;
