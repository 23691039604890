import { InformationCircleIcon, LinkIcon } from '@heroicons/react/24/outline';
import { parse as parseflatted } from 'flatted';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';
import { parseComplyAdvantageResponse } from './helper/complyadvantage.parser';

interface Props {
  data: any;
}

const ComplyAdvantageResponse = ({ data }: Props) => {
  const dataObj = parseflatted(data);

  const parsedData = parseComplyAdvantageResponse(dataObj);

  if (Object.keys(parsedData).length > 0) {
    const url = `https://app.complyadvantage.com/#/case-management/search/${parsedData['ref']}`;
    return (
      <>
        <IconTitleItem
          icon={<LinkIcon />}
          title='ComplyAdvantage Search Result'
          className='mb-3'
          subItem={
            <a href={url} target='_blank' className='text-sm underline text-primary'>
              {'Open Link >'}
            </a>
          }
        />
        <div className='grid gap-3 grid-cols-3'>
          {Object.keys(parsedData).map((parsedDataKey) => {
            if (parsedDataKey !== 'ref') {
              return (
                <IconTitleItem
                  icon={<InformationCircleIcon />}
                  title={parsedDataKey}
                  key={parsedDataKey}
                  subTitle={parsedData[parsedDataKey].toString()}
                />
              );
            }
          })}
        </div>
      </>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default ComplyAdvantageResponse;
