import { UserIcon } from '@heroicons/react/24/outline';
import { parse as parseflatted } from 'flatted';
import React from 'react';
import UserAddress from '../../../interfaces/address.dto';
import { getAddressString, getCountryIcon, getCountryLabel } from '../../../utils/Address.util';
import FlattedText from '../../flatted-text/FlattedText';
import IconTitleItem from '../../IconTitleItem/IconTitleItem';

interface Props {
  data: any;
}

const AddressRequest = ({ data }: Props) => {
  const parsedData = parseflatted(data);
  if (parsedData.length > 0) {
    return (
      <div className='grid gap-3 grid-cols-2 w-2/3'>
        {parsedData.map((address: UserAddress, index: number) => {
          return (
            <>
              <IconTitleItem
                icon={<UserIcon />}
                title={`Address ${(index + 1).toString()}`}
                subTitle={getAddressString(address)}
                key={`address-${index}`}
              />
              <IconTitleItem
                icon={getCountryIcon(address.country)}
                title={`Country ${(index + 1).toString()}`}
                subTitle={getCountryLabel(address.country)}
                key={`country-${index}`}
              />
            </>
          );
        })}
      </div>
    );
  }

  return <FlattedText flattedText={data} />;
};

export default AddressRequest;
