import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';
//import { Navigate } from 'react-router-dom';
import { Role } from '../enums/Role';
import { getKeycloakRoles } from '../utils/KeycloakUser.util';

interface RequireAuthProps {
  roles: Role[];
  redirect?: boolean;
  children: JSX.Element;
}

function RequireAuth({
  roles,
  children,
  redirect = false,
}: RequireAuthProps): JSX.Element {
  const { keycloak } = useKeycloak();

  const userRoles = getKeycloakRoles(keycloak);

  const can = roles.some((r) => userRoles.some((ur: any) => ur === r));

  if (can) {
    return <>{children}</>;
  } else if (redirect === true) {
    return <Navigate to={'/no-permission'} />;
  }

  return <></>;
}

export default RequireAuth;
