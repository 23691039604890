import { Provider } from 'mobx-react';
import { stores } from './stores';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routing/Routes';
import { useKeycloak } from '@react-keycloak/web';
import Loading from './components/loading/Loading';

function App() {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return <Loading />
  }

  return (
    <Provider {...stores}>
      <Routes />
      <ToastContainer />
    </Provider>
  );
}

export default App;
