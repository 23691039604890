import Button from '../components/button/Button';
import TextInput from '../components/inputs/TextInput';
import { useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { onPromise } from '../utils/formHelper';
import projectStore from '../stores/project.store';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Loading from '../components/loading/Loading';
import RequireAuth from '../components/RequireAuth';
import { Role } from '../enums/Role';

function CreateProject() {
  const [loading, setLoading] = useState(false);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const methods = useForm<any>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      setLoading(true);
      //  await userStore.verify(data)
      const response = await projectStore.createProject(data);
      setLoading(false);
      if (response) {
        await keycloak.updateToken(Number.MAX_SAFE_INTEGER);
        navigate('/projects');
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <RequireAuth roles={[Role.ADMIN]} redirect>
      <div className='w-full h-full bg-white px-10 py-16 flex justify-center items-center '>
        <div className='w-3/4 lg:w-1/2 border rounded-md p-4'>
          <header>
            <div className='max-w-7xl'>
              <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
                Create Project
              </h1>
              <p className='mt-8 text-xl leading-8 text-gray-500'>
                Please give your project a name.
              </p>
            </div>
          </header>
          <main className='w-full flex mt-10'>
            <FormProvider {...methods}>
              <form
                onSubmit={onPromise(methods.handleSubmit(onSubmit))}
                className='w-full'
              >
                <TextInput
                  placeHolder='Project Name'
                  variable='name'
                  regExp={/[a-zA-Z0-9]/}
                  className='w-full'
                />
                <div className='flex justify-end'>
                  <div className='w-1/3'>
                    <Button
                      type='submit'
                      style='primary'
                      title='Create'
                      className='w-full'
                    />
                  </div>
                </div>
              </form>
            </FormProvider>
          </main>
        </div>
      </div>
    </RequireAuth>
  );
}

export default CreateProject;
