import UserDetail from '../../../components/User/UserDetail/UserDetail';
import { observer } from 'mobx-react';
import clientUserStore from '../../../stores/clientUser.store';
import { verificationStepTitle } from '../../../utils/VerificationSteps.util';
import { getUserVerificationStepStatus } from '../../../utils/User.util';
import VerificationStatusCard from '../../../components/User/UserDetail/VerificationStatusCard';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import { getVerificationStepUserServices } from '../../../helpers/VerificationService.helper';
import UserVerificationServiceDto from '../../../interfaces/user/verification-service.dto';
import VerificationServiceCards from '../../../components/User/UserDetail/VerificationSteps/VerificationServiceCards';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import VerificationStepSettings from '../../../components/User/UserDetail/VerificationSteps/VerificationStepSettings';
import DirectorApprovalData from '../../../components/User/UserDetail/DirectorApproval/DirectorApprovalData';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

function UserDetailDirectorApproval() {
  const stepType = VerificationStepType.DIRECTOR_APPROVAL;
  const directorApproval = clientUserStore.user?.directorApproval;

  let services: UserVerificationServiceDto[] = [];

  const { user } = useParams();
  useEffect(() => {
    if (user) {
    //   clientUserStore.resetUser();
      clientUserStore.getUser({ user, properties: [stepType] });
    }
  }, [user]);

  if (directorApproval) {
    services = getVerificationStepUserServices({
      configServices: stepConfigStore(
        clientUserStore.user?.flowType
      ).getVerificationServices(stepType),
      userServices: directorApproval.services ? directorApproval.services : [],
    });
  }

  const renderDirectorApprovalData = () => {
    return (
      <VerificationStatusCard
        title={verificationStepTitle(stepType)}
        status={getUserVerificationStepStatus({
          user: clientUserStore.user!,
          step: stepType,
        })}
        rightTopItem={
          directorApproval && <VerificationStepSettings step={stepType} />
        }
      >
        {directorApproval && (
          <DirectorApprovalData directorApproval={directorApproval} />
        )}
      </VerificationStatusCard>
    );
  };

  return (
    <UserDetail>
      <>
        {renderDirectorApprovalData()}
        {directorApproval &&
          (services && services.length > 0 ? (
            <VerificationServiceCards
              className='mt-8'
              services={services}
              comments={directorApproval.comments}
              stepType={stepType}
            />
          ) : (
            <></>
          ))}
      </>
    </UserDetail>
  );
}

export default observer(UserDetailDirectorApproval);
