import { VerificationStepType } from '../../enums/VerificationStepType';
import { VerificationStepsConfigStore } from '../verification-steps-config.store';

const kybStepsConfigStore = new VerificationStepsConfigStore({
  allowedStepTypes: [
    VerificationStepType.COMPANY_INFO,
    VerificationStepType.DIRECTOR_APPROVAL,
    VerificationStepType.LEGAL_DOCUMENTS,
    VerificationStepType.BENEFICIAL_OWNER,
    VerificationStepType.QUESTIONNAIRE,
    VerificationStepType.WALLET,
    VerificationStepType.FINISH,
  ],
});
export default kybStepsConfigStore;
