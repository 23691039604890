import React from 'react'
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
const Loading = () => {
    return (
        <div className='w-screen h-screen flex flex-1 justify-center items-center bg-background'>
            <LoadingAnimation />
        </div>
    )
}

export default Loading