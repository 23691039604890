import React, { ReactElement } from 'react';

interface Props {
  value?: string | JSX.Element;
  className?: string;
}

const ReadonlyInput: React.FC<Props> = ({
  className = '',
  value = '',
}: Props): ReactElement => {
  return (
    <div className={`${className} relative mb-4`}>
      {typeof value == 'string' ? (
        <input
          value={value}
          autoComplete='off'
          readOnly
          className={`border rounded-lg border-gray-300 outline-0 h-10 px-2.5 w-full text-sm`}
        />
      ) : (
        <div
          className={`border flex items-center rounded-lg border-gray-300 outline-0 h-10 px-2.5 w-full text-sm`}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default ReadonlyInput;
