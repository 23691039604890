enum POSSIBLE_KEYS {
  FaceDetection = "FaceDetection",
  AuthenticityCheckList = "AuthenticityCheckList",
  DocumentPostion = "DocumentPosition",
  OneCandidate = "OneCandidate",
  DocVisualExtendedInfo = "DocVisualExtendedInfo",
  DocGraphicsInfo = "DocGraphicsInfo",
}

const parseRegulaResponse = (request: any) => {
  const { ContainerList } = request;
  if (!ContainerList || !ContainerList.List) {
    return {};
  }

  const result: any = {};
  const list = ContainerList.List;
  for (let element of list) {
    if (Object.keys(element).includes(POSSIBLE_KEYS.FaceDetection)) {
      result[POSSIBLE_KEYS.FaceDetection] = handleFaceDetection(element);
    }

    if (Object.keys(element).includes(POSSIBLE_KEYS.AuthenticityCheckList)) {
      result[POSSIBLE_KEYS.AuthenticityCheckList] =
        handleAuthenicityCheckList(element);
    }

    if (Object.keys(element).includes(POSSIBLE_KEYS.DocumentPostion)) {
      result[POSSIBLE_KEYS.DocumentPostion] =
        handleAuthenicityCheckList(element);
    }

    if (Object.keys(element).includes(POSSIBLE_KEYS.OneCandidate)) {
      result[POSSIBLE_KEYS.OneCandidate] = element[POSSIBLE_KEYS.OneCandidate];
    }

    if (Object.keys(element).includes(POSSIBLE_KEYS.DocVisualExtendedInfo)) {
      if (!result[POSSIBLE_KEYS.DocVisualExtendedInfo]) {
        result[POSSIBLE_KEYS.DocVisualExtendedInfo] = [];
      }

      const generatedElements = handleDocVisualExtendedInfo(element);
      for (const item of generatedElements) {
        if (
          result[POSSIBLE_KEYS.DocVisualExtendedInfo].find(
            (el: any) => el.FieldName === item.FieldName
          )
        ) {
          continue;
        }
        result[POSSIBLE_KEYS.DocVisualExtendedInfo].push(item);
      }
    }
    if (Object.keys(element).includes(POSSIBLE_KEYS.DocGraphicsInfo)) {
      if (!result[POSSIBLE_KEYS.DocGraphicsInfo]) {
        result[POSSIBLE_KEYS.DocGraphicsInfo] = [];
      }
      result[POSSIBLE_KEYS.DocGraphicsInfo] = [
        ...result[POSSIBLE_KEYS.DocGraphicsInfo],
        ...handleDocGraphicsInfo(element),
      ];
    }
  }

  return result;
};

const handleFaceDetection = (element: any) => {
  const { FaceDetection } = element;
  const result: any = {};
  result["count"] = FaceDetection.Count;
  return FaceDetection;
};

const handleAuthenicityCheckList = (element: any) => {
  const { AuthenticityCheckList } = element;
  const result: any = {};
  if (
    !AuthenticityCheckList ||
    !AuthenticityCheckList.List ||
    AuthenticityCheckList.List.length === 0
  ) {
    return result;
  }
  for (let element of AuthenticityCheckList.List) {
    for (const entry of element.List) {
      if (entry["EtalonImage"]) {
        result["etalonImage"] = {};
        result["etalonImage"]["image"] = entry["EtalonImage"].image;
      }
      if (entry["SourceImage"]) {
        result["sourceImage"] = {};
        result["sourceImage"]["image"] = entry["SourceImage"].image;
      }
      if (entry["ResultImages"]) {
        result["resultImages"] = {};
        result["resultImages"]["image"] = entry["ResultImages"].images;
      }
    }
  }
  return result;
};

const handleDocVisualExtendedInfo = (element: any) => {
  const { DocVisualExtendedInfo } = element;
  console.log("element");
  const result: any = [];
  if (!DocVisualExtendedInfo || !DocVisualExtendedInfo.pArrayFields) {
    return result;
  }
  for (let element of DocVisualExtendedInfo.pArrayFields) {
    let tmp: any = {};
    tmp["value"] = element.Buf_Text;
    tmp["mask"] = element.FieldMask;
    tmp["FieldName"] = element.FieldName;
    result.push(tmp);
  }
  return result;
};

const handleDocGraphicsInfo = (element: any) => {
  const { DocGraphicsInfo } = element;
  const result: any = [];
  if (!DocGraphicsInfo || !DocGraphicsInfo.pArrayFields) {
    return result;
  }
  for (let element of DocGraphicsInfo.pArrayFields) {
    let tmp: any = {};
    tmp["fieldName"] = element.FieldName;
    tmp["image"] = element.image;
    result.push(tmp);
  }
  return result;
};

export { parseRegulaResponse };
