import ReactTimeAgo from 'react-time-ago';
import CommentDto from '../../interfaces/user/comment.dto';

interface Props {
  comment: CommentDto;
}

const Comment = ({ comment }: Props) => {
  return (
    <article className='px-4 py-2 text-base'>
      <footer className='flex justify-between items-center mb-1'>
        <div className='flex items-center'>
          <p className='inline-flex items-center mr-3 text-sm'>
            {comment.userName}
          </p>
          {comment.created_at && (
            <p className='text-sm text-gray-400'>
              <ReactTimeAgo date={new Date(comment.created_at)} />
            </p>
          )}
        </div>
        {/* <button
          className='inline-flex items-center p-2 text-sm font-medium text-center text-gray-400 bg-white rounded-lg'
          type='button'
        >
          <TrashIcon className='h-5 w-5' />
        </button> */}
      </footer>
      <p className='text-gray-500 whitespace-pre-line'>{comment.comment}</p>
    </article>
  );
};

export default Comment;
