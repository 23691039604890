import React, { ReactElement } from 'react'
import { parse as parseflatted } from 'flatted';
import ImageGallery from 'react-image-gallery';

interface Props {
    data: any
}

const RegulaRequest = ({ data }: Props): ReactElement => {
    const request = parseflatted(data)
    if (!request) return <></>

    const images = request.List.map((item: any) => {
        return { original: item.value, thumbnail: item.value }
    })

    return (
        <div>
            <div>
                <ImageGallery items={images} showBullets autoPlay={false} showFullscreenButton={false} />
                {/* {request.List.map((item: any) => {
                    return <div className='w-1/2' > <img src={item.value} /></div>
                })} */}
            </div>
        </div >
    )
}

export default RegulaRequest