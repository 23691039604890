import { action, makeObservable, observable } from "mobx";
import { toast } from "react-toastify";
import axios from "../utils/Axios";
import ProjectConfigDto from "../interfaces/ProjectConfig.dto";
import countryRestrictionStore from "./countryRestriction.store";
import {
  resetAllStepConfigs,
  setAllStepConfigs,
} from "../utils/FlowConfig.util";

export class ProjectConfigStore {
  @observable config: ProjectConfigDto | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setConfig(config: any) {
    setAllStepConfigs(config);

    if (config.countryRestriction) {
      countryRestrictionStore.setCountries(config.countryRestriction);
    }

    this.config = config;
  }

  @action
  async getConfig(config: string): Promise<void> {
    const response = await axios.get("/project-config/" + config);
    if (response.status === 200) {
      this.setConfig(response.data);
    }
  }

  @action
  async updateConfig(updateConfig: Partial<ProjectConfigDto>): Promise<void> {
    try {
      if (!this.config) return;
      const response = await axios.put(
        "/project-config/" + this.config.id,
        updateConfig
      );
      if (response.status === 200) {
        this.config = response.data;
        toast.success("Project updated successfully");
      }
    } catch (e) {
      toast.error("Error updating project");
      console.log(e);
    }
  }

  @action
  async uploadImage(file: File): Promise<string> {
    await this.updateConfig({ logo: await this.uploadFile(file) });
    await this.getConfig(this.config?.id || "");
    return "";
  }

  @action
  async uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post("/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 201) {
      return response.data.url;
    }
    return "";
  }

  @action
  resetConfig() {
    this.config = undefined;
    resetAllStepConfigs();
    countryRestrictionStore.resetRestrictions();
  }

  @action
  async deactivateTestMode() {
    if (!this.config) return;
    await this.updateConfig({ testMode: false });
  }

  @action
  async inviteUser(email: string) {

  }
}

const projectConfigStore = new ProjectConfigStore();
export default projectConfigStore;
