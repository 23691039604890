import {
    PlusIcon,
} from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';

const CreateProjectCard = () => {
    return (
        <NavLink to='/projects/create' className="h-16 relative flex items-center rounded-lg border border-gray-300 bg-white px-3 py-3 shadow-sm focus-within:ring-2 focus-within:ring-offset-2 hover:border-gray-400 w-1/4 m-2 ml-0 box-border"
        >
            <PlusIcon className='w-5 h-5 mr-3' />
            Create Project
        </NavLink>
    )
}

export default CreateProjectCard