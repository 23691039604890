import { observer } from 'mobx-react';
import filterStore from '../../../stores/filter.store';
import ActiveStatusFilterItem from './ActiveStatusFilterItem';
import ActiveFlowTypeFilterItem from './ActiveFlowTypeFilterItem';

interface Props {
  className?: string;
}

const ActiveUserStatusFilterRow = ({ className }: Props) => {
  if (
    filterStore.hasActiveStatusFilter() ||
    filterStore.hasActiveFlowTypeFilter()
  ) {
    return (
      <div className={`${className}`}>
        {filterStore.hasActiveStatusFilter() && (
          <button
            onClick={() => {
              filterStore.removeStatusFilter();
            }}
            className='mr-2'
          >
            <ActiveStatusFilterItem userStatus={filterStore.statusFilter!} />
          </button>
        )}
        {filterStore.hasActiveFlowTypeFilter() && (
          <button
            onClick={() => {
              filterStore.removeFlowTypeFilter();
            }}
            className='mr-2'
          >
            <ActiveFlowTypeFilterItem flowType={filterStore.flowTypeFilter!} />
          </button>
        )}
      </div>
    );
  }
  return <></>;
};

export default observer(ActiveUserStatusFilterRow);
