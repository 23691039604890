import React from 'react';
import { VerificationStepType } from '../../../../../enums/VerificationStepType';
import { UserDto } from '../../../../../interfaces/User.dto';
import { verificationStatusTextColor } from '../../../../../utils/VerificationStatus.util';
import { verificationStepEntityName, verificationStepTitle } from '../../../../../utils/VerificationSteps.util';
import StatusIcon from '../../../UserStatus/StatusIcon';

interface Props {
  tabs: VerificationStepType[];
  currentTab: VerificationStepType;
  setCurrentTab: (tab: VerificationStepType) => void;
  user: UserDto;
}

const BeneficialOwnerDialogTabs = ({
  tabs,
  currentTab,
  setCurrentTab,
  user,
}: Props) => {
  return (
    <div className='border-b border-gray-200'>
      <nav className='-mb-px flex' aria-label='Tabs'>
        {tabs.map((tab, tabIdx) => {
          const isCurrent = currentTab === tab;
          const status = (user as any)[verificationStepEntityName(tab)]?.status;
          return (
            <div
              key={tabIdx}
              onClick={() => setCurrentTab(tab)}
              className={`${
                isCurrent
                  ? 'border-primary'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              } w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer`}
              aria-current={isCurrent ? 'page' : undefined}
            >
              <div className='flex justify-center items-center'>
                {verificationStepTitle(tab)}
                <StatusIcon
                  status={status}
                  className={`${verificationStatusTextColor(
                    status
                  )} w-5 h-5 ml-2`}
                />
              </div>
            </div>
          );
        })}
      </nav>
    </div>
  );
};

export default BeneficialOwnerDialogTabs;
