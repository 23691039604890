import ReactTimeAgo from 'react-time-ago';
import { UserDto } from '../../../interfaces/User.dto';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { getUserFullName, getUserInitials } from '../../../utils/User.util';
import FlowTypeItem from '../UserFlowType/FlowTypeItem';

interface UserInfoProps {
  user: UserDto;
  hideStatus?: boolean;
  hideInitials?: boolean;
}

function UserInfo({
  user,
  hideStatus = false,
  hideInitials = false,
}: UserInfoProps): JSX.Element {
  const userInitials = getUserInitials(user);
  const userName = getUserFullName(user);

  return (
    <div className='flex items-center'>
      {!hideInitials && userInitials ? (
        <div className='h-14 w-14 text-2xl mr-2.5 flex justify-center items-center rounded-full p-4 border-full aspect-square bg-background-700'>
          {userInitials}
        </div>
      ) : (
        <></>
      )}
      <div className='flex flex-col w-full'>
        <div className='flex justify-between'>
          <div className='font-bold'>{userName ? userName : '-'}</div>
          {!hideStatus && (
            <div className='h-min'>
              {/* <VerificationStatusItem status={user.status} /> */}
              <FlowTypeItem flowType={user.flowType} status={user.status} />
            </div>
          )}
        </div>
        {user.created_at && (
          <div className='text-xs mt-0.5'>
            Created <ReactTimeAgo date={new Date(user.created_at)} />
          </div>
        )}
        <div className='flex items-center mt-2'>
          <EnvelopeIcon className='h-4 w-4 mr-2' />
          <div className='text-sm'>{user.email}</div>
        </div>
        {/* <div className='flex items-center mt-1'>
        <LinkIcon className='h-4 w-4 mr-2' />
        <div className='text-sm'>{user.id}</div>
      </div> */}
      </div>
    </div>
  );
}

export default UserInfo;
