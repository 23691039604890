import { SyntheticEvent } from 'react';

function onPromise<T> (promise: (event: SyntheticEvent) => Promise<T>) {
    return (event: SyntheticEvent) => {
        promise(event).catch((error) => {
            console.error('Unexpected error', error);
        });
    };
}

export { onPromise }
