import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import VerificationStatusItem from './StatusItem';

interface Props {
  status: VerificationStatus;
  onStatusChange?: (status: VerificationStatus) => void;
}

function ChangableVerificationStatusItem({
  status,
  onStatusChange,
}: Props): JSX.Element {
  const renderMenuItem = (menuStatus: VerificationStatus) => {
    return (
      <div className='py-1 w-32 flex justify-center'>
        <Menu.Item>
          <button
            onClick={() => {
              if (onStatusChange) {
                onStatusChange(menuStatus);
              }
            }}
          >
            <VerificationStatusItem status={menuStatus} noBackground />
          </button>
        </Menu.Item>
      </div>
    );
  };

  if (status) {
    return (
      <Menu as='div' className='relative flex'>
        <Menu.Button>
          <VerificationStatusItem statusChangable status={status} />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-2 top-full origin-top-right z-10 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {status !== VerificationStatus.COMPLETED &&
              renderMenuItem(VerificationStatus.COMPLETED)}
            {status !== VerificationStatus.REJECTED &&
              renderMenuItem(VerificationStatus.REJECTED)}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
  return <></>;
}

export default ChangableVerificationStatusItem;
