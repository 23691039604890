import { useState } from 'react';
import ConfigVerificationStepLayout from '../../../components/ProjectConfiguration/VerificationStep/ConfigVerificationStepLayout';
import DocumentUpload from '../../../components/ProjectConfiguration/VerificationStep/finish/DocumentUpload';
import DocumentRestriction from '../../../components/ProjectConfiguration/VerificationStep/id-and-liveness/DocumentRestriction';
import FlowType from '../../../enums/FlowType';
import { VerificationStepType } from '../../../enums/VerificationStepType';
import { stepConfigStore } from '../../../utils/FlowConfig.util';
import { verificationStepTitle } from '../../../utils/VerificationSteps.util';

interface Props {
  flowType: FlowType;
}

const Flow = ({ flowType }: Props) => {
  const configStore = stepConfigStore(flowType);

  const [activeStep, setActiveStep] = useState(configStore.allowedStepTypes[0]);

  const getConfigVerificationStepChildren = (step: VerificationStepType) => {
    switch (step) {
      case VerificationStepType.ID_AND_LIVENESS:
        if (configStore.config?.idAndLiveness?.documentRestriction) {
          const config = {
            ...configStore.config,
          };
          return (
            <DocumentRestriction
              documentDBObj={config.idAndLiveness!.documentRestriction}
              onChange={(docsDbObj) => {
                config.idAndLiveness!.documentRestriction = docsDbObj;
                configStore.updateConfig(config);
              }}
            />
          );
        }
        return <></>;
      case VerificationStepType.FINISH:
        return <DocumentUpload flowType={flowType} />;
      default:
        return <></>;
    }
  };

  return (
    <div className='h-full w-full pt-2'>
      <div className='font-medium text-center text-gray-500 border-b border-gray-200 pl-6 pr-6'>
        <ul className='flex flex-wrap -mb-px'>
          {configStore.allowedStepTypes.map((step, index) => {
            return (
              <li className='mr-2' key={index}>
                <div
                  className={`inline-block p-4 border-b-2 border-transparent cursor-pointer rounded-t-lg ${
                    activeStep === step
                      ? 'text-primary border-primary'
                      : 'hover:text-gray-600 hover:border-gray-300'
                  }`}
                  onClick={() => {
                    setActiveStep(step);
                  }}
                >
                  {verificationStepTitle(step)}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <ConfigVerificationStepLayout
        verificationStepType={activeStep}
        flowType={flowType}
      >
        {getConfigVerificationStepChildren(activeStep)}
      </ConfigVerificationStepLayout>
    </div>
  );
};

export default Flow;
