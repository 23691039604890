import React, { Fragment } from 'react';
import FilterType from '../../../enums/FilterType';
import FlowType from '../../../enums/FlowType';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import { Menu, Transition } from '@headlessui/react';
import FilterStatusItem from './FilterStatusItem';
import FilterFlowTypeItem from './FilterFlowTypeItem';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

interface Props {
  type: FilterType;
  options: FlowType[] | VerificationStatus[];
}

const UserFilterItem = ({ type, options }: Props) => {
  return (
    <Menu as='div' className='relative flex w-36'>
      <Menu.Button className='data-[headlessui-state=open]:text-primary flex p-3 w-full items-center justify-between'>
        <span>{type === FilterType.STATUS ? 'Status' : 'Type'}</span>
        <ChevronDownIcon className='w-4 h-4' />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 left-0 ml-3 mr-3 text-center -mt-1 top-full origin-top-right z-10 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {options.map((item) => {
            if (type === FilterType.STATUS) {
              return <FilterStatusItem status={item as VerificationStatus} />;
            } else {
              return <FilterFlowTypeItem flowType={item as FlowType} />;
            }
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserFilterItem;
