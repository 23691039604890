import { useKeycloak } from '@react-keycloak/web';
import { VerificationStatus } from '../../../enums/VerificationStatus';
import { isUserReadyonly } from '../../../utils/KeycloakUser.util';
import Card from '../../card/Card';
import ChangableVerificationStatusItem from '../UserStatus/ChangebleStatusItem';
import VerificationStatusItem from '../UserStatus/StatusItem';

interface VerificationStatusCardProps {
  status?: VerificationStatus;
  title?: string;
  children?: JSX.Element;
  statusChangable?: boolean;
  onStatusChange?: (status: VerificationStatus) => void;
  rightTopItem?: JSX.Element;
}

const VerificationStatusCard = ({
  status,
  title,
  children,
  statusChangable,
  onStatusChange,
  rightTopItem,
}: VerificationStatusCardProps) => {
  const { keycloak } = useKeycloak();
  return (
    <Card className={`mt-8 p-6 ${!title && 'relative'}`}>
      <div className='w-full flex justify-between '>
        {title && <div className='font-bold'>{title}</div>}
        <div className='flex'>
          <div
            className={`h-min ${
              title ? 'flex ' : 'w-auto absolute top-0 right-0 mt-6 mr-6'
            }`}
          >
            <div className='flex'>
              {status &&
                (statusChangable && !isUserReadyonly(keycloak) ? (
                  <ChangableVerificationStatusItem
                    onStatusChange={onStatusChange}
                    status={status}
                  />
                ) : (
                  <VerificationStatusItem status={status} />
                ))}
              {rightTopItem}
            </div>
          </div>
        </div>
      </div>
      {children ? <div className={title && 'mt-5'}>{children}</div> : <></>}
    </Card>
  );
};

export default VerificationStatusCard;
